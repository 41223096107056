import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { getUserBrands, createBrand, updatedBrand, deleteBrand } from './thunks'


interface Brand {
  id?: number
  name?: string | undefined
  editing?: boolean
}

// Define a type for the slice state
interface BrandPanelState {
  brands: Brand[]
  error: string | undefined
}


const newBrand: Brand = {
  name: undefined,
}

// Define the initial state using that type
const initialState: BrandPanelState = {
  brands: [], 
  error: undefined
}

export const brandsSlice = createSlice({
  name: 'brands',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setBrands: (state, action: PayloadAction<any[]>) => {
      state.brands = action.payload
    },
    addNewBrand: (state) => {
      state.brands = [...state.brands, newBrand]
    },
    toggleEdit: (state, action: PayloadAction<number>) => {
      state.brands = state.brands.map((brand, index) => {
        if(index === action.payload) {
          return {...brand, editing: !brand.editing}
        }
        return brand
      })
    },
    finishRename: (state, action: PayloadAction<Brand>) => {
      state.brands = state.brands.map((brand, index) => {
        if(index === action.payload.id) {
          return {...brand, editing: false, name: action.payload.name}
        }
        return brand
      })
    },
    // deleteBrand: (state, action: PayloadAction<number>) => {
    //   state.brands = state.brands.filter((brand, index) => index !== action.payload)
    // },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [getUserBrands.fulfilled.toString()]: (state, action) => {
      // Add user to the state array
      state.brands.push(action.payload)
    },
    // [getUserBrands.rejected.toString()]: (state, action) => {
    //   // Add user to the state array
    //   state.error = action.payload
    // },
    [createBrand.fulfilled.toString()]: (state, action) => {
      // Add user to the state array
      state.brands = action.payload
    },
    // [createBrand.rejected.toString()]: (state, action) => {
    //   // Add user to the state array
    //   state.error = action.payload
    // },
    // [updatedBrand.fulfilled.toString()]: (state, action) => {
    //   state.brands = state.brands.map(({id}) => {
    //     if (action.payload.id === id) {
    //       return {
    //         ...action.payload
    //       }
    //     }
    //     return
    //   })
    // },
    [updatedBrand.fulfilled.toString()]: (state, action) => {
      // Add user to the state array
      state.brands = action.payload
    },
    // [updatedBrand.rejected.toString()]: (state, action) => {
    //   // Add user to the state array
    //   state.error = action.payload
    // },
    [deleteBrand.fulfilled.toString()]: (state, action) => {
      // Add user to the state array
      state.brands = action.payload
    },
    // [deleteBrand.rejected.toString()]: (state, action) => {
    //   // Add user to the state array
    //   state.error = action.payload
    // },
  },
})

export const { setBrands, addNewBrand, toggleEdit, finishRename } = brandsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.ui.value

export default brandsSlice.reducer as Reducer<typeof initialState>