import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CardContainer from "../../components/CardContainer";
import { Exit } from "../../assets";
import BaseDialog from "../../components/BaseDialog";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    // boxSizing: "content-box",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    // alignItems: "center",
    width: 392,
    paddingBottom: 35,
    paddingTop: 20,
  },
  text: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "1rem",
    padding: "0.4rem",
  },
  buttonContainer: {
    width: 400,
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "space-between",
    // gap: "1.5rem",
    textAlign: "center"
  },
  cancel: {
    height: 41,
    width: 104,
    borderRadius: 6,
    padding: 11,
    backgroundColor: theme.palette.primary.main,
    border: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  subscription: {
    height: 41,
    width: 200,
    borderRadius: 6,
    padding: 8,
    backgroundColor: theme.palette.primary.main,
    border: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonText: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: "1rem",
    textAlign: "center",
  },
}));

interface props {
  onClick: (arg0?: boolean) => void;
  open: boolean;
  subscription: boolean;
}

export default function ConfirmDialog({ onClick, open, subscription }: props) {
  const classes = useStyles();

  const handleClose = () => {
    onClick(false);
  };

  const handleSubscription = () => {
    onClick(true);
  };
  return (
    <BaseDialog
      title={subscription ? `Update Subscription` : "New Subscription"}
      open={open}
      close={handleClose}
    >
      <Box className={classes.textContainer}>
        <Typography variant="body1" className={classes.text}>
          {subscription
            ? `Are you sure to change your Subscription Plan ?`
            : "Are you Sure to continue with this plan ? "}
        </Typography>
      </Box>
      <Box className={classes.buttonContainer}>
        {/* <Button className={classes.cancel} variant="outlined">
          <Typography onClick={handleClose} className={classes.buttonText}>
            Cancel
          </Typography>
        </Button> */}
        <Button
          className={classes.subscription}
          variant="outlined"
          onClick={handleSubscription}
        >
          <Typography className={classes.buttonText}>Confirm</Typography>
        </Button>
      </Box>
    </BaseDialog>
  );
}
