import { Box, createStyles, makeStyles, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import DefaultView from '../../views/DefaultView'
import ActionButton from '../../components/Buttons/ActionButton'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CenterContentContainer from '../../components/CenterContentContainer';
import {useAppSelector, useAppDispatch} from '../../hooks/redux'
import { Link, useParams } from 'react-router-dom';
import { Arrow, Back } from '../../assets';
import BrandTabs from './BrandTabs'
import ProductList from './ProductList';
import ADSPanel from './ADSPanel';
import ReviewPanel from './ReviewsPanel';
import { Notifications } from '@material-ui/icons';
import NotificationsPanel from './NotificationsPanel';
import { setCurrentBrandId } from '../../redux/reducers/brandPanelReducer';
import { currentMan } from '../../redux/reducers/brandPanelReducer/thunks';
import { getAds } from '../../redux/reducers/adsGridReducer/thunks'
import { manProducts, getProductList, getReports, getCurrentProductId } from '../../redux/reducers/productsReducer/thunks'
import { getReviewData, getReviews } from '../../redux/reducers/reviewReducer/thunks'
import { getNotifications } from '../../redux/reducers/notificationsReducer/thunks';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20
  },
  linkText: {
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 18,
    paddingLeft: 8
  },


})) 


export default function BrandPanel() {
  const { tab } = useAppSelector(state => state.brandPanel)
  const { products } = useAppSelector(state => state)
  const { brands } = useAppSelector(state => state.brands)
  const { currentListId } = useAppSelector(state => state.products)
  const params = useParams()
  const id = parseInt(params.id)
  // Might be redundent
  const currentBrand = brands.find((brand) => brand.id === id)
  const dispatch = useAppDispatch()
  const classes = useStyles()

  React.useEffect(() => {
    dispatch(setCurrentBrandId(id))
    dispatch(getAds(id))
    dispatch(manProducts(id))
    dispatch(currentMan(id))
    dispatch(getReviews(id))
    dispatch(getCurrentProductId(id))
    dispatch(getNotifications(id))
  }, [])

  React.useEffect(() => {
    if(currentListId) {
      dispatch(getProductList(currentListId))
      dispatch(getReports(currentListId))
    }
    // dispatch(getReviewData(currentListId))
  },[currentListId,dispatch])


  const tabToShow = () => {
    switch(tab) {
      case 0: 
        return (
         <ProductList />
        )
      case 1: 
        return (
          <ADSPanel />
        )
      case 2: 
        return (
          <ReviewPanel />
        )
      case 3: 
        return (
          <NotificationsPanel />
        )
      default: return
    }
  }

  return (
    <DefaultView>
      <Link to="/brands" className={classes.link} >
        <Arrow color="#fff"/>
        <Typography variant="h1" className={classes.linkText}>
         { currentBrand.name }
        </Typography> 
      </Link>
      <BrandTabs/>
      { products && (
        tabToShow()
        )
      }
    </DefaultView>
  )
}        