import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom' 
import Login from './Login'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import PrivacyPolicy from './Documents/PrivacyPolicy'
import UserAgreement from './Documents/UserAgreement'


export default function AuthPage() {
  return(
    <Switch>
      <Route path="/auth/login" component={Login} />
      <Route
        path="/auth/registration"
        component={SignUp}
      />
      <Route
        path="/auth/forgot-password"
        component={ForgotPassword}
      />
      <Route path="/auth/Documents/privacyPolicy"
            component={PrivacyPolicy}
            />

          <Route path="/auth/Documents/UserAgreement"
            component={UserAgreement}
            />

      <Redirect from="/auth" exact={true} to="/auth/login" />
      <Redirect to="/auth/login" />
    </Switch>
  )
}