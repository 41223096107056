import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CardContainer from "../../../components/CardContainer";
import { Exit } from "../../../assets";
import BaseDialog from "../../../components/BaseDialog";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    // boxSizing: "content-box",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    // alignItems: "center",
    width: 350,
    height: 92,
    textAlign: "center"
  },
  text: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "1rem",
    padding: "0.4rem",
  },
  buttonContainer: {
    width: 400,
    textAlign: "center"
  },
  cancel: {
    height: 41,
    width: 104,
    borderRadius: 6,
    padding: 11,
    backgroundColor: theme.palette.primary.main,
    border: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  subscription: {
    height: 41,
    width: 250,
    borderRadius: 6,
    padding: 8,
    backgroundColor: theme.palette.primary.main,
    border: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonText: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: "1rem",
    textAlign: "center",
  },
}));

interface props {
  onClick: (arg0?: boolean) => void;
  open: boolean;
  subscription: any;
  status: any;
}

export default function SubscriptionDialog({
  onClick,
  open,
  subscription,
  status
}: props) {
  const classes = useStyles();

  const handleClose = () => {
    onClick(false);
  };

  const handleSubscription = () => {
    onClick(true);
  };
  return (
    <BaseDialog
      title={
        status
          ? (status == "canceled" || status == "incomplete_expired"
            ? "Get Subscription"
            : (status == "past_due" || status == "incomplete"
              ? "Complete your payment"
              : "Upgrade your subscription"))
          : "Get New Subscription"
      }
      open={open}
      close={handleClose}
    >
      <Box className={classes.textContainer}>
        <Typography variant="body1" className={classes.text}>
          {status
            ? (status == "canceled" || status == "incomplete_expired"
              ? "Your subscription is canceled/expired. Get new Subscription."
              : (status == "past_due" || status == "incomplete"
                ? "You have pending payment."
                : `You have only ${subscription.productMetadata.brands} brands limit. For more brands please update your subscription.`))
            : "To create your Brands please get a Subscription."
          }
        </Typography>
      </Box>
      <Box className={classes.buttonContainer}>
        {/* <Button className={classes.cancel} variant="outlined">
          <Typography onClick={handleClose} className={classes.buttonText}>
            Cancel
          </Typography>
        </Button> */}
        <Button
          className={classes.subscription}
          variant="outlined"
          onClick={handleSubscription}
        >
          <Typography className={classes.buttonText}>
            {status
              ? (status == "canceled" || status == "incomplete_expired"
                ? "Get Subscription"
                : (status == "past_due" || status == "incomplete"
                  ? "Complete Payment"
                  : "Upgrade Subscription"))
              : "Subscribe"
            }
          </Typography>
        </Button>
      </Box>
    </BaseDialog>
  );
}
