import { Box, createStyles, Grid, makeStyles, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import DefaultView from '../../views/DefaultView'
import ActionButton from '../../components/Buttons/ActionButton'
import { Link } from 'react-router-dom';
import { Arrow, Back } from '../../assets';
import AdminTable from './AdminTable'
import InviteTable from './InviteTable'
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import SnackBar from '../../components/SnackBar'
import { getAllUsers, deleteUser, generateInviteCode, getAllInviteCodes, deleteInviteCode } from '../../redux/reducers/adminReducer/thunks';
import Ajax from '../../Ajax';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '34px 0 85px 0'
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20
  },
  linkText: {
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(32),
    paddingLeft: 8
  },
  icon: {
    color: theme.palette.common.white,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '20px 0'
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    minHeight: 465
  }
})) 


export const AdminContext = React.createContext({})


export default function AdminPanel() {
  const [snackbarMessage, setSnackbarMessage] = React.useState()
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const dispatch = useAppDispatch()
  const classes = useStyles()

  const {currentUser} = useAppSelector(state => state.auth);
  const [users, setUsers] = React.useState([])
  const [inviteCodes, setInviteCodes] = React.useState([])

  React.useEffect(() => {
    if (!users.length && currentUser.isAdmin) {
      getUsers()
      getInviteCodes()
    }

  }, [])


  const getInviteCodes = async () => {
    const inviteCodes = await Ajax.getAllInviteCodes()
    setInviteCodes(inviteCodes)
  }

  const getUsers = async () => {
    const allUsers = await Ajax.getAllUsers();
    setUsers(allUsers)
  }


  const handleDelete = async (id , email) => {
    const confirm = window.confirm(`Are you sure you want to delete ${email}? This will permanently remove the user's access.`);
    if (!confirm) return;

    await dispatch(deleteUser(id));
    if (currentUser.isAdmin) {
      getUsers()
    }
  }

  const handleSnackBarOpen = (message) => {
    setSnackbarMessage(message)
    setSnackbarOpen(true)
    setTimeout(() => setSnackbarOpen(false), 1000)
  }

  const generateCode = async () => {
    await Ajax.createInviteCode()
    getInviteCodes()
  }

  const deleteCode = async(code) => {
    if (currentUser.isAdmin) {
      await dispatch(deleteInviteCode(code))
      getInviteCodes()
    }
  }


  return (
    <DefaultView>
      <AdminContext.Provider value={{
        handleDelete,
        handleSnackBarOpen,
        deleteCode
      }}>
        <Box className={classes.root}>      
          <Link to="/brands" className={classes.link}>
            <Arrow color="#fff"/>
            <Typography variant="h1" className={classes.linkText}>
              Manage
            </Typography> 
          </Link>
          <Box className={classes.buttonContainer}>
            <ActionButton label="Generate New Code" onClick={generateCode}/>
          </Box>
        </Box>
        <Grid container className={classes.tableContainer} spacing={8}>
          <Grid item xs={6}>
            <AdminTable users={users}/>
          </Grid>
          <Grid item xs={6}>
            <InviteTable inviteCodes={inviteCodes}/>
          </Grid>
        </Grid>
      </AdminContext.Provider>
      {snackbarOpen ? <SnackBar message={snackbarMessage} severity="success" open={true} /> : ''}
    </DefaultView>
  )
} 