import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MoreActionMenu, {
  StyledMenuItem,
} from "../../../../components/MoreActionMenu";
import SwitchRow from "./SwitchRow";
import { AdGridContext } from "..";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import {getAds, updateAdsGrid} from '../../../../redux/reducers/adsGridReducer/thunks'
import { CSVLink } from "react-csv";
import AdsCollapse from "./AdsCollapes";
import moment from "moment";
import Spinner from "../../../../components/Spinner";

import { deleteAllAdsGrids } from "../../../../redux/reducers/adsGridReducer/thunks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  table: {
    // minWidth: 580,

    backgroundColor: theme.palette.common.white,
  },
  tableContainer: {
    borderRadius: 4,
    borderTopLeftRadius: 0,
    // overflow: 'hidden',
  },
  header: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.black,
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    textTransform: "none",
    height: 41,
    width: 149,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonText: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.white,
  },
  topHeaders: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  center: {
    marginRight: 50,
  },
  actionColumn: {
    width: 50,
  },
  bottomPages: {
    backgroundColor: theme.palette.secondary.light,
    height: 60,
    width: "100%",
  },
  headerContainer: {
    height: 60,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
  },
  border: {
    borderTop: `2px solid ${theme.palette.secondary.light}`,
    borderBottom: `2px solid ${theme.palette.secondary.light}`,
  },
  end: {
    width: 0,
  },
  export: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.fontFamily,
    textDecoration: "none",
    padding: "0 4px",
  },
  moreContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  delete: {
    height: 41,
    width: 104,
    borderRadius: 6,
    padding: 11,
    backgroundColor: theme.palette.error.main,
    border: "none",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  buttonViewMore: {
    backgroundColor: "#fff",
    textTransform: "none",
    color: "#4B7FD3",
    textDecoration: "none",
    height: 41,
    width: 140,
    cursor: "pointer",
    fontFamily: theme.typography.fontFamily
  },
}));

export default function AdGridTable() {
  let adsFull = useAppSelector((state) => state.adsGrid.ads)
  const {currentMan} = useAppSelector(state => state.brandPanel)
  const [currentAds, setCurrentAds] = React.useState([])
  const [archivedAds, setArchivedAds] = React.useState([])
  const [adsToArchive, setAdsToArchive] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [showFull, setShowFull] = React.useState(false);
  const classes = useStyles();
  const [view, setView] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [deleteAllExportAllDialog, setDeleteAllExportAllDialog] = React.useState(false)

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (adsFull) {
      if (adsFull.length > 1) {
        adsFull = adsFull.slice().sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
      }
      var cAds = adsFull.filter(ads => !ads.archived)
      var aAds = adsFull.filter(ads => ads.archived)
      setCurrentAds(cAds)
      setArchivedAds(aAds)
    }
  }, [adsFull])

  const handleSwitch = () => {
    setView(!view);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    closeDeleteAllExportAllDialog()
    dispatch(deleteAllAdsGrids({ manufacturer_id: adsFull[0].ManufacturerId }));
  };

  const createCSV = (report) => {
    if (!report) return [];

    return report
      .filter((row) => row.sku)
      .map((row) => {
        return {
          Week: row.week,
          "Start Date": row.startDate,
          "End Date": row.endDate,
          Model: row.model,
          SKU: row.sku,
          STC: row.stc,
          Notes: row.notes,
          MSRP: row.msrp,
          "Suggested Savings": row.savings,
          "Suggested Promo Retail": row.promo,
          "Gross Cost": row.gross,
          "Adjusted Cost": row.adjustedCost,
          "Reg % GM": row.regGMPercent,
          "Adjusted % GM": row.adjustedGMPercent,
          "Reg GM": row.regGM,
          "Adjusted GM": row.adjustedGM,
        };
      });
  };

  const handleArchive = (adID) => {
    if (adsToArchive.includes(adID)) {
      const newArchiveList = adsToArchive.filter(
        id => id !== adID
      );
      setAdsToArchive(newArchiveList);
      return;
    }
    setAdsToArchive((state) => state.concat(adID));
  };

  const archiveAds = async () => {
    // maybe need to move this to redux so state updates
    try {
      setSubmitting(true);
      adsToArchive.forEach(async (adID, i) => {
        const thisAd = adsFull.filter(ad => ad.id === adID)
        await dispatch(updateAdsGrid({ id: adID, archived: true }));
      });
      await dispatch(getAds(currentMan.id));
      setAdsToArchive([]);
      setSubmitting(false)
    } catch (err) {
      window.alert(err.message);
      setSubmitting(false);
    }
  };

  const closeDeleteAllExportAllDialog = async() => {
    await setDeleteAllExportAllDialog(true)
    await setDeleteAllExportAllDialog(false)
  }

  const combineAdsGrid = (adsGrids) => {
    if (!adsGrids) return;
    let ads = []
    adsGrids.map((adsGrid) => adsGrid.value && adsGrid.value.current
          ? adsGrid.value.current.values.map(val => {
              ads.push(val)
            })
          : []
      );

    let sorted = ads.sort((a, b) => {
      return new Date(b.startDate) - new Date(a.startDate);
    });
    return createCSV(sorted);
  };

  const getToday = () => {
    const today = new Date();
    return `${today.getMonth()+1}-${today.getDate()}-${today.getFullYear()}`
  }

  const csvFileName = currentMan && adsFull && adsFull.length ? `${currentMan.name} - all Ads Grid (${getToday()}).csv` : "AdsGrid.csv";

  return (
    <Box className={classes.root}>
      <Paper>
        <Box className={classes.headerContainer}>
          <SwitchRow
            toggle={view}
            handleSwitch={handleSwitch}
            on="View Current"
            off="View Archived"
          />
          {adsToArchive.length > 0 && (
            <Button
              className={classes.button}
              onClick={() => archiveAds()}
              disabled={submitting}
            >
              <Typography
                className={classes.buttonText}
              >{`Archive ${adsToArchive.length} Ads`}</Typography>
            </Button>
          )}
          {adsFull && adsFull.length ?
            <MoreActionMenu close={deleteAllExportAllDialog} className={classes.more} actions={{}}>
              <Box className={classes.moreContainer}>
                <CSVLink
                  filename={csvFileName}
                  className={classes.export}
                  data={combineAdsGrid(adsFull) || []}
                  onClick={() => closeDeleteAllExportAllDialog()}
                >
                  Export all to CSV
                </CSVLink>
                <StyledMenuItem
                  onClick={() => {
                    handleClickOpen();
                  }}
                  style={{color: "red"}}
                >
                  Delete all{" "}
                </StyledMenuItem>
              </Box>
            </MoreActionMenu>
          : "" }
        </Box>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="customized table">
            {!submitting ?
              (view && currentAds ? currentAds.map((ads, i) => (
                <AdsCollapse
                  handleArchive={handleArchive}
                  gridIndex={i}
                  viewType={"current"}
                  archived={ads.archived}
                  data={ads.value.current}
                  id={ads.id}
                  name={ads.value.filename ? ads.value.filename : ''}
                  date={moment(ads.value.current.dateCreated).format(
                    "MM-DD-YYYY HH:mm")}
                  show={i < 5 || showFull ? true : false}
                />
              )) : (!view && archivedAds ? archivedAds.map((ads, j) => (
                    <AdsCollapse
                      handleArchive={() => {}}
                      gridIndex={j}
                      viewType={"archived"}
                      archived={ads.archived}
                      data={ads.value.current}
                      id={ads.id}
                      name={ads.value.filename ? ads.value.filename : ''}
                      date={moment(ads.value.current.dateCreated).format(
                        "MM-DD-YYYY HH:mm")}
                      show={true}
                    />
                  )) : ''))
            : <Spinner />}
            {view && !showFull && !submitting && currentAds.length > 5 ?
              <button className={classes.buttonViewMore} onClick={() => setShowFull(true)}>View More...</button>
              : ''}
          </Table>
        </TableContainer>
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete All ad grids?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete all ad grids? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="secondary"
            className={classes.delete}
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleClose} variant="contained" color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
