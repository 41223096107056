import { Box, makeStyles, Switch, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchText: {},
  borders: {
    borderTop: `2px solid ${theme.palette.secondary.light}`,
    padding: '10px 10px',
    borderBottom: `2px solid ${theme.palette.secondary.light}`
  }
}))

interface props {
  label: string
  borders?: boolean,
  on?: string,
  off?: string,
  handleSwitch?: () => void,
  toggle?: boolean,
}

export default function SwitchRow({label, borders, on, off, toggle, handleSwitch}: props) {
  const [viewCurrent, setViewCurrent] = React.useState(true)
  const classes = useStyles();

  const handleViewCurrent = () => {
    setViewCurrent(!viewCurrent)
  }
  return (
    <Box className={`${classes.root} ${borders && classes.borders}`}>
      <Switch
        checked={toggle}
        onChange={handleSwitch}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <Typography className={classes.switchText}>
        {toggle ?  on : off}
      </Typography>
    </Box>       
  )
}