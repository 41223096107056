import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'

// Define a type for the slice state
interface user {
  email: string
  id: string
}

type status = 'error' | 'warning' | 'info' | 'success' | undefined

interface CounterState {
  currentUser: user | null
  status: status,
  accountDialog: boolean,
}

// Define the initial state using that type
const initialState: CounterState = {
  accountDialog: false,
  currentUser: null,
  status: undefined
}

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<user | null>) => {
      state.currentUser = action.payload
    },
    setStatus: (state, action: PayloadAction<status | undefined>) => {
      state.status = action.payload
    },
    toggleAccountDialog: (state) => {
      state.accountDialog = !state.accountDialog
    }
  }
})

export const { logIn, setStatus, toggleAccountDialog } = authSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.auth.currentUser

export default authSlice.reducer