import React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, Checkbox, TablePagination, Tabs, Typography, FormControlLabel, TextField } from '@material-ui/core';
import MoreActionMenu from '../../../../components/MoreActionMenu';
import { NotificationsContext } from '..';
import { useAppSelector } from '../../../../hooks/redux';


const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 600,
      letterSpacing: '5%',
    },
    body: {
      fontSize: theme.typography.pxToRem(14),
    
    
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);


const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: 20,
    width: '100%'
  },
  table: {
    // minWidth: 580,
   
    backgroundColor: theme.palette.common.white
  },
  tableContainer: {
    borderRadius: 4,
    // borderTopLeftRadius: 0,
  },
  header: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    maxWidth: 186,
 
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    textTransform: 'none',
    height: 41,
    width: 128,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  buttonText: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.white
  },
  topHeaders: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main
  },
  center: {
    marginRight: 50
  }, 
  actionColumn: {
    width: 50
  },
  bottomPages: {
    backgroundColor: theme.palette.secondary.light,
    height: 60,
    width: '100%'
  }
}));


export default function NotificationsTable() {
  const {   
    handleChangeFreq,
    handleChange,
    addEmail,
    handleDelete 
  } = React.useContext(NotificationsContext)
  const { notifications } = useAppSelector(state => state.notifications)
  const [editing, setEditing] = React.useState(undefined)
  const [target, setTarget] = React.useState(undefined)
  const [edits, setEdits] = React.useState({
    // email: '',
    // fromAddress: ''
  })
  const classes = useStyles();

  const toggleEdit = (i) => {
    setEditing(i)
  }

  const handleEdit = (e, key) => {
    setEdits({...edits, [key]: e.target.value})
    setTarget(e.target.name)
  }

  const handleSave = () => {
    handleChange(edits, editing)
    setEdits({})
    setEditing(undefined)
  }

  const handleUpdate = (key, val, freq = "daily") => {
    if(!val) return {[key]: {}}
    if (key == "reports") {
      return {[key]: { shouldNotify: val, frequency: freq}}
    }
    return {[key]: { shouldNotify: val}}
  }

  return (
    <Box className={classes.root}>
      <TableContainer className={classes.tableContainer} >
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow> 
              <StyledTableCell align="center" className={classes.header}>Email</StyledTableCell>
              <StyledTableCell align="center" className={classes.header}>Sku Status Reports</StyledTableCell>
              <StyledTableCell align="center" className={classes.header}>AD Grid Reports</StyledTableCell>
              <StyledTableCell align="center" className={classes.header}>Reviews Reports</StyledTableCell>
              {/* <StyledTableCell align="center" className={classes.header}>Email From address</StyledTableCell> */}
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
             {notifications && notifications.length ? notifications.map((row, i) => (
              <StyledTableRow key={row.email}>
                   <StyledTableCell align="center">
                    {editing === i
                      ? <TextField variant='filled' autoFocus name='email' value={edits.email || row.email} onChange={(e) => handleEdit(e, "email")} />
                      : <Typography variant='body1' style={{ color: '#000' }}>{row.email}</Typography>
                    }
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormControlLabel
                      className={classes.label}
                      control={
                        <>
                          <Box>
                            <Checkbox
                              checked={row.reports ? (row.reports.shouldNotify !== undefined && row.reports.shouldNotify && (row.reports.frequency === undefined || row.reports.frequency != "weekly")) : false}
                              onChange={(e) => handleChange(handleUpdate("reports", e.target.checked), i)}
                              name="inventory"
                            />Daily
                          </Box>
                          <Box style={{width: '30px'}}></Box>
                          <Box>
                            <Checkbox
                              checked={row.reports ? (row.reports.shouldNotify !== undefined && row.reports.shouldNotify && (row.reports.frequency !== undefined && row.reports.frequency == "weekly")) : false}
                              onChange={(e) => handleChange(handleUpdate("reports", e.target.checked, "weekly"), i)}
                              name="inventory"
                            />Weekly
                          </Box>
                        </>
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormControlLabel
                      className={classes.label}
                      control={
                        <Checkbox
                          checked={row.ads ? row.ads.shouldNotify : false}
                          onChange={(e) => handleChange(handleUpdate("ads", e.target.checked), i)}
                          name="inventory"
                        />
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FormControlLabel
                      className={classes.label}
                      control={
                        <Checkbox
                          checked={row.reviews ? row.reviews.shouldNotify : false}
                          onChange={(e) => handleChange(handleUpdate("reviews", e.target.checked ), i)}
                          name="ads"
                        />
                      }
                    />
                  </StyledTableCell>
                  {/* <StyledTableCell align="left">
                    {editing === i
                      ? <TextField variant='filled' autoFocus name='email' value={edits.fromAddress || row.fromAddress} onChange={(e) => handleEdit(e, "fromAddress")} />
                      : <Typography variant='body1' style={{ color: '#000' }}>{row.fromAddress}</Typography>
                    }
                  </StyledTableCell> */}
                  <StyledTableCell align="center">
                    <MoreActionMenu 
                      editing={editing === i}
                      actions={{
                        edit: () => toggleEdit(i),
                        save: () => handleSave(row.email), 
                        delete: () => handleDelete(row.email)
                      }}
                    />
                  </StyledTableCell>
              </StyledTableRow>
            )) : null}

          </TableBody>     
        </Table>
      </TableContainer>
    </Box>
  );
}