import React from 'react';
import ForgotPassword from './ForgotPassword'
import SubmittedReset from './SubmittedReset'

export default function index() {
  return (
    <>
      <ForgotPassword />
      {/* <SubmittedReset /> */}
    </>
  )
}