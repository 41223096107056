import axios from "axios";
const baseUrl = 'https://turnskuapi.com';
// const baseUrl = "http://127.0.0.1:8081";

const getToken = () => {
  const token = localStorage.getItem("turn-sku-token");
  return token || null;
};

export default class Ajax {
  /**
   * @param {Array} skus
   */
  static async fetchBestBuyApi(skus) {
    try {
      let skuString = skus.join(",");

      const res = await axios(`${baseUrl}/bestbuy/search/${skuString}`);
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  static async login(body) {
    const res = await axios.post(`${baseUrl}/users/login`, body);

    return res.data;
  }

  // verify mail for password Reset
  static async generateResetCode(body) {
    try {
      const res = await axios.post(`${baseUrl}/users/generateresetcode`, body);
      return res;
    } catch (err) {
      return err.response;
    }
  }

  //match user entered otp with generated otp
  static async verifyResetCode(body) {
    try {
      const res = await axios.post(`${baseUrl}/users/verifyresetcode`, body);
      return res;
    } catch (e) {
      return e.response;
    }
  }

  //Send new Password for that user and Reset password
  static async resetPassword(body) {
    try {
      const res = await axios.put(`${baseUrl}/users/reset-password`, body);
      return res;
    } catch (e) {
      return e.response;
    }
  }

  static async getInviteCode(hash) {
    try {
      const res = await axios(`${baseUrl}/invite/${hash}`);

      return res?.data?.hash || null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getAllInviteCodes() {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/invite/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data ? res.data.map((val) => val.hash) : [];
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async createInviteCode() {
    try {
      const token = getToken();

      const res = await axios.post(
        `${baseUrl}/invite`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async deleteInviteCode(hash) {
    try {
      const res = await axios.delete(`${baseUrl}/invite/${hash}`);

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // Users

  /**
   * @param {Object} body
   * { email, password }
   */
  static async createUser(body) {
    try {
      const res = await axios.post(`${baseUrl}/users/`, body);

      return res.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async getCurrentUser() {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/users/currentuser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getAllUsers() {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async deleteUser(id) {
    try {
      const token = getToken();

      const res = await axios.delete(`${baseUrl}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async updateCurrentUser(body) {
    try {
      const token = getToken();

      const res = await axios.put(`${baseUrl}/users/currentuser`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // Manufacturers

  static async getUserManufacturers() {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/users/manufacturers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // sorting brands
      const brandsRaw = res.data;
      const brandsDefined = brandsRaw.filter((brand) => brand.name != null);
      const brandsNotDefined = brandsRaw.filter((brand) => !brand.name);
      brandsDefined.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      const brands = brandsDefined;
      brands.push(...brandsNotDefined);

      return brands;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getManufacturer(id) {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/manufacturers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data.manufacturer;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async updateManufacturer(id, data) {
    try {
      const token = getToken();

      const res = await axios.put(`${baseUrl}/manufacturers/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data.manufacturer;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async createManufacturer(body) {
    try {
      const token = getToken();

      const res = await axios.post(`${baseUrl}/manufacturers`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async deleteManufacturer(id) {
    try {
      const token = getToken();

      const res = await axios.delete(`${baseUrl}/manufacturers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // Products

  static async getManufacturersProducts(manId) {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/manufacturers/${manId}/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getProductBrands(skus) {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/bestbuy/brands/${skus}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async createProductList(body, manId) {
    try {
      const token = getToken();

      const res = await axios.post(
        `${baseUrl}/manufacturers/${manId}/products`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getProductList(id) {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/products/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      return null;
    }
  }

  static async updateProductList(id, body) {
    try {
      const token = getToken();

      const res = await axios.put(`${baseUrl}/products/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async deleteProductList(id) {
    try {
      const token = getToken();

      const res = await axios.delete(`${baseUrl}/products/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // Ads
  static async createManufacturersAds(manId, body) {
    try {
      const token = getToken();

      const res = await axios.post(`${baseUrl}/ads/${manId}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getManufacturersAds(manId) {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/manufacturers/${manId}/ads`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data || null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async updateManufacturersAds(id, body) {
    try {
      const token = getToken();

      const res = await axios.put(`${baseUrl}/ads/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data || null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async deleteAds(id) {
    try {
      const token = getToken();

      const res = await axios.delete(`${baseUrl}/ads/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async deleteAllAds(manufacturer_id) {
    try {
      const token = getToken();

      const res = await axios.delete(`${baseUrl}/ads/${manufacturer_id}/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // Reports
  static async createReport(body, listId) {
    try {
      const token = getToken();

      const res = await axios.post(
        `${baseUrl}/products/${listId}/reports`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getReport(id) {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/reports/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getProductReports(listId) {
    try {
      const token = getToken();

      const res = await axios.get(`${baseUrl}/products/${listId}/reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async updateReportList(id, body) {
    try {
      const token = getToken();

      const res = await axios.put(`${baseUrl}/reports/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async deleteReport(id) {
    try {
      const token = getToken();

      const res = await axios.delete(`${baseUrl}/reports/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async deleteAllReports(ids) {
    try {
      const token = getToken();
      const res = await axios.delete(`${baseUrl}/reports/deleteAll/${ids}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // Reviews
  static async getReview(id) {
    try {
      const token = getToken();

      const res = await axios(`${baseUrl}/reviews/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  // Update Review
  static async updateReview(id, data) {
    try {
      const token = getToken();

      const res = await axios.put(`${baseUrl}/reviews/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data.reviews;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async createProductListReview(listId, data) {
    try {
      const token = getToken();

      const res = await axios.post(
        `${baseUrl}/products/${listId}/reviews`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async getProductListReviews(listId) {
    try {
      const token = getToken();

      const res = await axios.get(`${baseUrl}/products/${listId}/reviews`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async deleteReview(id) {
    try {
      const token = getToken();

      const res = await axios.delete(`${baseUrl}/reviews/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static async deleteMultipleReview(ids) {
    try {
      const token = getToken();

      const res = await axios.post(
        `${baseUrl}/reviews/bulk-delete`,
        { ids },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  //retrun all subscription data
  static async getSubscriptions() {
    try {
      const token = getToken();

      const res = await axios.get(`${baseUrl}/subscription/getsubscriptions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  //for checkout session
  static async checkout(data) {
    try {
      const token = getToken();

      const res = await axios.post(`${baseUrl}/stripe/checkout`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  //get selected Subscription
  static async getUserSubscription(email) {
    try {
      const token = getToken();

      const res = await axios.get(
        `${baseUrl}/subscription/getSelectedSubscription/${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  //cancel subscription
  static async cancelSubscription(subId) {
    try {
      const token = getToken();

      const res = await axios.get(
        `${baseUrl}/stripe/cancelSubscription/${subId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  //cancel paymentIntent
  static async cancelPaymentIntent(custId) {
    try {
      const token = getToken();

      const res = await axios.get(
        `${baseUrl}/stripe/cancelPaymentIntent/${custId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  //GET Invoice payment link
  static async getInvoiceLink(invoice) {
    try {
      const token = getToken();
      const res = await axios.get(
        `${baseUrl}/stripe/getInvoiceLink/${invoice}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
