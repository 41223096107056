import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
// import { TransitionProps } from '@material-ui/core/transitions';
import { Box, Button, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import CardContainer from '../../../../components/CardContainer';
import {useAppDispatch, useAppSelector} from '../../../../hooks/redux'
import { toggleAddProductDialog } from '../../../../redux/reducers/productsReducer'
import ProductRow from './ProductRow'
import { Exit } from '../../../../assets';
import BaseDialog from '../../../../components/BaseDialog';
import CommonButton from '../../../../components/Buttons/CommonButton';
import ActionButton from '../../../../components/Buttons/ActionButton';
import {TableContext} from '../ProductTable'



const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: 20
  },
  bottomButtonContainer: {
    paddingTop: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }

}))

interface props {
  handleAdd: (arg0: any) => void;
}

export default function AlertDialogSlide({handleAdd}: props) {
  // const { handleAdd } = React.useContext(TableContext)
  const { addProductDialogToggled, } = useAppSelector(state => state.products)
  const [listNewItems, setListNewItems] = React.useState([
    {
      sku: '',
      map: '',
      model: '',
    }
  ])
  const dispatch = useAppDispatch()
  const classes = useStyles()

  const handleSubmit = () => {
    // listNewItems.forEach(async (item, i) =>  await handleAdd(item))
    handleAdd(listNewItems)
    handleClose()
  }

  const handleAddRow = () => {
    setListNewItems([...listNewItems, {
      sku: '',
      map: '',
      model: '',
    }])
  }

  const handleClose = () => {
    setListNewItems([
      {
        sku: '',
        map: '',
        model: '',
      }
    ])
    dispatch(toggleAddProductDialog());
  };

  const handleChange = (type: string, value: string | number, index: number) => {
    const newState = listNewItems.map((item, i) => {
      if(i === index) {
        return {
          ...item,
          [type]: value 
        }
      }
      return item
    })
    setListNewItems(newState)
  }

   return (
    <BaseDialog
      open={addProductDialogToggled}
      close={handleClose}
      title="Add Product"
    >     
        {listNewItems.map((item, i) => (
          <ProductRow key={i} data={item} onChange={handleChange} index={i}/>
        ))}
      <Box className={classes.buttonContainer}>
        <CommonButton label="Add 1 More" width={162} onClick={handleAddRow} />
      </Box>      
      <Box className={classes.bottomButtonContainer}>
        <ActionButton label="Upload New SKU(s)" onClick={handleSubmit} />
      </Box>      
    </BaseDialog>
  );
}