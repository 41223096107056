import { Box, InputLabel, makeStyles, TextField, Typography, withStyles } from '@material-ui/core';
import { Label } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

const StyledTextField = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
    '& .MuiOutlinedInput-root': {  
      '& fieldset': {
        borderColor: theme.palette.secondary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '13px 14px',
    }
  }
}))(TextField)

const useStyles = makeStyles((theme) => ({
  forgotParent: {
    position: 'relative',
    marginTop: 10
  },
  fullWidth: {
    width: '586px !important'
  },
  link: {
    position: 'absolute',
    right: 0,
    top: -20,
    textDecoration: 'none'
  },
  text: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.primary.dark,
  },
  subText: {
    weight: 400,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.black
  },
  height: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  }
}))

interface props {
  placeHolder?: string
  forgotPassword?: boolean,
  fullWidth?: boolean,
  fieldHeader?: string,
  width?: number
  height?: number
  onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void
  value?: string | number
  name?: string | undefined
  id?: number | undefined
  type?: string | undefined
  error?: boolean,
  helperText?: string,
  label?: string
}

export default function CommonTextField({
    name,
    error,
    helperText,
    type,
    value,
    onChange,
    placeHolder, 
    forgotPassword, 
    fullWidth, 
    fieldHeader, 
    label,
    width = 336, 
    height}: props) {
  const classes = useStyles()

  return (
    <Box style={{width: width, height: height}} className={`
        ${forgotPassword && classes.forgotParent}
        ${fullWidth && classes.fullWidth}
        ${height && classes.height}
      `}>
      {forgotPassword && (
        <Link to="/auth/forgot-password" className={classes.link}>
          <Typography variant="body1" className={classes.text}>
            Forgot password?
          </Typography>
        </Link>
      )}
      {
        fieldHeader && (
          <Typography className={classes.subText}>
            {fieldHeader}
          </Typography>
        )
      }
      <StyledTextField
        label={label}
        style={{width: width}}
        placeholder={placeHolder}
        variant="outlined"
        fullWidth={fullWidth ? true : false}
        onChange={onChange}
        value={value}
        name={name}
        type={type}
        error={error}
        helperText={helperText}
      /> 
    </Box>
  )
}