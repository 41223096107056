import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import Ajax from '../../../Ajax'
// inviteCode
// 56wnpV


export const currentMan = createAsyncThunk(
  'products/getMan',
  async (id: number) => {
    const currentMan = await Ajax.getManufacturer(id)

    return currentMan
  }
)

// ADS THUNKS

export const getAdsReport = createAsyncThunk(
  'brandPanel/getAdsReport',
  async (id: number) => {
    const ads = await Ajax.getManufacturersAds(id);

    return ads
  }
)