import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import {
  getReviews,
  deleteReview,
  deleteMultipleReviews,
  updateReview,
} from "./thunks";

// Define a type for the slice state
interface reviewState {
  reviews: undefined | any;
  loading: boolean;
}

// Define the initial state using that type
const initialState: reviewState = {
  reviews: undefined,
  loading: false,
};

export const reviewSlice = createSlice({
  name: "review",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setReviews: (state, action: PayloadAction<number>) => {
      state.reviews = action.payload;
    },
    setReviewTableData: (state, action: PayloadAction<any>) => {
      state.reviews = action.payload;
    },
  },
  extraReducers: {
    [getReviews.pending.toString()]: (state, action) => {
      state.reviews = action.payload;
      state.loading = true;
    },
    [getReviews.rejected.toString()]: (state, action) => {
      state.loading = false;
    },
    [getReviews.fulfilled.toString()]: (state, action) => {
      state.reviews = action.payload;
      state.loading = false;
    },
    [deleteReview.fulfilled.toString()]: (state, action) => {
      state.reviews = state.reviews.filter(
        (review: any) => review.id !== action.payload
      );
      state.loading = false;
    },
    [deleteMultipleReviews.fulfilled.toString()]: (state, action) => {
      state.reviews = state.reviews.filter(
        (review: any) => !action.payload.includes(review.id)
      );
      state.loading = false;
    },
    [updateReview.pending.toString()]: (state, action) => {
      state.loading = true;
    },
    [updateReview.rejected.toString()]: (state, action) => {
      state.loading = false;
    },
    [updateReview.fulfilled.toString()]: (state, action) => {
      state.reviews = [
        ...state.reviews.filter((review: any) => review.id !== action.payload.id),
        action.payload,
      ].sort((a, b) => {
        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
      });
      state.loading = false;
    },
  },
});

export const { setReviews } = reviewSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.products;

export default reviewSlice.reducer;
