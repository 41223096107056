import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    borderRadius: 8,
    border: `1px solid ${theme.palette.primary.light}`,
    position: 'relative'
  },
  textContainer: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  centerText: {
    fontSize: 24,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.primary.light
  },
  populated: {
    border: 'none'
  }
}))

interface props {
  children?: React.ReactNode,
  label?: string
}

export default function CenterContentContainer({children, label}: props) {

  const classes = useStyles()

  return (
    <Box className={`${classes.root} ${children && classes.populated}`}>
      { !children && (
      <Box className={classes.textContainer}>
        <Typography variant="body1" className={classes.centerText}>
          {label}
        </Typography>
      </Box>
      )}
      {children}
    </Box>
  )
}