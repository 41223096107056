import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CardContainer from "../../../../components/CardContainer";
import BackupIcon from "@material-ui/icons/Backup";
import { Exit } from "../../../../assets";
import ShowExample from "./ShowExample";
import CommonTextField from "../../../../components/CommonTextField";
import ActionButton from "../../../../components/Buttons/ActionButton";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { toggleListDialog } from "../../../../redux/reducers/productsReducer";
import Dropzone from "react-dropzone";
import { CSVLink } from "react-csv";
import { ProductListContext } from "..";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 24,
  },
  headingContainer: {
    boxSizing: "content-box",
    width: "100%",
    height: 32,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 40,
  },
  header: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(24),
    color: "#000",
  },
  icon: {
    padding: 0,
    color: theme.palette.primary.main,
  },
  productListLanding: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 248,
    width: 520,
    border: `1px solid ${theme.palette.secondary.light}`,
  },
  centerLandingInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  landingIcon: {
    width: 87,
    height: 55,
  },
  landingText: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    alignText: "center",
  },
  listAndAccordion: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: 520,
  },
  listContainer: {
    minHeight: 58,

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  list: {},
  listItem: {
    padding: 0,
    height: 20,
  },
  listItemText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    marginLeft: -42,
  },
  listItemIcon: {},
  dot: {
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: theme.palette.common.black,
  },
  accordionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  textFieldContainer: {
    paddingTop: 32,
    paddingBottom: 40,
  },
  buttonContainer: {},
  check: {
    display: "flex",
    justifyContent: "flex-start",
    marginRight: "2in",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const { productListDialogToggled } = useAppSelector(
    (state) => state.products
  );
  const { readFile, createProductList } = React.useContext(ProductListContext);
  const dispatch = useAppDispatch();
  const [file, setFile] = React.useState();
  const [listName, setListName] = React.useState("");
  const [multipleBrandsChecked, setmultipleBrandsChecked] =
    React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setListName("");
    setFile(null);
    setmultipleBrandsChecked(false);
    dispatch(toggleListDialog());
  };

  const onDrop = React.useCallback(async (files, multipleBrandsChecked) => {
    readFile(files, multipleBrandsChecked)
      .then(() => {
        setFile(files);
      })
      .catch((err) => window.alert(`File reading error: ${err}`));
  }, []);

  const handleFileUpload = async () => {
    if (!listName || listName === "") {
      window.alert("Please enter a name for the product list");
      return;
    }
    await createProductList(listName, multipleBrandsChecked);
    setListName("");
    setFile(null);
    setmultipleBrandsChecked(false);
  };

  const handleListName = (e) => {
    setListName(e.target.value);
  };

  const handleCheckBox = (e) => {
    setmultipleBrandsChecked(e.target.checked);
  };

  return (
    <div>
      <Dialog
        open={productListDialogToggled}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        // className={classes.root}
      >
        <CardContainer width={634} minHeight={700}>
          <Box className={classes.root}>
            <Box className={classes.headingContainer}>
              <Typography className={classes.header} variant="h1">
                Upload Product List
              </Typography>
              <IconButton className={classes.icon} onClick={handleClose}>
                <Exit />
              </IconButton>
            </Box>
            <div className={classes.check}>
              <FormControlLabel
                control={
                  <Checkbox
                      disabled={file}
                    checked={multipleBrandsChecked}
                    onChange={handleCheckBox}
                  />
                }
                label="Product list contains multiple-brand SKUs"
              />
            </div>
            <Dropzone
              onDrop={(acceptedFiles) =>
                onDrop(acceptedFiles, multipleBrandsChecked)
              }
            >
              {({ getRootProps, getInputProps }) => (
                <Box className={classes.productListLanding}>
                  {!file && (
                    <section>
                      <div
                        {...getRootProps()}
                        className={classes.centerLandingInfo}
                      >
                        <input {...getInputProps()} />
                        <BackupIcon className={classes.landingIcon} />
                        <Typography className={classes.landingText}>
                          Drag file here or click to select
                        </Typography>
                      </div>
                    </section>
                  )}
                  {file && (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Typography className={classes.landingText}>
                          {file[0].name}
                        </Typography>
                      </div>
                    </section>
                  )}
                </Box>
              )}
            </Dropzone>
            <Box className={classes.listAndAccordion}>
              <Box className={classes.listContainer}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <Box className={classes.dot}></Box>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listItemText}>
                        Must be CSV File
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <Box className={classes.dot}></Box>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listItemText}>
                        BBY SKU, MODEL, MAP starting in cell A1
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <Box className={classes.dot}></Box>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listItemText}>
                        No blank rows
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
              <Box className={classes.accordionContainer}>
                <ShowExample />
              </Box>
            </Box>

            <Box className={classes.textFieldContainer}>
              <CommonTextField
                placeHolder="List name"
                onChange={handleListName}
                value={listName}
              />
            </Box>
            <Box className={classes.buttonContainer}>
              <ActionButton
                label="Upload Product List"
                disabled={!file}
                onClick={() => handleFileUpload()}
              />
            </Box>
          </Box>
        </CardContainer>
      </Dialog>
    </div>
  );
}
