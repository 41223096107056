import { Box, Container, makeStyles } from '@material-ui/core';
import React from 'react';
import TopNav from '../components/TopNav'
import AccountDialog from './AccountDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
    padding: 0
  },
  container: {
    width: '100%',
    height: '100%',
    padding: 0
  }
}))

interface props {
  children: React.ReactNode
}

const DefaultView = React.memo(({children}: props) => {
  const classes = useStyles()
  return(
    <Container maxWidth="lg"className={classes.root}>
      <Box className={classes.container}>
        {children}
      </Box>
      <AccountDialog />
    </Container>
  )
})

export default DefaultView