import {
  Fade,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { More } from "../../assets";
import typehole from "typehole";

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.common.white,
    // width: 71,
    height: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
}))((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    padding: "0 4px",
    fontSize: 14,
    "&:focus": {
      // backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  delete: {
    color: theme.palette.error.main,
  },
}));

// interface props {
//   actions: {
//     T
//   }
// }
// Need to make this a bit more dynamic for reuse through out the application

interface actions {
  className?: string;
  actions?: Actions;
  editing?: boolean;
  children?: React.ReactNode
  Link?: React.ReactNode
  close?: boolean;
}
interface Actions {}

export default function MoreActionMenu(props: actions) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const {Link} = props

  React.useEffect(() => {
    if(props.close) handleClose()
  },[props.close])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton {...props} onClick={handleClick}>
        <More />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        
        {props.actions && Object.entries(props.actions).map(([key, value]) => {
          if(!props.editing && key !== 'save') {
            return (
            <StyledMenuItem
              className={`${key === "delete" && classes.delete}`}
              onClick={() => {
                value()
                handleClose()
              }}
            >
              {key}
            </StyledMenuItem>
          )  
        } else if (props.editing && key !== 'edit') {
          return (
            <StyledMenuItem
              className={`${key === "delete" && classes.delete}`}
              onClick={() => {
                value()
                handleClose()
              }}
            >
              {key}
            </StyledMenuItem>
          )
        }
      })}
      {props.children}
      </StyledMenu>
    </>
  );
}
