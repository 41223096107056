import {
  TableHead,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  Typography,
  withStyles,
  Box,
  TableRow,
  Collapse,
  IconButton,
  Link,
  Checkbox,
  Tooltip
} from "@material-ui/core";
import { ExpandMoreRounded, ExpandLessRounded } from "@material-ui/icons";
import React from "react";
import MoreActionMenu from "../../../../components/MoreActionMenu";
import Stars from "./Stars";
import UpDownRow from "./UpDownRow";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { deleteReview } from "../../../../redux/reducers/reviewReducer/thunks";
import { CSVLink } from "react-csv";

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontWeight: 600,
      letterSpacing: "5%",
    },
    body: {
      fontSize: theme.typography.pxToRem(14),
    },
    root: {
      "&.MuiTableCell-root": {
        padding: 8,
      },
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const StyledTooltip = withStyles({
  tooltipPlacementTop: {
    margin: "-25px 0 0 40px",
  },
})(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {},
  topHeaders: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    borderTop: `2px solid #e2e2e2`,
    // borderBottom: `2px solid #e2e2e2`,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    zIndex: 100,
  },
  dateText: {
    padding: "1rem",
  },
  checkBox: {},
  actionContainer: {
    paddingRight: 8,
  },
  export:{
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    textDecoration: 'none',
    padding: '0 4px'
  }
}));

const ReviewCollapse = React.memo(({ reviews, date, index, open, id,handleCheckBoxToggle,selected,showCheckBox }) => {
  const [toggled, setToggled] = React.useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {name} = useAppSelector(state => state.brandPanel.currentMan)
  const [reviewDialog, setReviewDialog] = React.useState(false)

  const handleDelete = (id) => {
    dispatch(deleteReview(id));
  };

  const handleToggled = () => {
    setToggled(!toggled);
  };

  const closeReviewDialog = async() => {
    await setReviewDialog(true)
    await setReviewDialog(false)
  }

  const createCSV = (values) => {
    if (!values.length) return [];

    const parseLink = (model, url) => {
      return `=HYPERLINK(""${url.replaceAll(/\"/g, "")}"",""${model}"")`
    }
    return values.map(val => {
      let avgReview = val.available === "Missing" ? 'Product Missing' : (val.averageReview ? val.averageReview : 'No Ratings Yet')
      let trendData = val.available === "Missing" ? '' : (!val.averageReview || !val.lastWeekReview || isNaN(val.averageReview) || isNaN(val.lastWeekReview) ? 'No Trend Data'
                      : (val.averageReview > val.lastWeekReview ? '↑'
                        : (val.averageReview < val.lastWeekReview ? '↓'
                          : '-')))
      return {
        SKU: val.sku,
        Model: val.model,     // parseLink(val.model, val.reviewUrl),
        Description: val.description.replaceAll(",", "").replaceAll(/\"/g, ""),
        'Average Review': avgReview,
        'Weekly Trend': trendData,
        'No. of Reviews': val.totalReviews,
        'New Reviews': (val.totalReviews && val.lastWeekTotalReview) ? (val.totalReviews - val.lastWeekTotalReview) : '-'
      }
    });
  };

  return (
    <>
      <Box className={classes.topContainer}>
        <Box className={classes.dateContainer}>
        { showCheckBox && (
            <Checkbox className={classes.checkBox} onClick={() => {handleCheckBoxToggle()}} checked={selected}/>
          )}  
          <Typography className={classes.dateText}>{date}</Typography>
          {!toggled ? (
            <IconButton onClick={() => handleToggled(!toggled)}>
              <ExpandMoreRounded />
            </IconButton>
          ) : (
            <IconButton onClick={() => handleToggled(!toggled)}>
              <ExpandLessRounded />
            </IconButton>
          )}
        </Box>
        <Box className={classes.actionContainer}>
          <MoreActionMenu
            close={reviewDialog}
            actions={{
              delete: () => handleDelete(id),
            }}
          >
            {" "}
            <CSVLink
              filename={`${name} Reviews (${date}).csv`}
              data={createCSV(reviews.value || [])}
              className={classes.export}
              onClick={() => closeReviewDialog()}
            >
              Export to CSV
            </CSVLink>
          </MoreActionMenu>
        </Box>
      </Box>
      <Collapse in={open || toggled} timeout="auto" unmountOnExit>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left">SKU</StyledTableCell>
              <StyledTableCell align="left">MODEL</StyledTableCell>
              <StyledTableCell align="left">DESCRIPTION</StyledTableCell>
              <StyledTableCell align="center">AVERAGE REVIEWS</StyledTableCell>
              <StyledTableCell align="center">LAST WEEK TREND </StyledTableCell>
              <StyledTableCell align="center">No. OF REVIEWS </StyledTableCell>
              <StyledTableCell align="center"> NEW REVIEWS</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {reviews &&
              reviews.value.map((row) => (
                <StyledTableRow key={row.sku}>
                  <StyledTableCell component="th" scope="row" align="left">
                    {row.sku}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.reviewUrl ? (
                      <StyledTooltip title="BBY Review Page" placement='top'>
                        <Link href={row.reviewUrl} target="_blank">
                          {row.model}
                        </Link>
                      </StyledTooltip>
                      
                    ) : (
                      row.model
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ maxWidth: 295 }}>
                    {row.description}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Stars rating={row.averageReview} available={row.available}/>
                  </StyledTableCell>
                  {row.averageReview && row.lastWeekReview ? (
                    <UpDownRow
                      trend={row.averageReview > row.lastWeekReview ? 'up' : (row.averageReview < row.lastWeekReview ? 'down' : 'same')}
                    />
                  ) : (
                    row.available === "Missing" ?
                      <StyledTableCell align="center">
                      </StyledTableCell>
                      :
                      <StyledTableCell align="center">
                        No Trend Data
                      </StyledTableCell>
                  )}
                  <StyledTableCell align="center">
                    {row.totalReviews && row.totalReviews }
                  </StyledTableCell>
                  <StyledTableCell align="center">
                  {(row.totalReviews && row.lastWeekTotalReview) ? (row.totalReviews - row.lastWeekTotalReview) : ''}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </Collapse>
    </>
  );
});

export default ReviewCollapse;
