import { Box, Button, Dialog, makeStyles, Slide } from '@material-ui/core';
import React from 'react';
import CardContainer from '../../components/CardContainer';
import CenterHeader from '../../components/CenterHeader';
import CommonTextField from '../../components/CommonTextField';
import CommonButton from '../../components/Buttons/CommonButton'
import AuthBottomActions from '../../components/AuthBottomActions'
import {Formik, useFormik} from 'formik'
import * as Yup from 'yup';
import Ajax from '../../Ajax'
import { logIn } from '../../redux/reducers/authReducer'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import BaseDialog from '../../components/BaseDialog'
import { toggleAccountDialog } from '../../redux/reducers/authReducer'
import { TransitionProps } from '@material-ui/core/transitions';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  signUpButton: {
    
  }
}))

 

interface LoginFormValues {
  email: string,
  password: string
  passwordValidate: string,
  inviteCode: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function AccountDialog() {
  const { currentUser, accountDialog } = useAppSelector(state => state.auth)
  const [loading, setLoading] = React.useState(false)
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
      ),
    passwordValidate: Yup.string()
    .required()
    .when("password", {
      is: (val:string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
  });

  const initialValues: LoginFormValues = {
    email: currentUser ? currentUser.email : '',
    password: '',
    passwordValidate: '',
    inviteCode: ''
  }

  const handleToggleAccountDialog = () => {
    dispatch(toggleAccountDialog())
  }
  

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async ({email, password}, { setStatus, setSubmitting }) => {
      try {  
        setLoading(true);
        const res = await Ajax.updateCurrentUser({ email, password });
  
        if (res.token) {
          localStorage.setItem('turn-sku-token', res.token);
        }
  
        setLoading(false);
        handleToggleAccountDialog()
      } catch (e) {
        setLoading(false);
        window.alert(e.message);
      }
  }})


  return (
    <div>
      <Dialog
        open={accountDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleToggleAccountDialog} 
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
      <form onSubmit={formik.handleSubmit}>
        <Box className={classes.root}>
          {/* <CenterHeader title="Create an account" /> */}
          <CardContainer width={386} height={444} auth>
            <CommonTextField 
              placeHolder="Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              // helperText={formik.errors.email} 
              // error={!!formik.errors.email && formik.touched.email}
            />
            <CommonTextField 
              placeHolder="Password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              // helperText={formik.errors.password} 
              // error={!!formik.errors.password && formik.touched.password}
            />
            <CommonTextField 
              placeHolder="Confirm Password"
              name="passwordValidate"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.passwordValidate}
              // helperText={formik.errors.passwordValidate} 
              // error={!!formik.errors.passwordValidate && formik.touched.passwordValidate}
            />
            <CommonButton label="Reset Password" type="submit"/>
          </CardContainer>
        </Box>
      </form>
    </Dialog>
    </div>
  )
}