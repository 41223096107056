import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Star from '../../../../assets/Star'
import StarFilled from '../../../../assets/StarFilled'
import ReactStars from "react-rating-stars-component";

const useStyles = makeStyles((theme) => ({
  parentRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 140,
    marginLeft: -12
  },
  text: {
    minWidth:120,
    paddingLeft: 16
  },
  star: {
    color: theme.palette.warning.main
  },
  filledStar: {}
}))

export default function Stars({rating, available}) {
  const classes = useStyles()
  const loaded = React.useRef(false)




  return (
    <Box className={classes.parentRoot}>
      {available !== "Missing" ?
        <ReactStars 
          count={5}
          value={ parseInt(rating) }
          size={24}
          activeColor='#FFC254'
          edit={false}
        />: ''}
      <Typography className={classes.text}>
        {rating ? (
          `${rating} / 5`
        ) : (
          available === "Missing" ? `Product Missing` : `No Ratings Yet`
        )}
      </Typography>
    </Box>
  )
}