import React, { StatelessComponent } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import App from './App';
import { Store } from "redux";
// import { RootState, RootAction } from "./redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// interface Props {
//   store: Store<RootState, RootAction>;
//   app: StatelessComponent;
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate persistor={persistStore(store)}> */}
        <App />
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
