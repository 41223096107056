import React from 'react';
import CenterContentContainer from '../../components/CenterContentContainer';
import DefaultView from '../../views/DefaultView'
import { makeStyles, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  }, 
  textContainer: {
    height: 250,
    width: '100%',
    maxWidth: 556,
    minWidth: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 126
  },
  text: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.common.white,
    textAlign: 'center'
  },
}))

export default function ScreenSizeWarning() {
  const classes = useStyles()
  return (
    <DefaultView>
      <Box className={classes.root}>
        <Box className={classes.textContainer}>
          <Typography className={classes.text}>
            TurnSKU is best viewed in a large resolution setting.
          </Typography>
          <Typography className={classes.text}>
            Please visit this application on a dektop or laptop computer.
          </Typography>
        </Box>
      </Box>
    </DefaultView>
  )
}