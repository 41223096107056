import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import example from '../../../../assets/example.png'
import { Box } from '@material-ui/core';

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    // boxShadow: 'none',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      // margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    width: 520,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    padding: 0,
    textDecoration: 'underline',
    '&.MuiAccordion-root': {
      backgroundColor: 'none',
    }, 
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    // marginBottom: -1,
    // minHeight: 56,
    '&$expanded': {
      // minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      // margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 0,
   
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
  
  },
  exampleContainer: {
    // width: 318,
    // height: 117
  },
  example: {
    width: '100%',
    height: '100%'
  }
}))

export default function ShowExample() {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<{}>) => {
    setExpanded(!expanded);
  };

  return (
    <Box className={classes.root}>
      <Accordion expanded={expanded} onChange={handleChange} elevation={0}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{expanded ? 'Hide example' : 'Show example'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.exampleContainer}>
            <img src={example} alt="example file" className={classes.example}/>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}