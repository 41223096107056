import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CustomTabs from "../CustomTabs";
import {
  Box,
  Button,
  TableFooter,
  TablePagination,
  Tabs,
  Typography,
} from "@material-ui/core";
import MoreActionMenu from "../../../../components/MoreActionMenu";
import { ProductListContext } from "..";
import AddProductsDialog from "../AddProductsDialog";
import Ajax from "../../../../Ajax";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import TablePaginationActions from "./TablePaginationActions";
import {
  toggleAddProductDialog,
  setCurrentListId,
} from "../../../../redux/reducers/productsReducer";
import { updateProductList } from "../../../../redux/reducers/productsReducer/thunks";

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontWeight: 600,
      letterSpacing: "5%",
    },
    body: {
      fontSize: theme.typography.pxToRem(14),
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    top: -40,
    width: 1200,
  },
  table: {
    // minWidth: 580,

    backgroundColor: theme.palette.common.white,
  },
  tableContainer: {
    borderRadius: 4,
    borderTopLeftRadius: 0,
  },
  header: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.black,
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    textTransform: "none",
    height: 41,
    width: 128,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonText: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.white,
  },
  disabledButton: {
    backgroundColor: theme.palette.secondary.light,
    textTransform: "none",
    height: 41,
    width: 128,
  },
  topHeaders: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
  },
  center: {
    marginRight: 50,
  },
  actionColumn: {
    width: 50,
  },
  bottomPages: {
    backgroundColor: theme.palette.secondary.light,
    height: 60,
    width: "100%",
  },
  headerContainer: {
    height: 60,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
  },
  bottomTextContainer: {
    width: "100%",
    height: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  exportCsvButton: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    textDecoration: "none",
    padding: "0 4px",
  },
}));

export const TableContext = React.createContext({});

export default function ProductTable() {
  const classes = useStyles();
  const { currentListId, productsList } = useAppSelector(
    (state) => state.products
  );
  const dispatch = useAppDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [recentReport, setRecentReport] = React.useState(null);
  const [editing, setEditing] = React.useState(null);
  const [edits, setEdits] = React.useState({
    sku: "",
    map: "",
    model: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // End Pagination functions

  // Need to figure this out
  const getModel = (sku) => {
    if (!recentReport) return "No report run for this list";
    if (typeof sku === "object" && sku.length) sku = parseInt(sku[0]);

    const prod = recentReport.find((r) => r.sku === parseInt(sku));
    return prod ? prod.model : "Unable to find model";
  };

  const handleEdit = (i) => {
    if (editing !== null) return;

    setEditing(i);
    const pair = productsList.list[i];

    setEdits(pair);
  };

  const handleSave = async () => {
    const editedList = productsList.list.map((pair, i) => {
      if (i === editing) {
        return edits;
      } else {
        return pair;
      }
    });

    dispatch(
      updateProductList({
        id: productsList.id,
        data: { ...productsList, list: editedList },
      })
    );
    setEdits({ sku: "", map: "", model: "" });
    setEditing(null);
  };

  const handleDelete = async (i) => {
    const editedList = productsList.list.filter((pair, j) => i !== j);
    dispatch(
      updateProductList({
        id: productsList.id,
        data: { ...productsList, list: editedList },
      })
    );
    setEdits({ sku: "", map: "", model: "" });
    setEditing(null);
  };

  async function handleAdd(items) {
    var editedList = productsList.list;
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      const comparator = productsList.list[0] || null;
      if (productsList.multipleBrands == false) {
        const productDetails = comparator
          ? await Ajax.fetchBestBuyApi([item.sku, comparator.sku])
          : await Ajax.fetchBestBuyApi([item.sku]);

        if (
          productDetails.length >= 2 &&
          productDetails[0].brand !== "" &&
          productDetails[1].brand !== "" &&
          productDetails[0].brand !== productDetails[1].brand
        ) {
          window.alert(
            `Multiple brands detected: ${productDetails[0].brand}, ${productDetails[1].brand}. Product lists should be specific to one brand`
          );
          return;
        }
      }
      const existingProd = productsList.list.find(
        (p) => parseInt(p.sku) === parseInt(item.sku)
      );

      if (existingProd) {
        window.alert(
          `Product with SKU ${existingProd.sku} already exists in list.`
        );
        return;
      }
      editedList = [
        {
          sku: item.sku,
          model: item.model,
          map: item.map,
        },
        ...editedList,
      ];
    }

    await dispatch(
      updateProductList({
        id: productsList.id,
        data: { ...productsList, list: editedList },
      })
    );
  }

  const productsCsvData =
    (productsList.list && [
      ...productsList.list.map((p) => Object.values(p)),
    ]) ||
    null;

  return (
    <TableContext.Provider value={{ handleAdd }}>
      <Box className={classes.root}>
        <CustomTabs />
        <Paper style={{ borderTopLeftRadius: 0 }}>
          <Box className={classes.headerContainer}>
            <Typography className={classes.header}>
              My current product list {productsList.multipleBrands ? `(Multi-Brand SKUs)` : `(Single-Brand SKUs)`}
            </Typography>
            <Box>
              <Button
                className={
                  productsList.name ? classes.button : classes.disabledButton
                }
                onClick={() => dispatch(toggleAddProductDialog())}
                disabled={productsList.name ? false : true}
              >
                <Typography className={classes.buttonText}>
                  Add Product
                </Typography>
              </Button>
              <MoreActionMenu>
                <CSVLink
                  filename={productsList.name ? `${productsList.name}.csv` : ""}
                  className={classes.exportCsvButton}
                  data={productsCsvData}
                >
                  Export Products to CSV
                </CSVLink>
              </MoreActionMenu>
            </Box>
          </Box>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead></TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={classes.topHeaders}
                  >
                    SKU
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    className={`${classes.topHeaders} ${classes.center}`}
                  >
                    MODEL NUMBER
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.topHeaders}>
                    MAP
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    className={classes.topHeaders}
                  ></StyledTableCell>
                </StyledTableRow>
                {productsList.list &&
                  (rowsPerPage > 0
                    ? productsList.list.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : productsList
                  ).map((row, i) => (
                    <StyledTableRow key={row.sku}>
                      <TableCell align="left">
                        {editing === i ? (
                          <TextField
                            variant="filled"
                            value={edits.sku}
                            onChange={(e) =>
                              setEdits({ ...edits, sku: e.target.value })
                            }
                          />
                        ) : (
                          <Typography variant="body1" style={{ color: "#000" }}>
                            {row.sku}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        {editing === i ? (
                          <TextField
                            variant="filled"
                            value={edits.model}
                            onChange={(e) =>
                              setEdits({ ...edits, model: e.target.value })
                            }
                          />
                        ) : (
                          <Typography variant="body1" style={{ color: "#000" }}>
                            {row.model || getModel(row.sku)}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        {editing === i ? (
                          <TextField
                            variant="filled"
                            value={edits.map}
                            onChange={(e) =>
                              setEdits({ ...edits, map: e.target.value })
                            }
                          />
                        ) : (
                          <Typography variant="body1" style={{ color: "#000" }}>
                            {row.map}
                          </Typography>
                        )}
                      </TableCell>

                      <StyledTableCell
                        align="right"
                        className={classes.actionColumn}
                      >
                        <MoreActionMenu
                          actions={{
                            edit: () => handleEdit(i),
                            save: () => handleSave(),
                            delete: () => handleDelete(i),
                          }}
                          editing={editing === i}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
              <TableFooter>
                {productsList.name ? (
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      colSpan={3}
                      count={(productsList && productsList.list.length) || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                ) : (
                  ""
                )}
              </TableFooter>
            </Table>
          </TableContainer>
          {productsList.name ? (
            ""
          ) : (
            <Box className={classes.bottomTextContainer}>
              <Typography className={classes.bottomText}>
                Upload Products List to get started
              </Typography>
            </Box>
          )}
        </Paper>
        <AddProductsDialog handleAdd={handleAdd} />
      </Box>
    </TableContext.Provider>
  );
}
