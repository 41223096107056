import { Fade, IconButton, makeStyles, Menu, MenuItem, withStyles } from '@material-ui/core';
import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { logIn } from '../../redux/reducers/authReducer'
import { Link } from 'react-router-dom';
import { toggleAccountDialog } from '../../redux/reducers/authReducer'

const useStyles = makeStyles((theme) => ({
  account: {
    position: 'relative',
    // height: 42,
    right: 16
  },
  accountIcon: {
    fontSize: 40,
    color: theme.palette.common.white
  },
  menu: {
    // backgroundColor: theme.palette.common.white
  },
  link: {
    textDecoration: 'none'
  }
}))

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: "1px solid #d3d4d5",
    backgroundColor: theme.palette.common.white
  }
}))(Menu)

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      // backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);



export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {currentUser} = useAppSelector(state => state.auth);
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('turn-sku-token');
    setAnchorEl(null);
    dispatch(logIn(null));
    dispatch({type: 'USER_LOGGED_OUT'})
  }

  const handleToggleAccountDialog = () => {
    dispatch(toggleAccountDialog())
  }

  return (
    <>
      <IconButton className={classes.account} onClick={handleClick}>
        <AccountCircleIcon className={classes.accountIcon}/>
      </IconButton>
        <StyledMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          className={classes.menu}
        
        >
        <StyledMenuItem onClick={handleToggleAccountDialog}>
          Account
        </StyledMenuItem>
        {currentUser && currentUser.email === "david@ebcarlson.com" && currentUser.isAdmin ?
          <StyledMenuItem onClick={handleClose}>
            <Link to="/manage" className={classes.link}>Manage</Link>
          </StyledMenuItem>
        : ''}

          <StyledMenuItem onClick={handleClose}>
            <Link to="/subscription" className={classes.link}>Subscription</Link>
          </StyledMenuItem>

        <StyledMenuItem onClick={handleClose}>
          <a 
            className={classes.link}
            rel="noopener noreferrer"
            href={`mailto:david.rhodes@turnsku.com`}
          >
            Support
          </a>
        </StyledMenuItem>
        <StyledMenuItem onClick={handleLogout}>Log Out</StyledMenuItem>
      </StyledMenu>
    </>
  )
}