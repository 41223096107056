import { makeStyles,Grid} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 24px',
    bottom: 0,
    fontFamily: theme.typography.fontFamily
  },
  copyright: {
    marginTop: 'auto'
  },
  image: {
    right:5,
    textOverflow:'text-wrap',
    fontFamily: theme.typography.fontFamily,
    color:"white",
    textAlign:'center'
  },
  disclaimer: {
    display: 'inline-block',
    maxWidth: '170px',
    fontSize: '10px',
    textAlign: 'justify',
    backgroundColor: '#292c33',
    opacity: 0.6,
    padding: '0 5px'
  }
}));

const BottomFooter: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.copyright}>© TurnSKU, LLC</div>
      < div className={classes.image}>
        <span className={classes.disclaimer}>TurnSKU is not owned, operated or affillated in any way with Best Buy, Inc. TurnSKU, LLC uses data from an approved connection to Bestbuy.com</span>
        <a href="https://developer.bestbuy.com" target="_blank">
          <img src="https://developer.bestbuy.com/images/bestbuy-logo.png" alt="Best Buy Developer API" style={{width:90,height:61}} />
        </a>
      </div>    
    </div>

  );
 

};

export default BottomFooter;
