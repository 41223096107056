import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import Ajax from "../../../Ajax";
import ActionButton from "../../../components/Buttons/ActionButton";
import CenterContentContainer from "../../../components/CenterContentContainer";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import ReviewsTable from "./ReviewTable";
import Spinner from '../../../components/Spinner'


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  icon: {
    color: theme.palette.common.white,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "20px 0",
  },
}));


export const ReviewTableContext = React.createContext({});

export default function ReviewPanel() {
  const classes = useStyles();
  const { reviews, loading } = useAppSelector(
    (state) => state.reviews
  );


  const getReviewsByRating = (reviews: any, target: any) => {
    if (!reviews || !reviews.length) return "-";

    const byRating = reviews.filter((rev: any) => {
      return target.includes(rev.rating);
    });

    return byRating.length;
  };

 

  if(loading) return (
    <Box className={classes.root}>
      <CenterContentContainer label="Reviews Report Runs 1X per week on Wednesdays - check back later for data">
        <Spinner />
      </CenterContentContainer>
    </Box>
  )


  return (
    <ReviewTableContext.Provider value={{}}>
      <Box className={classes.root}>
        <CenterContentContainer label="Reviews Report Runs 1X per week on Wednesdays - check back later for data">
          { reviews && reviews.length > 0 && !loading && (
            <ReviewsTable />
          )}
        </CenterContentContainer>
      </Box>
    </ReviewTableContext.Provider>
  );
}
