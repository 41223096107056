import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 170,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

interface props {
  title: string
}

export default function CenterHeader({title}: props) {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Typography variant="h1">
        {title}
      </Typography>
    </Box>
  )
}