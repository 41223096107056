import * as React from "react";

function SvgExit(props) {
  return (
    <svg
      width={30}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.343 7.929A1 1 0 007.93 9.343L13.586 15l-5.657 5.657a1 1 0 101.414 1.414L15 16.414l5.657 5.657a1 1 0 001.414-1.414L16.414 15l5.657-5.657a1 1 0 00-1.414-1.414L15 13.586 9.343 7.929z"
        fill="#0047bb"
      />
    </svg>
  );
}

export default SvgExit;
