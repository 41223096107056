import { createAsyncThunk } from '@reduxjs/toolkit'
import { isBefore, isAfter } from "date-fns";
import Ajax from '../../../Ajax'

// REVIEW THUNKS ** is this correct?
// review reports is being generated using the SKU reports data
export const getReviews = createAsyncThunk(
  'reviews/getReviews',
  async (brandId) => {
    try {
      const productList = await Ajax.getManufacturersProducts(brandId)
      
      const reviews = await Ajax.getProductListReviews(productList.productListId)

      let sortedReviews = reviews.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });

      const reviewReports = sortedReviews.map(review => {
        const val = review.value.map(r => {
          return {
            sku: r.sku,
            model: r.model,
            description: r.description,
            reviewUrl: r.averageReview ? r.reviewPageUrl : r.url,
            averageReview: r.averageReview,
            lastWeekReview: r.lastWeekReview,
            available: r.available,
            totalReviews: r.totalReviews,
            lastWeekTotalReview: r.lastweekTotalReview
          }
        })
        return {
          ...review,
          value: val
        }
      })
      return reviewReports
    } catch(err) {
      console.log(err, "error")
    }
  }
)

export const deleteReview = createAsyncThunk(
  'reviews/deleteReview',
  async (reviewId) => {

    try {
      const response = await Ajax.deleteReview(reviewId);
      console.log({response})
      return reviewId
    } catch(err) {
      console.log(err, "error")
    }
  })

export const deleteMultipleReviews = createAsyncThunk(
  'reviews/deleteMultipleReviews',
  async (ids) => {

    try {
      const response = await Ajax.deleteMultipleReview(ids);
      console.log({response})
      return ids;
    } catch(err) {
      console.log(err, "error")
    }
  })
  
export const updateReview = createAsyncThunk(
  'reviews/updateReview',
  async ({id,data}) => {
    try {
      const response = await Ajax.updateReview(id, data);

      const val = response.value.map(r => {
          return {
            sku: r.sku,
            model: r.model,
            description: r.description,
            reviewUrl: r.averageReview ? r.reviewPageUrl : r.url,
            averageReview: r.averageReview,
            lastWeekReview: r.lastWeekReview,
            available: r.available
        }
      })
      const editedReview = {
        ...response,
        value: val
      }
      return editedReview;
    } catch (err) {
      console.log(err, "error");
    }
})
// END REVIEW THUNKS