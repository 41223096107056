import { Switch, Route } from "react-router-dom";
import AuthPage from "../pages/Auth/Login";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import SignUp from "../pages/Auth/SignUp";
import Dashboard from "../pages/Dashboard";
import BrandPanel from "../pages/BrandPanel";
import AdminPanel from "../pages/AdminPanel";
import NotFound from "./NotFound";
import SubscriptionPanel from "../pages/SubscriptionPanel";


export default function Router() {
  return (
    <>
      <Switch>
        <Route exact path="/brands" component={Dashboard}/>
        <Route exact path="/brand" component={BrandPanel}/>
        <Route exact path="/manage" component={AdminPanel}/>
        <Route exact path="/brand-panel/:id" component={BrandPanel}/>
        <Route exact path="/subscription" component={SubscriptionPanel} />
        <Route path='*' component={NotFound} />
      </Switch>
    </>
  );
}