import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import Ajax from '../../../Ajax'
// inviteCode
// 56wnpV

// export const fetchList = createAsyncThunk(
//   'products/getProductList',
//   async (id) => {
//     const list = await Ajax.getProductList(id);

//     return list
//   }
// )

export const manProducts = createAsyncThunk(
  'products/getManProducts',
  async (id) => {
    const manProducts = await Ajax.getManufacturersProducts(id)

    return manProducts
  }
)


export const getCurrentProductId = createAsyncThunk(
  'products/getCurrentProductId',
  async (id) => {
    const manProducts = await Ajax.getManufacturersProducts(id)
    return manProducts.products.id
  }
)



// PRODUCT THUNKS
export const getProductList = createAsyncThunk(
  'brandPanel/getProductList',
  async (id) => {
    const list = await Ajax.getProductList(id)
    if(!list) return {list:[]}
    return list
  }
)

export const updateProductList = createAsyncThunk(
  'brandPanel/updateProductList',
  async (_data) => {
    const {id, data} = _data
    const res = await Ajax.updateProductList(id, data);
    return res
  }
)

export const createProductListThunk = createAsyncThunk(
  'brandPanel/ createProductListThunk',
  async (data) => {
  
    const list = await Ajax.createProductList(data.data, data.id)
    return list
  }
)

// REPORTS THUNKS

export const getReports = createAsyncThunk(
  'brandPanel/getReports',
  async (listId) => {
    const listReports = await Ajax.getProductReports(listId);
    const listReportsSorted = listReports.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    return listReportsSorted
  }
)

export const createReports = createAsyncThunk(
  'brandPanel/createReports',
  async (data) => {

    const listReports = await Ajax.createReport(data.data.value, data.productsListId);
  
    return listReports
  }
)

export const deleteReport = createAsyncThunk(
  'brandPanel/deleteReport',
  async (id) => {
    const reports = await Ajax.deleteReport(id)
    return reports
  }
)

export const archiveReports = createAsyncThunk(
  'brandPanel/archiveReports',
  async (id, list) => {

    const listReports = await Ajax.getProductReports(id);
    

    const listReportsSorted = listReports.sort((a, b) => {

      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return listReportsSorted
  }
)

