import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  placeHolderImage: {
    width: 153.3,
    height: 137.48,
    backgroundColor: theme.palette.common.white,
    paddingTop: 23.1,
    paddingBottom: 39.8
  },
  text: {
    paddingBottom: 42,
    fontWeight: 400,
    fontSize: '1rem',
    textAlign: 'center'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    width: 67,
    height: 41,
    borderRadius: 6,
    padding: 11,
    backgroundColor: theme.palette.primary.main, 
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  buttonText: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: '1rem',
  },

}))

interface props {
  success?: boolean
  failure?: boolean
}

export default function AccountChangeStatus({success, failure}: props) {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Box className={classes.placeHolderImage}>
      </Box>
      <Typography>
      {
        success ? 
        "Success in updating your password! You may close this window" : 
        'Could not complete your request You may retry'
      }
        
      </Typography>
      <Box className={classes.buttonContainer}>
        <Button className={classes.button}>
          <Typography className={classes.buttonText}>
            {success ? "Close" : 'Retry'}
          </Typography>
        </Button>
      </Box>   
    </Box>
  )
}