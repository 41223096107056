import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAdsGrid, getAds, updateAdsGrid, deleteAllAdsGrids, deleteAdsGrids } from './thunks'


// First, create the thunk

type loading = 'idle' | 'loading'

interface state {
  dialogToggled: boolean
  adsGridError: undefined | string
  ads: undefined | any
  loading: boolean
}

const initialState: state = { 
  dialogToggled: false,
  adsGridError: undefined,
  ads: undefined,
  loading: false,
 }


// Then, handle actions in your reducers:
const adsGridSlice = createSlice({
  name: 'adsGrid',
  initialState,
  reducers: {
    toggleDialog: (state) => {
      state.dialogToggled = !state.dialogToggled 
    },
    adsGridError: (state, action: PayloadAction<string>) => {
      state.adsGridError = action.payload
    },
    updateAds: (state, action: PayloadAction<string>) => {
      state.ads = action.payload
    }
  },
  extraReducers: {
    [getAds.pending.toString()]: (state, action) => {
      state.ads = action.payload
      state.loading = true
    },
    [getAds.rejected.toString()]: (state, action) => {
      state.ads = action.payload
      state.loading = false
    },
    [getAds.fulfilled.toString()]: (state, action) => {
      state.ads = action.payload
      state.loading = false
    },
    [updateAdsGrid.fulfilled.toString()]: (state, action) => {
      state.ads = action.payload
    },
    [createAdsGrid.fulfilled.toString()]: (state, action) => {
      state.ads = [action.payload, ...state.ads]
    },
    [deleteAdsGrids.fulfilled.toString()]: (state, action) => {
      state.ads = state.ads.filter((ad:any) => { console.log('adId',ad.id);return action.payload.id !== ad.id})
    },
    [deleteAllAdsGrids.fulfilled.toString()]: (state, action) => {
      state.ads = action.payload
    }
  }
})


export const { toggleDialog, adsGridError, updateAds } = adsGridSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.auth.currentUser

export default adsGridSlice.reducer