import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CardContainer from "../../../../components/CardContainer";
import BackupIcon from "@material-ui/icons/Backup";
import { Exit } from "../../../../assets";
import CommonButton from "../../../../components/Buttons/CommonButton";
import ActionButton from "../../../../components/Buttons/ActionButton";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { toggleDialog } from "../../../../redux/reducers/adsGridReducer";
import { AdGridContext } from "..";
import Dropzone from "react-dropzone";
import { CSVLink } from "react-csv";
import { template } from "../template";
import * as FileSaver from 'file-saver';
import * as Excel from "exceljs/dist/exceljs.min.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 24,
  },
  headingContainer: {
    boxSizing: "content-box",
    width: "100%",
    height: 32,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 20,
  },
  header: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(24),
    color: "#000",
  },
  icon: {
    padding: 0,
    color: theme.palette.primary.main,
  },
  productListLanding: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 248,
    width: 520,
    border: `1px solid ${theme.palette.secondary.light}`,
  },
  centerLandingInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  landingIcon: {
    width: 87,
    height: 55,
  },
  landingText: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    alignText: "center",
  },
  listAndAccordion: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: 520,
    paddingBottom: 32,
  },
  listContainer: {
    minHeight: 58,

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  list: {},
  listItem: {
    padding: 0,
    height: 20,
  },
  listItemText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    marginLeft: -42,
  },
  listItemIcon: {},
  dot: {
    width: 5,
    height: 5,
    borderRadius: "50%",
    backgroundColor: theme.palette.common.black,
  },
  accordionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  textFieldContainer: {
    paddingTop: 32,
    paddingBottom: 40,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 90px",
  },
}));

const Transition = React.forwardRef(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function AdGridDialog() {
  const { dialogToggled } = useAppSelector((state) => state.adsGrid);
  const { readFile, createCSV, createAdGrid } = React.useContext(AdGridContext);
  const dispatch = useAppDispatch();
  const [file, setFile] = React.useState();
  const classes = useStyles();

  const handleClose = () => {
    dispatch(toggleDialog());
    setFile(undefined);
  };

  const handleFileUpload = (file) => {
    readFile(file)
      .then(() => {
        setFile(file);
      }).catch(err => window.alert(`File reading error: ${err}`));
  };

  const handleCreateAdGrid = () => {
    if (!file) return;
    createAdGrid();
    dispatch(toggleDialog());
    setFile(undefined);
  };

  const generateExcel = () => {
    var options = {
      filename: './streamed-workbook.xlsx',
      useStyles: true,
      useSharedStrings: true
    };
    let workbook = new Excel.Workbook(options);
    var worksheet = workbook.addWorksheet('Sheet 1');
    let alignmentOptions = {
      vertical: "middle",
      horizontal: "center",
      wrapText: '1'
    }
    worksheet.columns = [
      { header: 'Week', key: 'week', width: 15, style: { alignment: alignmentOptions } },
      { header: 'Start Date', key: 'startDate', width: 10, style: { alignment: alignmentOptions, numFmt: "mm/dd/yy" } },
      { header: 'End Date', key: 'endDate', width: 10, style: { alignment: alignmentOptions, numFmt: "mm/dd/yy" } },
      { header: 'Model', key: 'model', width: 12, style: { alignment: alignmentOptions } },
      { header: 'BBY SKU', key: 'sku', width: 10, style: { alignment: alignmentOptions } },
      { header: 'STC (Sell Through Credit)', key: 'stc', width: 12, style: { alignment: alignmentOptions, numFmt: "$#,##0.00" } },
      { header: 'Notes', key: 'notes', width: 12, style: { alignment: alignmentOptions } },
      { header: 'MSRP', key: 'msrp', width: 10, style: { alignment: alignmentOptions, numFmt: "$#,##0.00" } },
      { header: 'Suggested Savings', key: 'savings', width: 10, style: { alignment: alignmentOptions, numFmt: "$#,##0.00" } },
      { header: 'Suggested Promo', key: 'promo', width: 10, style: { alignment: alignmentOptions, numFmt: "$#,##0.00" } },
      { header: 'Gross Cost', key: 'gross', width: 10, style: { alignment: alignmentOptions, numFmt: "$#,##0.00" } },
      { header: 'Adjusted Cost', key: 'adjustedCost', width: 10, style: { alignment: alignmentOptions, numFmt: "$#,##0.00" } },
      { header: 'Reg % GM', key: 'regGMPercent', width: 10, style: { alignment: alignmentOptions, numFmt: "0%" } },
      { header: 'Adjusted % GM', key: 'adjustedGMPercent', width: 10, style: { alignment: alignmentOptions, numFmt: "0%" } },
      { header: 'Reg GM', key: 'regGM', width: 10, style: { alignment: alignmentOptions, numFmt: "$#,##0.00" } },
      { header: 'Adjusted GM', key: 'adjustedGM', width: 10, style: { alignment: alignmentOptions, numFmt: "$#,##0.00" } },
    ];
    worksheet.addRow({
      week: 'Enter fiscal ad week or leave blank',
      startDate: new Date("2022-02-07"),
      endDate: new Date("2022-02-13"),
      model: "MFG Model #",
      sku: "1234567",
      stc: 25,
      notes: "often used for % off retail",
      msrp: 249.99,
      savings: { formula: '=H2-J2' },
      promo: 199.99,
      gross: 162.49,
      adjustedCost: { formula: '=K2-F2' },
      regGMPercent: { formula: '=1-(K2/H2)' },
      adjustedGMPercent: { formula: '=1-(L2/J2)' },
      regGM: { formula: '=H2-K2' },
      adjustedGM: { formula: '=J2-L2' }
    });
    worksheet.getRow(1).font = { bold: true }
    let fileName="ad_grid_template.xlsx";
    workbook.xlsx.writeBuffer()
      .then(function(buffer) {
          // done buffering
          const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          FileSaver.saveAs(data, fileName);
      });
  }

  return (
    <div>
      <Dialog
        open={dialogToggled}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <CardContainer width={634} height={513}>
          <Box className={classes.root}>
            <Box className={classes.headingContainer}>
              <Typography className={classes.header} variant="h1">
                Upload Ad Grid
              </Typography>
              <IconButton className={classes.icon} onClick={handleClose}>
                <Exit />
              </IconButton>
            </Box>
            <Dropzone
              onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <Box className={classes.productListLanding}>
                  {!file && (
                    <section>
                      <div
                        {...getRootProps()}
                        className={classes.centerLandingInfo}
                      >
                        <input {...getInputProps()} />
                        <BackupIcon className={classes.landingIcon} />
                        <Typography className={classes.landingText}>
                          Drag file here or click to select
                        </Typography>
                      </div>
                    </section>
                  )}
                  {file && (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Typography className={classes.landingText}>
                          {file[0].name}
                        </Typography>
                      </div>
                    </section>
                  )}
                </Box>
              )}
            </Dropzone>
            <Box className={classes.listAndAccordion}>
              <Box className={classes.listContainer}>
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <Box className={classes.dot}></Box>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listItemText}>
                        Must be .CSV File
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <Box className={classes.dot}></Box>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listItemText}>
                        Row 1 must be column headings (Week, Start Date, End Date, etc.)
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <Box className={classes.dot}></Box>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listItemText}>
                        Ads data must start from row 2
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <Box className={classes.dot}></Box>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography className={classes.listItemText}>
                        No blank rows
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
            </Box>
            <Box className={classes.buttonContainer}>
              <CommonButton
                label="Download Template"
                height="100%"
                width={173}
                padding={0}
                onClick={() => generateExcel()}
              />
              {/* <CSVLink filename="example-ad.csv" data={createCSV(template)}>
                <CommonButton
                  label="Download Template"
                  height="100%"
                  width={173}
                  padding={0}
                  onClick={() => {}}
                />
              </CSVLink> */}
              <ActionButton
                label="Upload Ad Grid"
                disabled={!file}
                onClick={() => handleCreateAdGrid()}
              />
            </Box>
          </Box>
        </CardContainer>
      </Dialog>
    </div>
  );
}
