import { Box, Button, makeStyles } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useState } from "react";
import CardContainer from "../../../components/CardContainer";
import CenterHeader from "../../../components/CenterHeader";
import CommonTextField from "../../../components/CommonTextField";
import CommonButton from "../../../components/Buttons/CommonButton";
import AuthSubActions from "../../../components/AuthBottomActions";
import SnackBar from "../../../components/SnackBar"
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Ajax from "../../../Ajax";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

interface LoginFormValues {
  email: string;
  resetCode: string;
  password: string;
  ReEnterPassword: string;
}

export default function ForgotPassword() {
  const classes = useStyles();
  const [mailVerified, SetMailVerified] = useState<boolean>(false);
  const [resetCodeVerified, SetResetCodeVerified] = useState<boolean>(false);
  const [successed, SetSuccessed] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity,setSnackbarSeverity] = useState<string>('');
  const [loading ,SetLoading] = useState<boolean>(false);


  const dispatch = useDispatch();

  const handleSnackBarOpen = (message:any,type:any) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type);
    setSnackbarOpen(true)
    setTimeout(() => setSnackbarOpen(false), 2000)
  }


  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required
      // intl.formatMessage({
      //   id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      // })
      (),

  });

  const initialValues: LoginFormValues = {
    email: "",
    resetCode: "",
    password: "",
    ReEnterPassword: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (
      { email, resetCode, password, ReEnterPassword },
      { setStatus, setSubmitting }
    ) => {
      try {
        if (
          resetCode &&
          mailVerified &&
          resetCodeVerified &&
          password &&
          ReEnterPassword
        ) {
          if (password === ReEnterPassword) {
            formik.resetForm();
            //send new password in backend
            SetLoading(true);
             const result = await Ajax.resetPassword({email,password,resetCode});
             if(result){
               if(result.status==200)
               {
                handleSnackBarOpen("Password reset successful!" , "success");
                setTimeout(() => {
                  SetSuccessed(true);
                 }, 1500); 
               }else{
                handleSnackBarOpen(result.data.message,"error");
               }
             }else{
              handleSnackBarOpen("Something went wrong! Try again.","error");
             }
            SetLoading(false);
          
          } else {
            handleSnackBarOpen("Confirm password doesn't match","error");
          }
        } else if (resetCode && mailVerified) {
          if(resetCodeVerified)
          {
            handleSnackBarOpen('Password fields should not be empty','warning');
            
          } else {
            //match entered code with generated code sand save in result
            SetLoading(true);
             const result = await Ajax.verifyResetCode({email,resetCode});
             SetLoading(false);
             if(result) {
              if(result.status === 200) {
                handleSnackBarOpen('Reset Code verified successfully! Create new password.','success');
                SetResetCodeVerified(true);
              }
              else
                handleSnackBarOpen(result.data.message,"error");
            }
             else
            handleSnackBarOpen("Something went wrong! Try again.","error");
          }}
         
        else if (!resetCode && email) {
          if(mailVerified)
          {
            handleSnackBarOpen('Reset Code field should not be empty','warning');
            return;
          }
          //verfiy the mail and and set mailverified
          SetLoading(true);
          const result = await Ajax.generateResetCode({email});
          SetLoading(false);
          if (result) {
              if (result.status === 200) {
                  SetMailVerified(true)
                  handleSnackBarOpen('Reset code sent successfully on registered email','success');
                }
               else {
                handleSnackBarOpen(result.data.message,'error');
              }
            }
             else {
            handleSnackBarOpen('Something went wrong!','error');
          }
        } else {
          handleSnackBarOpen('Field should not be empty','warning');
        }
      } catch (e:any) {
        const errMessage = e.response?.data?.message || e.message;
        dispatch(setStatus("error"));
        handleSnackBarOpen(errMessage,"error");
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
      {snackbarOpen ? <SnackBar message={snackbarMessage} severity={snackbarSeverity} open={true} /> : ''}
       <Box className={classes.root}>
          <CenterHeader title="Forgot password" />
          <CardContainer width={386} height={mailVerified ? 290 : 200} auth>
            {!resetCodeVerified ? (
              <>
                <CommonTextField
                  placeHolder="Email"
                  name="email"
                  type="email"
                  onChange={mailVerified ? () => {} : formik.handleChange}
                  value={formik.values.email}
                  //  helperText={formik.errors.email}
                   error={!!formik.errors.email && formik.touched.email}
                />
                {mailVerified ? (
                  <CommonTextField
                    placeHolder="Enter Reset Code here"
                    name="resetCode"
                    type="string"
                    onChange={formik.handleChange}
                    value={formik.values.resetCode}
                    // helperText={formik.errors.email}
                     error={!!formik.errors.resetCode && formik.touched.resetCode}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              !loading ? <>
                <CommonTextField
                  placeHolder="Enter New Password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  // helperText={formik.errors.email}
                   error={!!formik.errors.password && formik.touched.password}
                />
                <CommonTextField
                  placeHolder="Re-Enter password"
                  name="ReEnterPassword"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.ReEnterPassword}
                  // helperText={formik.errors.email}
                   error={!!formik.errors.ReEnterPassword && formik.touched.ReEnterPassword}
                />
              </> : ''
            )}
            {loading ?<CircularProgress /> : <CommonButton
              label={mailVerified ? "Submit" : "Send Reset Code"}
              type="submit"
              width={175}
            /> }
          </CardContainer>
          <AuthSubActions backToLogin />
        </Box>
      </form>
      {successed && <Redirect to="/auth/login" />}
    </>
  );
}
