import React, { useEffect, useState } from "react";
import {
  makeStyles,
} from "@material-ui/core/styles";
import {
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import SwitchRow from "../../ADSPanel/AdGridTable/SwitchRow";
import { useAppSelector, useAppDispatch } from "../../../../hooks/redux";
import { deleteMultipleReviews,updateReview } from "../../../../redux/reducers/reviewReducer/thunks";
import ReviewCollapse from "./ReviewCollapse";
import MoreActionMenu, {
  StyledMenuItem,
} from "../../../../components/MoreActionMenu";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 20,
    width: '100%'
  },
  table: {
    // minWidth: 580,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    height: 60,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
  },
  export: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.fontFamily,
    textDecoration: "none",
    padding: "0 4px",
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    textTransform: "none",
    margin: "0 1rem",
    height: 41,
    width: 175,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonText: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.white,
  },
}));

export default function ReviewsTable() {
  const classes = useStyles();
  const { reviews } = useAppSelector((state) => state.reviews);
  const {name} = useAppSelector(state => state.brandPanel.currentMan)
  const [view, setView] = React.useState(true);
  const dispatch = useAppDispatch();
  const [reviewDialog, setReviewDialog] = React.useState(false)

  const [selectedReviews,setSelectedReviews]  = useState([])

  const handleCheckBoxToggle = (reviewId) => {
    console.log({ reviewId });
    if (selectedReviews.includes(reviewId)) {
      setSelectedReviews(selectedReviews.filter((rID) => rID !== reviewId));
    } else {
      setSelectedReviews([...selectedReviews,reviewId]);
    }
  };

  console.log({selectedReviews})


  const handleSwitch = () => {
    setView(!view);
  };

  const handleDeleteAll = () => {
    dispatch(deleteMultipleReviews(reviews.map((review) => review.id)));
  };

  const formatDate = myDate => {
    var d = new Date(myDate)
    var d_month = (d.getMonth()+1) < 10 ? `0${d.getMonth()+1}` : d.getMonth()+1
    var d_date =  (d.getDate()+1) < 10 ? `0${d.getDate()}` : d.getDate()
    return `${d_month}-${d_date}-${d.getFullYear()}`
  }

  const getCsv = () => {
    if(!reviews) return []
    let reviewsCSV = []
    reviewsCSV.push(['SKU', 'Model', 'Description', 'Average Review', 'Weekly Trend','No. of Reviews','New Reviews'])
    const parseLink = (model, url) => {
      return `=HYPERLINK(""${url.replaceAll(/\"/g, "")}"",""${model}"")`
    }
    reviews.map(r => {
      reviewsCSV.push([`Reviews report on: ${formatDate(r.createdAt)}`])
      r.value.map(val => {
        let avgReview = val.available === "Missing" ? 'Product Missing' : (val.averageReview ? val.averageReview : 'No Ratings Yet')
        let trendData = val.available === "Missing" ? '' : (!val.averageReview || !val.lastWeekReview || isNaN(val.averageReview) || isNaN(val.lastWeekReview) ? 'No Trend Data'
                        : (val.averageReview > val.lastWeekReview ? '↑'
                          : (val.averageReview < val.lastWeekReview ? '↓'
                            : '-')))
        reviewsCSV.push([val.sku,
          val.model,      // parseLink(val.model, val.reviewUrl),
          val.description.replaceAll(",", "").replaceAll(/\"/g, ""),
          avgReview,
          trendData,
          val.totalReviews,
          (val.totalReviews && val.lastWeekTotalReview) ? (val.totalReviews - val.lastWeekTotalReview) : '-'
        ])
      })
    })
    return reviewsCSV
  }

  const closeReviewDialog = async() => {
    await setReviewDialog(true)
    await setReviewDialog(false)
  }

  const archiveReviews = () => {
    selectedReviews.forEach((reviewId) => {
      dispatch(updateReview({id:reviewId ,data:{archived: true }}));
    })
  }

  useEffect(() => {
    setSelectedReviews([])
  }, [reviews]);

  console.log({reviews})
  console.log({view})

  return (
    <Box className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <Box className={classes.headerContainer}>
            <SwitchRow
              toggle={view}
              handleSwitch={handleSwitch}
              on="View Current"
              off="View Archived"
            />
            <Box className={classes.buttonContainer}>
             {selectedReviews.length > 0 && (
              <Button
                className={classes.button}
                onClick={archiveReviews}
                // disabled={submitting}
              >
                <Typography
                  className={classes.buttonText}
                >{`Archive ${selectedReviews.length} Reviews`}</Typography>
              </Button>
            )}
            </Box>
            {reviews && reviews.length && (
              <MoreActionMenu className={classes.more} actions={{}} close={reviewDialog}>
                <Box className={classes.moreContainer}>
                  <CSVLink
                    filename={`${name} All Reviews (${formatDate(new Date())}).csv`}
                    className={classes.export}
                    data={getCsv() || []}
                    onClick={() => closeReviewDialog()}
                  >
                    Export all to CSV
                  </CSVLink>
                  <StyledMenuItem
                    style={{ color: "red" }}
                    onClick={() => {
                      handleDeleteAll();
                    }}
                  >
                    Delete all{" "}
                  </StyledMenuItem>
                </Box>
              </MoreActionMenu>
            )}
          </Box>
          {reviews &&
            reviews.filter( review => review.archived !== view).map((row) => (
              <ReviewCollapse
                key={row.id}
                id={row.id}
                reviews={row}
                showCheckBox={view}
                date={moment(row.date).format("MM-DD-YYYY")}
                selected={selectedReviews.includes(row.id)}
                handleCheckBoxToggle={() => {
                  handleCheckBoxToggle(row.id)
                }}
              />
            ))}
        </Table>
      </TableContainer>
    </Box>
  );
}
