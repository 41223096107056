import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CardContainer from "../../components/CardContainer";
import { Exit } from "../../assets";
import BaseDialog from "../../components/BaseDialog";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    // boxSizing: "content-box",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    // alignItems: "center",
    width: 392,
    paddingBottom: 35,
    paddingTop: 20,
  },
  text: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: "1rem",
    padding: "0.4rem",
  },
  buttonContainer: {
    width: 400,
    textAlign: "center"
  },
  cancel: {
    height: 41,
    width: 200,
    borderRadius: 6,
    padding: 8,
    backgroundColor: theme.palette.error.main,
    border: "none",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  buttonText: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: "1rem",
    textAlign: "center",
  },
}));

interface props {
  onClick: (arg0?: boolean) => void;
  open: boolean;
  handleCancellation: any;
}

export default function CancelDialog({ onClick, open, handleCancellation }: props) {
  const classes = useStyles();

  const handleClose = () => {
    onClick(false);
  };

  return (
    <BaseDialog
      title={'Cancel Subscription'}
      open={open}
      close={handleClose}
    >
      <Box className={classes.textContainer}>
        <Typography variant="body1" className={classes.text}>
          Are you sure you want to cancel your Subscription Plan?
        </Typography>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button
          className={classes.cancel}
          variant="outlined"
          onClick={handleCancellation}
        >
          <Typography className={classes.buttonText}>Confirm</Typography>
        </Button>
      </Box>
    </BaseDialog>
  );
}
