import { createStyles, makeStyles, TableCell, Theme, withStyles } from '@material-ui/core';
import React from 'react';
import ArrowDown from '../../../../assets/ArrowDown'
import DownArrow from '../../../../assets/DownArrow';
import UpArrow from '../../../../assets/UpArrow'

const useStyles = makeStyles((theme) => ({
  upArrow: {
    color: theme.palette.success.main
  },
  downArrow: {
    color: theme.palette.error.main
  }
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 600,
      letterSpacing: '5%',
    },
    body: {
      fontSize: theme.typography.pxToRem(14),
    },
  }),
)(TableCell);

interface props {
  trend: string
}

export default function UpDownRow({trend}: props) {
  const classes = useStyles()
  return(
    <StyledTableCell align="center">
      {trend === 'up' ?
        <UpArrow className={classes.upArrow}/>
        : ( trend === 'down' ?
            <DownArrow className={classes.downArrow}/>
          : '-'
        )
      }
    </StyledTableCell>
  )
}