import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import CardContainer from '../../../components/CardContainer';
import { Exit } from '../../../assets';
import BaseDialog from '../../../components/BaseDialog'

const useStyles = makeStyles((theme) => ({
  textContainer: {
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 392,
    height: 92,
    paddingBottom: 32,
    paddingTop: 42

  },
  text: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '1rem'
  },
  buttonContainer: {
    width: 252,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cancel: {
    height: 41,
    width: 104,
    borderRadius: 6,
    padding: 11,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  delete: {
    height: 41,
    width: 104,
    borderRadius: 6,
    padding: 11,
    backgroundColor: theme.palette.error.main,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    }
  },
  buttonText: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: '1rem',
    textAlign: 'center'
  }

}))


interface props {
  onClick: (arg0?: boolean) => void
  open: boolean,
  name: string
}

export default function AlertDialogSlide({onClick, open, name}: props) {
  const classes = useStyles()

  const handleClose = () => {
    onClick(false)
  };


  return (
    <BaseDialog title={`Delete ${name ? name : 'Brand'}?`} open={open} close={handleClose}>
      <Box className={classes.textContainer}>
        <Typography variant="body1" className={classes.text}>
          Deleting this brand will delete all the assoicated Products, Ad Grids, Reviews and Notifications. 
        </Typography>
        <Typography variant="body1" className={classes.text}>
          This action cannot be undone.
        </Typography>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button className={classes.cancel} variant="outlined">
          <Typography onClick={handleClose} className={classes.buttonText}>Cancel</Typography>
        </Button>
        <Button className={classes.delete} variant="outlined" onClick={() => onClick(true)}>
          <Typography className={classes.buttonText}>Delete</Typography>
        </Button>
      </Box>        
    </BaseDialog>
  );
}