import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Ajax from "../../../Ajax";
import typehole from "typehole";

// inviteCode
// 56wnpV


export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async (id: number) => {
    const currentMan = await Ajax.getManufacturer(id)

    return currentMan.notifications
  }
)

export const updatedNotifications = createAsyncThunk(
  "notifications/updatedNotifications",
  async (data: any) => {
    await Ajax.updateManufacturer(data.id, data.data);
    const response =  await Ajax.getManufacturer(data.id);
    return response.notifications;
  }
);