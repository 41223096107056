import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { currentMan } from './thunks'

// Define a type for the slice state
interface BrandPanelState {
  currentMan: any | undefined;
  tab: number;
  currentBrandId: number | undefined;
}

// Define the initial state using that type
const initialState: BrandPanelState = {
  currentMan: undefined,
  tab: 0,
  currentBrandId: undefined,
};


export const brandPanelSlice = createSlice({
  name: "brand panel",
  initialState,
  reducers: {
    changeTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setCurrentBrandId: (state, action: PayloadAction<number>) => {
      state.currentBrandId = action.payload;
    },

  },
  extraReducers: {
    [currentMan.fulfilled.toString()]: (state, action) => {
      state.currentMan = action.payload
    },
  },
});

export const { changeTab, setCurrentBrandId } =
  brandPanelSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.ui.value;

export default brandPanelSlice.reducer;
