import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    height: 84
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  lightText: {
    color: theme.palette.common.white,
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
  },
  link: {
    textTransform: 'none',
    textDecoration: 'none',
  },
  linkSpace: {
    paddingLeft: 6
  },
  boldText: {
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 18,
    textAlign: 'center',
    // display: 'contents'
  },
  linkContact: {
    color: 'white',
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'arial'
   }
}))

interface props {
  login?: boolean; 
  forgotPassword?: boolean; 
  backToLogin?: boolean;
  signUp?: boolean;
}

interface actionProps {
  label?: string;
  path: string
  space?: boolean
}

const ActionLink = ({label = 'Log in', path, space}: actionProps) => {
  const classes = useStyles()

  return (
    <Link to={path} className={classes.link}>
      <Typography className={`${classes.boldText} ${space && classes.linkSpace}`}>
        {label}
      </Typography> 
    </Link>
  )
}

export default function AuthSubActions({
  login, 
  backToLogin,
  signUp
  }: props){
  const classes = useStyles()

  return (
    <>
    {signUp && (
      <Box className={`${classes.root} ${classes.column}`}>
        <Typography className={classes.lightText}>
          Already have an account?
          <ActionLink path="/"/>
        </Typography>
      </Box>
    )}
     {login && (
      <Box className={`${classes.root} ${classes.column}`}>
        <Typography className={classes.lightText}>
          Need an account?
          <ActionLink label="Sign up" path="/auth/registration"/>
        </Typography>
      </Box>
    )}
     {backToLogin && (
      <Box className={`${classes.root} ${classes.row}`}>
        <Typography className={classes.lightText}>
          Back to 
        </Typography>
        <ActionLink path="/" space/>
      </Box>
    )}
    <div className={classes.linkContact}>
      <a rel="noopener noreferrer" href={`mailto:david.rhodes@turnsku.com`} style={{textDecoration: 'none', fontWeight:'600' }}>
            Contact us
          </a>

        </div>
    </>
  )
}