import { configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage'
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1'
import appReducer from '../reducers'
import { RootStateOrAny } from 'react-redux';

const persistConfig = {
  key: 'root',
  storage: storage,
  // stateReconciler: autoMergeLevel1,
};

const _persistedReducer = persistReducer<any, any>(persistConfig, appReducer);

export const store = configureStore({
  reducer: appReducer,
  // middleware: getDefaultMiddleware({
  //   serializableCheck: {
  //     /* ignore persistance actions */
  //     ignoredActions: [
  //       FLUSH,
  //       REHYDRATE,
  //       PAUSE,
  //       PERSIST,
  //       PURGE,
  //       REGISTER
  //     ],
  //   },
  // })
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;