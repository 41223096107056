import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import BaseDialog from '../../../../components/BaseDialog'
import CommonButton from '../../../../components/Buttons/CommonButton';
import CommonTextField from '../../../../components/CommonTextField';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { toggleEmailDialog } from '../../../../redux/reducers/notificationsReducer'
import ActionButton from '../../../../components/Buttons/ActionButton'
import { NotificationsContext } from '..';


const useStyles = makeStyles((theme) => ({
  textFieldContainer: {
    paddingTop: 24
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: 20
  },
  bottomButtonContainer: {
    paddingTop: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }

}))
export default function EmailDialog() {
  const { addEmail } = React.useContext(NotificationsContext)
  const [ emails, setEmails ] = React.useState([{email: '',  inventory: {}, reports: {}, ads:{}, reviews: {}, fromAddress: 'reports@turnsku.com'}])
  const { emailDialogToggled } = useAppSelector(state => state.notifications)
  const dispatch = useAppDispatch()
  const classes = useStyles()

  const handleAddEmail = () => {
    setEmails([...emails, {email: '',  inventory: {}, reports: {}, ads:{}, reviews: {}, fromAddress: 'reports@turnsku.com'}])
  }


  const handleDialogToggle = () => {
    dispatch(toggleEmailDialog(false))
  }

  const handleChange = (val, index) => {

    const updatedEmailList = emails.map((emailNotification, i) => {
    
      if(index === i) {
        return {
          ...emailNotification,
          email: val,
         
        }
      }
      return emailNotification
    })
    setEmails(updatedEmailList)
  }

  const handleUploadEmails = () => {
    addEmail(emails)
    setEmails([{email: '', inventory: {}, reports: {}, ads:{}, reviews: {}, fromAddress: 'reports@turnsku.com'}])
    dispatch(toggleEmailDialog(false))
  }

  return (
    <BaseDialog
      title="Add New Email"
      open={emailDialogToggled}
      close={handleDialogToggle}
    >
      {emails.map(({email}, i) => (
        <Box className={classes.textFieldContainer}>
          <CommonTextField width={586} onChange={(e) => handleChange(e.target.value, i)} value={email}/>
        </Box>
      ))}
      <Box className={classes.buttonContainer}>
        <CommonButton label="Add 1 More" width={162} onClick={handleAddEmail} />
      </Box>      
      <Box className={classes.bottomButtonContainer}>
        <CommonButton label="Add emails" onClick={handleUploadEmails} light/>
      </Box>   
    </BaseDialog>
  )
}