import { AppBar, Toolbar, makeStyles, Typography, SvgIcon, IconButton } from '@material-ui/core';
import React from 'react';
import { Person } from '../../assets';
import turnSkuLogo from '../../assets/turnSkuLogo.png';
import AccountMenu from './AccountMenu'
import AccountDialog from './AccountDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    height: 64,
    marginBottom: 6
  },
  container: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // margin: '0 2rem'
  },
  logoButton: {
    // position: 'relative',
    // left: 30,
    // height: 64
  },
  logo: {
    position: 'relative',
    height: 42,
    left: 30,
  },
  account: {
    position: 'relative',
    // height: 42,
    right: 16
  },
  accountIcon: {
    fontSize: 40,
    color: theme.palette.common.white
  }
}))

export default function TopNav() {
  const classes = useStyles()

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar variant="dense" disableGutters className={classes.container}>
        <IconButton edge="start"className={classes.logoButton}>
          <img src={turnSkuLogo} alt="turnSku logo" className={classes.logo}/>
        </IconButton>
        <AccountMenu />
      </Toolbar>
      <AccountDialog />
    </AppBar>
  )
}