import theme from './theme/theme'
import { ThemeProvider, makeStyles } from '@material-ui/core'
import DefaultView from './views/DefaultView'
import React from 'react'
import TopNav from './components/TopNav'
import Router from './Router'
import {BrowserRouter as ReactRouter} from 'react-router-dom'
import Auth from './pages/Auth'
import { useAppDispatch } from './hooks/redux'
import Ajax from './Ajax'
import { logIn } from './redux/reducers/authReducer'
import BottomFooter from './components/BottomFooter'

const useStyles = makeStyles((theme) => ({
  root:{
    backgroundColor: theme.palette.background.default
  }
}))

function App() {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    const token = localStorage.getItem('turn-sku-token');

    const getCurrentUser = async () => {
      const user = await Ajax.getCurrentUser();

      dispatch(logIn(user));
    }

    if (token) {
      getCurrentUser();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ReactRouter>
        <TopNav />
        <DefaultView>
          <Auth/>
        </DefaultView>
        <BottomFooter />
      </ReactRouter>
    </ThemeProvider>
  );
}

export default App;
