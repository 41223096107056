import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Box, Button, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { useAppDispatch } from '../../hooks/redux';
import CardContainer from '../CardContainer';
import { Exit } from '../../assets';




const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 24
  },
  headingContainer: {
    boxSizing: 'content-box',
    width: '100%',
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  header: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(24),
    color: '#1E1E24',
    
  },
  icon: {
    padding: 0,
    color: theme.palette.primary.main
  },
}))

interface props {
  title?: string,
  open: boolean, 
  close?: any,
  children: React.ReactNode
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({title, open, close, children}: props) {
  const dispatch = useAppDispatch()
  const classes = useStyles()



   return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={close} 
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <CardContainer width={634}>
          <Box className={classes.root}>
            <Box className={classes.headingContainer}>
              <Typography className={classes.header} variant="h1">
                {title}
              </Typography>
              <IconButton className={classes.icon} onClick={close}>
                <Exit />
              </IconButton>
            </Box>
            {children}
          </Box>
        </CardContainer>
      </Dialog>
    </div>
  );
}