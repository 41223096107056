import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { manProducts, getProductList, deleteReport, updateProductList, getReports, getCurrentProductId, archiveReports, createReports, createProductList, createProductListThunk} from './thunks'

// Define a type for the slice state
interface productsState {
  productListDialogToggled: boolean
  addProductDialogToggled: boolean
  productDialogWarningToggled: boolean
  currentListId: number | undefined
  tab: number,
  productsList: undefined | any
  products: undefined | any
  reports: undefined | any
  manProducts:  undefined | any
}

// Define the initial state using that type
const initialState: productsState = {
  productListDialogToggled: false,
  addProductDialogToggled: false,
  productDialogWarningToggled: false,
  currentListId: undefined,
  products: undefined,
  manProducts: undefined,
  reports: undefined,
  productsList: {
    list: []
  },
  tab: 0
}

export const productsSlice = createSlice({
  name: 'products',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentListId: (state, action: PayloadAction<number>) => {
      state.currentListId = action.payload
    },
    toggleListDialog: (state) => {
      state.productListDialogToggled = !state.productListDialogToggled
    },
    toggleAddProductDialog: (state) => {
      state.addProductDialogToggled = !state.addProductDialogToggled
    },
    toggleAddProductWarningDialog: (state) => {
      state.productDialogWarningToggled = !state.productDialogWarningToggled
    },
    changeTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload
    },
    updateReports: (state, action: PayloadAction<any>) => {
      state.reports = action.payload
    }
  },
  extraReducers: {
    [manProducts.fulfilled.toString()]: (state, action) => {
      state.manProducts = action.payload
    },
    [getCurrentProductId.fulfilled.toString()]: (state, action) => {
      state.currentListId = action.payload
    },
    [updateProductList.fulfilled.toString()]: (state, action) => {
      state.productsList = action.payload
    },
    [getProductList.fulfilled.toString()]: (state, action) => {
      state.productsList = action.payload
    },
    [getReports.fulfilled.toString()]: (state, action) => {
      state.reports = action.payload
    },
    [archiveReports.fulfilled.toString()]: (state, action) => {
      state.reports = action.payload
    },
    [createReports.fulfilled.toString()]: (state, action) => {
      if (!state.reports) {
        state.reports = [action.payload]
      } else {
        state.reports = [...state.reports,action.payload].sort((a, b) => {
          return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
        });
      }
    },
    [createProductListThunk.fulfilled.toString()]: (state, action) => {
      state.productsList = action.payload
    }
  }
})

export const { toggleListDialog, updateReports, toggleAddProductDialog, changeTab, toggleAddProductWarningDialog, setCurrentListId } = productsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.products

export default productsSlice.reducer