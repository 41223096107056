import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CardContainer from '../../components/CardContainer';
import CenterHeader from '../../components/CenterHeader';
import CommonTextField from '../../components/CommonTextField';
import CommonButton from '../../components/Buttons/CommonButton'
import AuthBottomActions from '../../components/AuthBottomActions'
import {Formik, useFormik} from 'formik'
import * as Yup from 'yup';
import Ajax from '../../Ajax'
import { logIn } from '../../redux/reducers/authReducer'
import { useAppDispatch } from '../../hooks/redux'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  signUpButton: {
    
  },
  policy: {
    textAlign: 'center',
    padding: '0rem 2rem',
  }
}))

 

interface LoginFormValues {
  email: string,
  password: string
  passwordValidate: string,
  inviteCode: string
}



export default function SignUp() {
  const [loading, setLoading] = React.useState(false)
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
      ),
    passwordValidate: Yup.string()
    .required()
    .when("password", {
      is: (val:string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
  });

  const initialValues: LoginFormValues = {
    email: '',
    password: '',
    passwordValidate: '',
    inviteCode: ''
  }
  

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async ({email, password}, { setStatus, setSubmitting }) => {
      try {
        
      setLoading(true)

      const res = await Ajax.createUser({email, password})

      if (res.user) {
        dispatch(logIn({ email: res.user.email, id: res.user.id }));

        const userResponse = await Ajax.login({ email, password });
        if (userResponse.token) {
          localStorage.setItem('turn-sku-token', userResponse.token);
        }

      }

      setLoading(false);
    } catch(error: any) {
      const errMessage = error.response?.data?.message || error.message;
      setLoading(false);
      setStatus(error.message)
      window.alert(errMessage);
    }
  }})


  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={classes.root}>
        <CenterHeader title="Create an account" />
        <CardContainer width={386} height={444} auth>
          <CommonTextField 
            placeHolder="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            // helperText={formik.errors.email} 
            // error={!!formik.errors.email && formik.touched.email}
          />
          <CommonTextField 
            placeHolder="Password"
            name="password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            // helperText={formik.errors.password} 
            // error={!!formik.errors.password && formik.touched.password}
          />
          <CommonTextField 
            placeHolder="Confirm Password"
            name="passwordValidate"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.passwordValidate}
            // helperText={formik.errors.passwordValidate} 
            // error={!!formik.errors.passwordValidate && formik.touched.passwordValidate}
          />
          {/* <CommonTextField 
            placeHolder="Invite Code"
            name="inviteCode"
            onChange={formik.handleChange}
            value={formik.values.inviteCode}
            // helperText={formik.errors.inviteCode} 
            // error={!!formik.errors.inviteCode && formik.touched.inviteCode}
          /> */}
          <Typography className={classes.policy}>By signing up you agree to accept our <a target="_blank" rel='noreferrer' href="/privacyPolicy.html" > Privacy Policy</a> and <a href='/termsAndConditions.html' target="_blank">T&C</a>.</Typography>
          <CommonButton label="Sign up" type="submit"/>
        </CardContainer>
        <AuthBottomActions signUp/>
      </Box>
    </form>
  )
}