import * as React from "react";

function SvgUpArrow(props) {
  return (
    <svg
      width={12}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.998 0a1.02 1.02 0 00-.618.239L.28 4.945c-.293.319-.414.93-.076 1.287.333.35.979.34 1.313-.01l3.581-3.308v16.204c0 .487.403.882.9.882s.9-.395.9-.882V2.914l3.582 3.309c.307.3.976.356 1.313.009.336-.348.229-.981-.076-1.287L6.617.239A.875.875 0 005.998 0z"
        fill="#87B750"
      />
    </svg>
  );
}

export default SvgUpArrow;
