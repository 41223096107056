import React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MoreActionMenu from '../../components/MoreActionMenu';
import SnackBar from '../../components/SnackBar'
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { deleteInviteCode } from '../../redux/reducers/adminReducer/thunks'
import { AdminContext } from '.';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 600,
      letterSpacing: '5%',
    },
    body: {
      fontSize: theme.typography.pxToRem(14),
    
    
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 580,
    backgroundColor: theme.palette.common.white
  },
}));


export default function CustomizedTables({inviteCodes}) {
  const [copied, setCopied] = React.useState(false)
  const { handleSnackBarOpen, deleteCode } = React.useContext(AdminContext)
  const dispatch = useAppDispatch()
  const classes = useStyles();

  const handleDeleteCode = (code: string) => {
    deleteCode(code)
  }

  const handleCopyCode = (code: string) => {
    navigator.clipboard.writeText(code)
    handleSnackBarOpen("Copied to Clipboard.")
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>active invite codes</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inviteCodes.map((invite: any) => (
            <StyledTableRow key={invite}>
              <StyledTableCell component="th" scope="row">
                {invite}
              </StyledTableCell>
              <StyledTableCell align="right">
                <MoreActionMenu 
                  actions={{
                    copy: () => {handleCopyCode(invite)},
                    delete: () => {handleDeleteCode(invite)}
                  }}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}