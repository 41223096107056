import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import ActionButton from '../../../components/Buttons/ActionButton'
import CenterContentContainer from '../../../components/CenterContentContainer';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import EmailDialog from './EmailDialog';
import NotificationsTable from './NotificationsTable'
import { toggleEmailDialog } from '../../../redux/reducers/notificationsReducer'
import { updatedNotifications } from '../../../redux/reducers/notificationsReducer/thunks';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  icon: {
    color: theme.palette.common.white,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '20px 0'
  },
}))


export const NotificationsContext = React.createContext({})

export default function NotificationsPanel() {
  const classes = useStyles()
  const { currentBrandId } = useAppSelector(state => state.brandPanel)
  const { notifications } = useAppSelector(state => state.notifications)
  const dispatch = useAppDispatch()


  const updateManufacturer = React.useCallback((update) => {
    // let parsedUpdate = update.map(({email, fromAddress, ads, reports, reviews}) => ({email, fromAddress, ads, reports, reviews}))

    dispatch(updatedNotifications({id: currentBrandId, data:{ notifications: update }}))
  },[currentBrandId, dispatch])

  const handleDelete = (e) => {
    const filteredList = notifications.filter(n => n.email !== e);
    updateManufacturer(filteredList);
  }

  const addEmail = (_newEmails) => {
    const accountNotifications = !Array.isArray(notifications) ? Object.keys(notifications).map(key => notifications[key]) : notifications
    updateManufacturer([...accountNotifications, ..._newEmails]);
  }

  const handleChange = React.useCallback((edits, i) => {

    const update = notifications.map((row, j) => {
      return j === i 
        ? 
          {
            ...row,
            ...edits
          }
        : 
          row 
    });

    updateManufacturer(update);
  },[notifications, updateManufacturer])

  return ( 
    <NotificationsContext.Provider value={{
      handleChange,
      addEmail,
      handleDelete,
      notifications
    }}>
      <Box className={classes.root}>
        <Box className={classes.buttonContainer}>
          <ActionButton label="Add Emails" width={150} onClick={() => dispatch(toggleEmailDialog())}/>
        </Box>
        <CenterContentContainer label="Add emails to get started">
          <NotificationsTable />
        </CenterContentContainer>
        <EmailDialog />
      </Box>
    </NotificationsContext.Provider>
  )
}