import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import CommonTextField from '../../../../components/CommonTextField';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: 24
  }
}))

interface props {
  data: any
  onChange: (arg0: string, arg1: string | number, arg2: number) => void
  index: number
}

export default function ProductRow({data, onChange, index}: props) {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <CommonTextField
      label="sku"
      onChange={(e) => onChange("sku", e.target.value, index)} 
      value={data.sku}
      width={190}/>
      <CommonTextField 
      label="model"
      onChange={(e) => onChange("model", e.target.value, index)} 
      value={data.model}
      width={190}/>
      <CommonTextField 
      onChange={(e) => onChange("map", e.target.value, index)} 
      label="map"
      value={data.map}
      width={190}/>
    </Box>
  )
}