import * as React from "react";

function SvgArrow(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#arrow_svg__clip0)">
        <path
          d="M0 10.002c.008.214.095.462.239.618l4.706 5.1c.319.293.93.414 1.287.076.35-.333.34-.979-.01-1.313l-3.308-3.581h16.204a.891.891 0 00.882-.9c0-.498-.395-.9-.882-.9H2.914L6.223 5.52c.3-.308.356-.976.009-1.312-.348-.337-.981-.23-1.287.075l-4.706 5.1a.875.875 0 00-.239.619z"
          fill={props.color}
        />
      </g>
      <defs>
        <clipPath id="arrow_svg__clip0">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgArrow;
