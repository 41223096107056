import { Box, Button, makeStyles } from '@material-ui/core';
import React from 'react';
import CardContainer from '../../components/CardContainer';
import CenterHeader from '../../components/CenterHeader';
import CommonTextField from '../../components/CommonTextField';
import CommonButton from '../../components/Buttons/CommonButton'
import AuthSubActions from '../../components/AuthBottomActions'
import {Formik, useFormik} from 'formik'
import * as Yup from 'yup';
import Ajax from '../../Ajax'
import { useAppDispatch } from '../../hooks/redux';
import { logIn, setStatus } from '../../redux/reducers/authReducer'
import Alert from './Alert'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  signUpButton: {
    
  }
}))

 
interface LoginFormValues {
  email: string,
  password: string

}

export default function Login() {
  const [loading, setLoading] = React.useState(false)
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const [statusMessage, setStatusMessage] = React.useState('')
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
      ),
  });

  const initialValues: LoginFormValues = {
    email: '',
    password: '',
  }
  

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async ({ email, password }) => {
      try {
        setLoading(true);
        const res = await Ajax.login({ email, password });
  
        if (res && res.token) {
          localStorage.setItem('turn-sku-token', res.token);
          dispatch(logIn(res.user));
        } 
  
        setLoading(false);
      } catch (e) {
        const errMessage = e.response?.data?.message || e.message;
        dispatch(setStatus("error"))
        setLoading(false);
        setStatusMessage(errMessage)
        window.alert(errMessage);
      }
    },
  });


  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={classes.root}>
        <CenterHeader title="Simplifying Brand Management for Best Buy Suppliers" />
        <CardContainer width={386} height={290} auth>
        <CommonTextField 
          placeHolder="Email"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          // helperText={formik.errors.email} 
          // error={formik.touched.email && !!formik.errors.email }
          
        />
        <CommonTextField 
          placeHolder="Password"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          // helperText={formik.errors.password} 
          // error={!!formik.errors.password && formik.touched.password}
          forgotPassword
          type="password"
        />
        <CommonButton label="Log in" type="submit"/>
        </CardContainer>
        <AuthSubActions login/>
      </Box>
      {/* <Alert message={statusMessage}/> */}
    </form>
  )
}