import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import ActionButton from "../../../components/Buttons/ActionButton";
import CenterContentContainer from "../../../components/CenterContentContainer";
import ProductListDialog from "./ProductListDialog";
import AddProductsDialog from "./AddProductsDialog";
import ProductTable from "./ProductTable";
import ReportsTable from "./ReportsTable";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import ProductListDialogWarning from "./ProductListDialogWarning";
import Ajax from "../../../Ajax";
import {
  toggleAddProductDialog,
  toggleListDialog,
} from "../../../redux/reducers/productsReducer";
import {
  getProductList,
  manProducts,
  updateProductList,
  createProductListThunk,
} from "../../../redux/reducers/productsReducer/thunks";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  icon: {
    color: theme.palette.common.white,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: "20px 0",
  },
}));

export const ProductListContext = React.createContext({});

export default function ProductList() {
  const { tab } = useAppSelector((state) => state.products);
  const dispatch = useAppDispatch();
  const { currentBrandId, currentMan } = useAppSelector(
    (state) => state.brandPanel
  );
  const { currentListId } = useAppSelector((state) => state.products);
  const classes = useStyles();
  const [newProd, setNewProd] = React.useState("");
  const [newProdData, setNewProdData] = React.useState([]);
  const { productsList } = useAppSelector((state) => state.products);
  const params = useParams();

  const readFile = async (files, multipleBrandsChecked) => {
    return new Promise((resolve, reject) => {
      if (!("TextDecoder" in window)) {
        reject(
          "This browser does not support decoding txt files. Please try the most recent version of Chrome, Firefox or Safari."
        );
        return;
      }

      if (!files) {
        setNewProdData([]);
        reject("No file found.");
        return;
      }

      const file = files[0];
      if (!file.path.includes("csv")) {
        reject("File must use a .csv extension");
        return;
      }

      const reader = new FileReader();

      reader.onerror = () => {
        reader.abort();
        reject("Error.");
        return;
      };

      reader.onload = async () => {
        const binaryStr = reader.result;

        const enc = new TextDecoder("utf-8");
        const raw = enc.decode(binaryStr);

        if (raw) {
          let arr = raw.trim().split("\n");
          arr = arr.map((pair) => pair.trim()).filter((pair) => pair);

          // if comma(,) in values
          arr = arr.map((row) => {
            while (row.indexOf('"') !== -1) {
              var start = row.indexOf('"');
              var end = row.indexOf('"', start + 1);
              var sub = row.substring(start, end + 1);
              var subNew = sub.replaceAll(",", "").replaceAll('"', "");
              row = row.replace(sub, subNew);
            }
            return row;
          });

          arr = arr.map((pair) => {
            var rowData = pair.split(",");
            if (rowData.length !== 3) {
              reject(
                "File format error - Number of provided columns does not match required columns."
              );
            }
            var [sku, model, map] = rowData;
            if (sku === "" && model === "" && map === "") {
              reject("Blank row detected.");
            }
            let skuCheck =
              sku && sku.match(/[\d]+/g)
                ? parseInt(sku.match(/[\d]+/g)[0])
                : null;
            let mapCheck =
              map &&
              parseFloat(
                map
                  .trim()
                  .replace(/\$/g, "")
                  .replaceAll('"', "")
                  .replaceAll(",", "")
              )
                ? parseFloat(
                    map
                      .trim()
                      .replace(/\$/g, "")
                      .replaceAll('"', "")
                      .replaceAll(",", "")
                  )
                : null;
            if (!skuCheck || !mapCheck) {
              reject(
                "File format error - SKU/MAP values not in correct format."
              );
            }
            return {
              sku: skuCheck,
              model: model.trim().replaceAll('"', "").replaceAll(",", ""),
              map: mapCheck,
            };
          });

          // Check if product list contains duplicate skus
          const skuMap = {};
          let duplicate = [];
          arr.forEach((prod) => {
            if (skuMap[prod.sku]) {
              duplicate.push(prod.sku);
            } else {
              skuMap[prod.sku] = true;
            }
          });
          if (duplicate.length) {
            reject(`Duplicate SKUs detected: ${duplicate.join(", ")}.`);
            return;
          }

          // Check if product list contains multiple brands
          if (multipleBrandsChecked == false) {
            const skus = arr.map((prod) => prod.sku).join(",");
            const productBrands = await Ajax.getProductBrands(skus);
            if (productBrands && productBrands.length > 1) {
              reject(`Multiple brands detected: ${productBrands.join(", ")}. Please check box "Product list contains multiple-brand SKUs" and re-upload`);
              return;
            }
          }
          setNewProdData(arr);
          resolve(true);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const createProductList = async (name, multipleBrandsChecked) => {
    if (!name) {
      window.alert("Please enter a name for the product list");
      return;
    }

    try {
      dispatch(
        createProductListThunk({
          data: { name, list: newProdData, multipleBrandsChecked },
          id: currentMan.id,
        })
      );

      dispatch(toggleListDialog());
    } catch (e) {
      window.alert("please try again");
    }
  };

  const handleDelete = async () => {
    const confirm = window.confirm(`Delete ${productsList.name}?`);

    if (!confirm) return;

    await Ajax.deleteProductList(productsList.id);

    const manProducts = await Ajax.getManufacturersProducts(currentMan.id);

    dispatch(updateProductList({ id: currentMan.id, manProducts }));
  };

  return (
    <ProductListContext.Provider
      value={{
        handleDelete,
        createProductList,
        readFile,
        newProd,
        newProdData,
        productsList,
      }}
    >
      <Box className={classes.root}>
        <Box className={classes.buttonContainer}>
          <ActionButton
            label="Upload Product List"
            onClick={() => dispatch(toggleListDialog())}
          />
        </Box>
        <CenterContentContainer label="Upload a product list to get started">
          {tab === 0 ? <ProductTable /> : <ReportsTable />}
        </CenterContentContainer>
      </Box>
      <ProductListDialog />
    </ProductListContext.Provider>
  );
}
