import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root:{
    width: 162,
    height: 41,
    borderRadius: 6,
    border: 'none',
  },
  btnActive: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  btnDisabled: {
    backgroundColor: theme.palette.secondary.light
  },
  text: {
    fontWeight: 600,
    fontSize: '1rem',
    textAlign: 'center',
    color: theme.palette.common.white
  },
  cancelButton: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.black}`,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    }
  },
  cancelText: {
    color: theme.palette.common.black,
  }
}))

interface props {
  label: string,
  cancel?: boolean
  onClick: (arg0:string) => void
  isDisabled?: boolean
}

export default function BrandButton({label, cancel, onClick, isDisabled=false}: props){
  const classes = useStyles()
  if (label === "products") {
    return (
      <Button className={`${classes.root} ${isDisabled ? classes.btnDisabled : classes.btnActive} ${cancel && classes.cancelButton}`} onClick={() => onClick(label)} disabled={isDisabled}>
        <Typography className={`${classes.text} ${cancel && classes.cancelText}`} variant="body1">
          {label}
        </Typography>
      </Button>
    )
  }
  return (
    <Button className={`${classes.root} ${isDisabled ? classes.btnDisabled : classes.btnActive} ${cancel && classes.cancelButton}`} onClick={() => onClick(label)} disabled={isDisabled}>
      <Typography className={`${classes.text} ${cancel && classes.cancelText}`} variant="body1">
        {label}
      </Typography>
    </Button>
  )
}