import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import Ajax from '../../../Ajax';
import ActionButton from '../../../components/Buttons/ActionButton'
import CenterContentContainer from '../../../components/CenterContentContainer';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import AdGridDialog from './AdGridDialog';
import AdGridTable from './AdGridTable';
import {toggleDialog, updateAds} from '../../../redux/reducers/adsGridReducer'
import {createAdsGrid, getAds, updateAdsGrid, deleteAdsGrids} from '../../../redux/reducers/adsGridReducer/thunks'
import moment from 'moment'
import Spinner from '../../../components/Spinner'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  icon: {
    color: theme.palette.common.white,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '20px 0'
  },
}))



export const AdGridContext = React.createContext({})

export default function ADSPanel() {
  const classes = useStyles()
  const {currentBrandId, currentMan} = useAppSelector(state => state.brandPanel)
  const { ads, loading } = useAppSelector(state => state.adsGrid)
  const [currentManufacturer, setCurrentMan] = React.useState(null);
  const [newAdData, setNewAdData] = React.useState([])
  const [filename, setFileName] = React.useState('');
  const dispatch = useAppDispatch()

  React.useEffect(() => { 
    if(ads) return
    getAds(currentBrandId)
  },[ads, currentBrandId])


  const readFile = React.useCallback(async (files) => {
    return new Promise((resolve, reject) => {
      if (!("TextDecoder" in window)) {
        reject("This browser does not support decoding txt files. Please try the most recent version of Chrome, Firefox or Safari.")
        return
      }

      if (!files) {
        setNewAdData([]);
        reject("No file found.");
        return
      }

      const file = files[0];
      if (!file.path.includes('csv')) {
        reject("File must use a .csv extension");
        return
      }

      setFileName(file.name)

      const reader = new FileReader()

      reader.onerror = () => {
        reader.abort()
        reject("Error.")
        return
      }
      reader.onload = () => {
        const binaryStr = reader.result;
  
        const enc = new TextDecoder("utf-8");
        const raw = enc.decode(binaryStr);
  
        if (raw) {
          let arr = raw.trim().split('\n');
          arr.shift(); // Remove header row of CSV
          arr = arr.filter(row => Boolean(row));
  
          // if comma(,) in values
          arr = arr.map(row => {
            while (row.indexOf('"') !== -1) {
              var start = row.indexOf('"')
              var end = row.indexOf('"', start + 1)
              var sub = row.substring(start, end + 1)
              var subNew = sub.replaceAll(',', '').replaceAll('"', '')
              row = row.replace(sub, subNew)
            }
            return row
          })

          arr = arr.map(row => {
            const rowData = row.split(',').map(val => val ? val.trim().replace(/\$/g, '').replace(/\%/g, '').replaceAll('"', '').replaceAll(',', '') : '');
            if (rowData.length !== 16) {
              reject("File format error - Number of provided columns does not match required columns.")
            }
            var [week, startDate, endDate, model, sku, stc, notes, msrp, savings, promo, gross, adjustedCost, regGMPercent, adjustedGMPercent, regGM, adjustedGM] = rowData
            if (week === '' && startDate === '' && endDate === '' && model === '' && sku === '' && stc === '' && notes === '' && msrp === '' && savings === '' && promo === '' && gross === '' && adjustedCost === '' && regGMPercent === '' && adjustedGMPercent === '' && regGM === '' && adjustedGM === '') {
              reject("Blank row detected.")
            }
            const parseNum = (num) => {
              if (!num) return null;
              return !isNaN(num) && num.match(/[\d.]+/)[0] ? parseFloat(num.match(/[\d.]+/)[0]) : null;
            }
            const cols = [sku, stc, msrp, savings, promo, gross, adjustedCost, regGMPercent, adjustedGMPercent, regGM, adjustedGM]
            cols.forEach(c => {
              if (c !== '' && parseNum(c) !== 0 && !parseNum(c)) {
                reject(`Values error - '${c}' is non-numeric.`)
              }
            })

            // formatting date for consistency
            var sd = new Date(startDate)
            var sDate = `${sd.getMonth() + 1}/${sd.getDate()}/${sd.getFullYear()}`
            var ed = new Date(endDate)
            var eDate = `${ed.getMonth() + 1}/${ed.getDate()}/${ed.getFullYear()}`

            return {
              week,
              startDate: sDate,
              endDate: eDate,
              model,
              sku: parseNum(sku),
              stc: parseNum(stc),
              notes: notes,
              msrp: parseNum(msrp),
              savings: parseNum(savings),
              promo: parseNum(promo),
              gross: parseNum(gross),
              adjustedCost: parseNum(adjustedCost),
              regGMPercent: parseNum(regGMPercent),
              adjustedGMPercent: parseNum(adjustedGMPercent),
              regGM: parseNum(regGM),
              adjustedGM: parseNum(adjustedGM)
            }
          });
  
          setNewAdData(arr);
          resolve(true)
        }
      };
  
      reader.readAsArrayBuffer(file);
    })
  })

  const createAdGrid = React.useCallback(async () => {
    const value = {
      filename,
      current: {
        dateCreated: moment(),
        values: newAdData
      }
    }
    dispatch(createAdsGrid({id:currentBrandId, updates:{ name: `${currentMan.name} Ad Grid`, value }}))
  },[filename, dispatch, currentBrandId, currentMan?.name, newAdData])

  

  const createCSV = React.useCallback((report) => {
    if (!report) return [];

    return report.map(row => {
      return {
        Week: row.week,
        'Start Date': row.startDate,
        'End Date': row.endDate,
        Model: row.model,
        'BBY SKU': row.sku,
        'STC (Sell Through Credit)': row.stc,
        Notes: row.notes,
        MSRP: row.msrp,
        'Suggested Savings': row.savings,
        'Suggested Promo': row.promo,
        'Gross Cost': row.gross,
        'Adjusted Cost': row.adjustedCost,
        'Reg % GM': row.regGMPercent,
        'Adjusted % GM': row.adjustedGMPercent,
        'Reg GM': row.regGM,
        'Adjusted GM': row.adjustedGM
      }
    });
  },[])


  const handleDelete = React.useCallback(async (id) => {
    try {
      dispatch(deleteAdsGrids(id));
    } catch (e) {
      window.alert(e.message);
    }
  },[dispatch])

  const handleUpdateRow = React.useCallback(async (row, viewType, newValues, gridIndex, id) => {
    if(viewType === undefined) return
    if(Object.keys(newValues).length === 0 && newValues.constructor === Object) return
    try {
      const update = JSON.parse(JSON.stringify(ads));
      const ad = update.filter(a => a.id === id)
      ad[0].value["current"].values[row] = newValues
      dispatch(updateAdsGrid({ id: id, value: ad[0].value }));
    } catch (e) {
      window.alert(e.message);
    }
  },[ads, dispatch])

  const handleDeleteRow = React.useCallback(async (index, id) => {
    try {
      const update = JSON.parse(JSON.stringify(ads));
      const ad = update.filter(a => a.id === id)
      ad[0].value["current"].values.splice(index, 1)
      dispatch(updateAdsGrid({ id: id, value: ad[0].value }));
    } catch (e) {
      window.alert(e.message);
    }
  },[ads, dispatch])
  
  return (
    <AdGridContext.Provider value={{
      handleDelete,
      handleDeleteRow,
      createCSV,
      createAdGrid,
      readFile,
      newAdData,
      ads,
      currentManufacturer,
      handleUpdateRow
    }}>
      .
      
      <Box className={classes.root}>
        <Box className={classes.buttonContainer}>
          <ActionButton label="Upload New Ad Grid" onClick={() => dispatch(toggleDialog())}/>
        </Box>
        <CenterContentContainer label="Upload an ad grid to get started">

            <AdGridTable />
    
         
        </CenterContentContainer>
      </Box>
      <AdGridDialog />
    </AdGridContext.Provider>
  )
}