import { Button, makeStyles, Typography } from '@material-ui/core';
import { type } from 'os';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 6,
    padding: 11,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  buttonText: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'center'
  },
  lightButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light

  }
}))

type button = "button" | "reset" | "submit" | undefined

interface props {
  label: string,
  width?: number,
  onClick?: () => void
  type?: button 
  padding?: string | number
  height?: string | number
  light?: boolean
}

export default function CommonButton({label, height, width = 143, onClick, type, padding, light}: props) {
  const classes = useStyles()
  return (
    <Button className={`${classes.root} ${light && classes.lightButton}`} style={{ width: width, padding: padding, height: height}} onClick={onClick} type={type}>
      <Typography className={classes.buttonText}>
        {label}
      </Typography>
    </Button>
  )
}