import { Box, Button, createStyles, makeStyles, Tab, Tabs, TextField, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { changeTab } from '../../../redux/reducers/productsReducer';



const useStyles = makeStyles((theme) => ({
  tabsContainer: {
  },
  projectTabs: {
    display: 'flex',
    flexDirection: 'row'
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 12,
    height: 39,
  },
  tab: {
    borderRadius: 0,
    textTransform: 'none',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 4,
    fontWidth: 600,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.white,
    width: 130,
    // marginRight: 9,
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    },
  },
  text: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
  },
  angle: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 0,
    borderTopLeftRadius: 4,
    marginLeft: -1,
    width: 10,
    height: '100%',
    clipPath: 'polygon(0 0, 0% 100%, 100% 100%)',
    '&.MuiButton-root': {
      minWidth: 25
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  selected: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  selectedText: {
    color: theme.palette.primary.main,
  }
}))


export default function BrandTabs() {
  const classes = useStyles()
  const {tab} = useAppSelector((state) => state.products)
  const dispatch = useAppDispatch()

  const handleTabChange = (value: number) => {
    dispatch(changeTab(value))
  }

  return(
    <Box className={classes.projectTabs}>
      <Box className={classes.tabContainer}>
        <Button disableRipple className={`${classes.tab} ${tab === 0 && classes.selected}`} onClick={() => handleTabChange(0)}>
          <Typography className={`${classes.text} ${tab === 0 && classes.selectedText}`}>Products</Typography>        
        </Button>
        <Button disableRipple className={`${classes.angle} ${tab === 0 && classes.selected}`} onClick={() => handleTabChange(0)}></Button>
      </Box>
      <Box className={classes.tabContainer}>
        <Button disableRipple className={`${classes.tab} ${tab === 1 && classes.selected}`} onClick={() => handleTabChange(1)}>
          <Typography className={`${classes.text} ${tab === 1 && classes.selectedText}`}>SKU Status</Typography>        
        </Button>
        <Button disableRipple className={`${classes.angle} ${tab === 1 && classes.selected}`} onClick={() => handleTabChange(1)}></Button>
      </Box>
    </Box> 
  )
}