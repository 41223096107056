import { Card, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative'

  },
  submitReset: {
    paddingBottom: 55,
    paddingTop: 55,
    justifyContent: 'space-around',
  },
  auth: {
    paddingBottom: 50,
    paddingTop: 45,
    justifyContent: 'space-between',
  },
}))

interface props {
  children: React.ReactNode,
  width: number,
  height?: number,
  submitReset?: boolean,
  auth?: boolean
  minHeight?: number
}

export default function CardContainer({children, width, height, submitReset, auth, minHeight}: props) {
  const [size, setSize] = React.useState<number>()
  const classes = useStyles()

  React.useEffect(() => {

  })

  return (
    <Card 
      className={`${classes.root} 
        ${submitReset && classes.submitReset} 
        ${auth && classes.auth}`} 
      style={{width: width, height: height , minHeight: minHeight}}>
        {children}
    </Card>
  )
}