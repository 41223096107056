import { Box, makeStyles, Switch, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 10px',
    borderTop: `2px solid ${theme.palette.secondary.light}`,
    borderBottom: `2px solid ${theme.palette.secondary.light}`
  },
  switchText: {}
}))

interface props {
  viewCurrent: boolean
  handleViewCurrent: () => {}
}

export default function SwitchRow({viewCurrent, handleViewCurrent}: props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Switch
        checked={viewCurrent}
        onChange={handleViewCurrent}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <Typography className={classes.switchText}>
        {viewCurrent ? "View current" : "View archived"}
      </Typography>
    </Box>       
  )
}