import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import {useFormik} from 'formik'
import * as Yup from 'yup'
import AccountChangeStatus from './AccountChangeStatus'

import { Box, Button, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';


import CommonTextField from '../CommonTextField';
import CardContainer from '../CardContainer';
import { Exit } from '../../assets';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headingContainer: {
    boxSizing: 'content-box',
    width: '100%',
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '9px 0 '
  },
  header: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.background.paper,
    
  },
  icon: {
    padding: 0,
    color: theme.palette.primary.main
  },
  textContainer: {
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 392,
    height: 219,
    paddingBottom: 53

  },
  text: {
    // fontFamily: 'Roboto san-serif',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '1rem'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancel: {
    height: 41,
    width: 104,
    borderRadius: 6,
    padding: 11,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  delete: {
    height: 41,
    width: 104,
    borderRadius: 6,
    padding: 11,
    backgroundColor: theme.palette.error.main,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    }
  },
  button: {
    width: 77,
    height: 41,
    borderRadius: 6,
    padding: 11,
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    }
  },
  buttonText: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: '1rem',
  }

}))

const initialValues = {
  email: '',
  password: '',
  changepassword: ''
}

type status = "Success" | "failure"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState<status>()
  const classes = useStyles()
  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(),
    changepassword: Yup.string()
    .required()
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
  });


  const handleClose = () => {
    setOpen(!open);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit:(values) => {

    }
  })


  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <CardContainer width={634} height={402}>
          { !submitted && (
            <Box className={classes.root}>
              <Box className={classes.headingContainer}>
                <Typography className={classes.header} variant="h1">
                  Account
                </Typography>
                <IconButton className={classes.icon}>
                  <Exit />
                </IconButton>
              </Box>
              <Box className={classes.textContainer}>
                <CommonTextField  fullWidth fieldHeader="Email" width={586} height={65}/> 
                <CommonTextField  fullWidth fieldHeader="Password" width={586} height={65}/> 
                <CommonTextField  fullWidth  width={586} height={65}/> 
              </Box> 
              <Box className={classes.buttonContainer}>
                <Button className={classes.button}>
                  <Typography className={classes.buttonText}>
                    Submit
                  </Typography>
                </Button>
              </Box>   
            </Box> 
          )}
          {submitted && (
            <AccountChangeStatus />
          )}  
        </CardContainer>
      </Dialog>
    </div>
  );
}