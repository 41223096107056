import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Ajax from "../../../Ajax";
import typehole from "typehole";

// inviteCode
// 56wnpV

export const getUserBrands = createAsyncThunk(
  "brands/getUserBrands",
  async () => {
    const response = await Ajax.getUserManufacturers();

    return response;
  }
);

export const createBrand = createAsyncThunk(
  "brands/createBrand",
  async () => {
    await Ajax.createManufacturer({ name: undefined });
    const userBrands = await Ajax.getUserManufacturers();

    return userBrands;
  }
);

export const updatedBrand = createAsyncThunk(
  "brands/updatedBrand",
  async (data: any) => {
    const test = await Ajax.updateManufacturer(data.id, data.data);
    const userBrands = await Ajax.getUserManufacturers();
    return userBrands;
  }
);


export const deleteBrand = createAsyncThunk(
  "brands/deleteBrand",
  async (id: number) => {
    await Ajax.deleteManufacturer(id);
    const userBrands = await Ajax.getUserManufacturers();
    return userBrands;
  }
);