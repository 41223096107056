import { Accordion,Link, AccordionSummary,TableHead, createStyles, makeStyles, Table, TableBody, TableCell, TableRow, Typography, withStyles, Checkbox, Box, Collapse, IconButton, Tooltip } from '@material-ui/core';
import { ExpandMoreRounded, ExpandLessRounded } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { format, isBefore, isAfter } from 'date-fns';

import MoreActionMenu from '../../../../components/MoreActionMenu'
import { useAppSelector } from '../../../../hooks/redux';

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 600,
      letterSpacing: '5%',
      padding: '10px 3px',
      textAlign: 'center'
    },
    body: {
      fontSize: theme.typography.pxToRem(14),
      padding: '10px 3px',
      textAlign: 'center'
    
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.white,
      },
    },
  }),
)(TableRow);


const StyledCollapse= withStyles((theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(Collapse);

const StyledTooltip = withStyles({
  tooltipPlacementTop: {
    margin: "-25px 0 0 40px",
  },
})(Tooltip);

const StyledTooltipLastReport = withStyles({
  tooltipPlacementTop: {
    margin: "0 0 0 100px",
  },
})(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {

  },
  topHeaders: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  dataHeaderRow: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.light,
    fontFamily: theme.typography.fontFamily,
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20
  },
  dateText: {
    paddingRight: '1rem'
  },
  checkBox: {},
  export: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    textDecoration: 'none',
    padding: '0 4px'
  },
  dataTable: {
    overflow: "auto",
    maxHeight: "450px",
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    '&::-webkit-scrollbar': {
      width: "10px"
    },
    
    '&::-webkit-scrollbar-track': {
        boxShadow: "inset 0 0 5px grey",
        borderRadius: "10px"
    },
    
    '&::-webkit-scrollbar-thumb': {
        background: theme.palette.secondary.light,
        borderRadius: "10px"
    },
    
    '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.secondary.main
    }
  }
}))

export default function TableAccordion({data, date, handleDelete, index, handleArchive, archived}) {
  const [toggled, setToggled] = React.useState(false)
  const {list, name} = useAppSelector(state => state.products.productsList)
  const {reports} = useAppSelector((state) => state.products)
  const classes = useStyles()
  const [dialog, setDialog] = React.useState(false)
  const [changedValues, setchangedValues] = React.useState(null)
  const [notChangedValues, setNotChangedValues] = React.useState(null)
  const [toggleLastReport, setToggleLastReport] = React.useState(false)
  
  useEffect(() => {
    const valuesChanged = data.value.filter(prod => prod.changesFromLastReport)
    const tblDataChanged = {
      ...data,
      value: valuesChanged
    }
    setchangedValues(tblDataChanged)
    const valuesNotChanged = data.value.filter(prod => !prod.changesFromLastReport)
    const tblDataNotChanged = {
      ...data,
      value: valuesNotChanged
    }
    setNotChangedValues(tblDataNotChanged)
  }, [data])

  const handleToggled = () => {
    setToggled(!toggled)
  }

  const handleToggleLastReport = () => {
    setToggleLastReport(!toggleLastReport)
  }

  const closeDialog = async() => {
    await setDialog(true)
    await setDialog(false)
  }
  
  const getMapValue = (sku) => {
    if(!sku) return
    const row = list.find(prod => parseInt(prod.sku) === parseInt(sku));


    if (row && row.map) return `$${row.map}`;

    return '';
  }

  const createCSV = (report) => {
    if (!report) return [];

    const parseLink = (model, url) => {
      return `=HYPERLINK(""${url}"",""${model}"")`
    }

    const shoppingLink = (price, model) => {
      return `=HYPERLINK(""${`https://www.google.com/search?q=${model}&tbm=shop`}"",""${price && price !== "" ? `$${price}` : ''}"")`
    }

    let csvData = []
    csvData.push(['SKU', 'Model', 'Description', 'MAP', 'Suggested Promo Retail', 'BBY Price', 'MSRP', 'Availability', 'STC'])
    
    if (changedValues && changedValues.value.length > 0) {
   
      csvData.push(["Here is what changed from the last report"])
     
      changedValues.value.map(prod => {
        csvData.push([
          prod.sku,
          prod.model,          // parseLink(prod.model, prod.url),
          prod.description.replaceAll(",", ""),
          getMapValue(prod.sku),
          prod.promo && prod.promo !== "" ? `$${prod.promo}` : '',
          prod.price && prod.price !== "" ? `$${prod.price}` : '',    // shoppingLink(prod.price, prod.model),
          prod.msrp &&  prod.msrp !== "" ? `$${prod.msrp}` : '',
          prod.available,
          prod.stc
        ])
        if(prod.isNewAdded) {
            csvData.push([
              "Last Report",
              "",
              "",
              "This product has been newly added"
            ])
          } else {
            csvData.push([
                "Last Report",
                "",
                "",
                "",
                prod.prevPromo && prod.prevPromo !== "" ? `$${prod.prevPromo}` : '',
                prod.prevPrice && prod.prevPrice !== "" ? `$${prod.prevPrice}` : '',    // shoppingLink(prod.price, prod.model),
                prod.prevMsrp &&  prod.prevMsrp !== "" ? `$${prod.prevMsrp}` : '',
                prod.prevAvailable,
              ])
            }
      })
    }
    
    if (notChangedValues && notChangedValues.value.length > 0) {
      if (changedValues && changedValues.value.length > 0) {
        csvData.push(["The SKU Status below is unchanged from the last report"])
      } else if (!(index === reports.length - 1)) {
        csvData.push(["No SKU Status changes from last report"])
      }
      notChangedValues.value.map(prod => {
        csvData.push([
          prod.sku,
          prod.model,          // parseLink(prod.model, prod.url),
          prod.description.replaceAll(",", ""),
          getMapValue(prod.sku),
          prod.promo && prod.promo !== "" ? `$${prod.promo}` : '',
          prod.price && prod.price !== "" ? `$${prod.price}` : '',    // shoppingLink(prod.price, prod.model),
          prod.msrp &&  prod.msrp !== "" ? `$${prod.msrp}` : '',
          prod.available,
          prod.stc
        ])
      })
    }
    return csvData
  }

  return (
    <>
      <Box className={classes.topContainer}>
        <Box className={classes.dateContainer}>
          { !archived && (
            <Checkbox className={classes.checkBox} onClick={() => handleArchive(data)}/>
          )}  
          <Typography className={classes.dateText}>{date}</Typography>
          { !toggled ? (
            <IconButton onClick={handleToggled}>
              <ExpandMoreRounded />
            </IconButton>
          ): (
            <IconButton onClick={handleToggled}>
              <ExpandLessRounded />
            </IconButton>  
          )}
        </Box>
        <Box>
          <MoreActionMenu
          close={dialog}
          actions={{
            delete: () => handleDelete(index)
          }}>
              <CSVLink filename={data ? `${name} SKU Status Report (${format(new Date(data.date), 'MM-dd-yyyy')}).csv` : ''} className={classes.export} onClick={() => closeDialog()} data={createCSV(data?.value || null)}>Export to CSV</CSVLink>
          </MoreActionMenu>
        </Box>
      </Box>
      <StyledCollapse in={toggled} timeout="auto" unmountOnExit>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" className={classes.topHeaders} style={{width: "125px"}}>
              SKU
            </StyledTableCell>
            <StyledTableCell align="left" className={classes.topHeaders} style={{width: "125px"}}>UPC</StyledTableCell>
            <StyledTableCell align="left" className={classes.topHeaders} style={{width: "140px"}}>MODEL</StyledTableCell>
            <StyledTableCell align="left" className={classes.topHeaders} style={{width: "280px"}}>DESCRIPTION</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders} style={{width: "90px"}}>MAP</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders} style={{width: "130px"}}>SUGGESTED PROMO RETAIL</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders} style={{width: "100px"}}>BBY PRICE</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders} style={{width: "90px"}}>MSRP</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders} style={{width: "120px"}}>BBY AVAILABILITY</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        </Table>
        <Box className={classes.dataTable}>
        <Table>
        <TableBody>
        {data ?
            (changedValues && changedValues.value.length > 0 ?
              <>
              <StyledTableRow>
                <StyledTableCell colspan="9" className={classes.dataHeaderRow}>
                  Here is what changed from the last report {data && data.lastReportDate ? `on ${format(new Date(data.lastReportDate), 'MM/dd/yyyy')}` : ''}
                  <StyledTooltipLastReport title={`${toggleLastReport ? 'Hide last report data': 'Show last report data'}`} placement='top'>
                    <IconButton onClick={() => handleToggleLastReport()}>
                      { !toggleLastReport ? <ExpandMoreRounded /> : <ExpandLessRounded />}
                    </IconButton>
                  </StyledTooltipLastReport>
                    
                </StyledTableCell>
              </StyledTableRow>
              {changedValues.value.map((row, i) => (
                row && row.changesFromLastReport ?
                <>
                  <StyledTableRow key={row.sku}>
                    <StyledTableCell component="th" scope="row" style={{width: "125px"}}>
                      {row.sku}
                    </StyledTableCell>
                    <StyledTableCell align="left" style={{width: "125px"}}>{row.upc}</StyledTableCell>
                    <StyledTableCell align="left" style={{width: "140px"}}>{ row.url ? (
                      <StyledTooltip title="BBY PDP" placement='top'>
                        <Link href={row.url} target="_blank" rel="noopener noreferrer">{row.model}</Link>
                      </StyledTooltip>
                    ): (
                      row.model
                    )}</StyledTableCell>
                    <StyledTableCell align="left" style={{width: "280px"}}>{row.description}</StyledTableCell>
                    <StyledTableCell align="left" style={{width: "90px"}}>{row.map}</StyledTableCell>
                    <StyledTableCell align="center" style={{ color: row.promo && row.price ? (parseFloat(row.price) === parseFloat(row.promo) ? 'green' : 'red') : '', width: "130px" }}>
                      {row.promo ? row.promo : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{width: "100px"}}>
                      <StyledTooltip title="Google Shopping" placement='top'>
                        <Link href={`https://www.google.com/search?q=${row.model}&tbm=shop`} target="_blank" rel="noopener noreferrer"  style={{ color: row.promo && row.price ? (parseFloat(row.price) === parseFloat(row.promo) ? 'green' : 'red') : '' }}>
                          {row.price}
                        </Link>
                      </StyledTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{width: "90px"}}>{row.msrp}</StyledTableCell>
                    <StyledTableCell align="center" style={{width: "110px"}}>{row.available}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={row.sku}>
                    <StyledTableCell colspan="9" style={{padding: 0, borderWidth: 0}}>
                      <StyledCollapse in={toggleLastReport} timeout="auto" unmountOnExit>
                        <Table>
                          <TableBody>
                            <StyledTableCell component="th" scope="row" style={{width: "125px"}}>
                              Last Report
                            </StyledTableCell>
                            {row.isNewAdded ?
                            <StyledTableCell colspan="8">
                              This product has been newly added
                            </StyledTableCell>
                            :
                            <>
                            <StyledTableCell align="left" colspan="4"></StyledTableCell>
                            <StyledTableCell align="center" style={{ width: "130px" }}>{row.prevPromo}</StyledTableCell>
                            <StyledTableCell align="center" style={{width: "100px"}}>{row.prevPrice}</StyledTableCell>
                            <StyledTableCell align="center" style={{width: "90px"}}>{row.prevMsrp}</StyledTableCell>
                            <StyledTableCell align="center" style={{width: "110px"}}>{row.prevAvailable}</StyledTableCell>
                            </>
                            }
                          </TableBody>
                        </Table>
                      </StyledCollapse>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
                : ""
              ))}
              </>
              : '') : ''}
            {data ?
              (notChangedValues && notChangedValues.value.length > 0 ?
                <>
                {changedValues && changedValues.value.length > 0 ?
                  <StyledTableRow>
                    <StyledTableCell colspan="9" className={classes.dataHeaderRow}>The SKU Status below is unchanged from the last report</StyledTableCell>
                  </StyledTableRow>
                  : (!(index === reports.length - 1) ?
                    <StyledTableRow>
                      <StyledTableCell colspan="9" className={classes.dataHeaderRow}>No SKU Status changes from the last report {data && data.lastReportDate ? `on ${format(new Date(data.lastReportDate), 'MM/dd/yyyy')}` : ''}</StyledTableCell>
                    </StyledTableRow>
                    : '')
                }
              {notChangedValues.value.map((row) => (
                row && !row.changesFromLastReport ?
            <StyledTableRow key={row.sku}>
              <StyledTableCell component="th" scope="row" style={{width: "125px"}}>
                {row.sku}
              </StyledTableCell>
              <StyledTableCell align="left" style={{width: "125px"}}>{row.upc}</StyledTableCell>
              <StyledTableCell align="left" style={{width: "140px"}}>{ row.url ? (
                <StyledTooltip title="BBY PDP" placement='top'>
                  <Link href={row.url} target="_blank" rel="noopener noreferrer">{row.model}</Link>
                </StyledTooltip>
              ): (
                row.model
              )}</StyledTableCell>
              <StyledTableCell align="left" style={{width: "280px"}}>{row.description}</StyledTableCell>
              <StyledTableCell align="left" style={{width: "90px"}}>{row.map}</StyledTableCell>
              <StyledTableCell align="center" style={{ color: row.promo && row.price ? (parseFloat(row.price) === parseFloat(row.promo) ? 'green' : 'red') : '', width: "130px" }}>
                {row.promo ? row.promo : '-'}
              </StyledTableCell>
              <StyledTableCell align="center" style={{width: "100px"}}>
                <StyledTooltip title="Google Shopping" placement='top'>
                  <Link href={`https://www.google.com/search?q=${row.model}&tbm=shop`} target="_blank" rel="noopener noreferrer"  style={{ color: row.promo && row.price ? (parseFloat(row.price) === parseFloat(row.promo) ? 'green' : 'red') : '' }}>
                    {row.price}
                  </Link>
                </StyledTooltip>
              </StyledTableCell>
              <StyledTableCell align="center" style={{width: "90px"}}>{row.msrp}</StyledTableCell>
              <StyledTableCell align="center" style={{width: "110px"}}>{row.available}</StyledTableCell>
            </StyledTableRow>
            : ""
          ))}
          </> : '')
          : ''}
        </TableBody>
      </Table>
      </Box>
    </StyledCollapse>
  </>
  )
}