 import {createMuiTheme} from '@material-ui/core'
import "typeface-inter";

const theme = createMuiTheme({
  overrides: {
    MuiTab: {
      root: {
        "&$selected": {
          backgroundColor: "inherit",
          color: "#fff", 
        },
      }
    },
    MuiButton: {
      root: {
        border: 'none',
        '&:hover': {
          backgroundColor: 'inherit'
        }
      }
    },
    MuiTextField: {
      root: {
        borderColor: '#173B75',
        backgroundColor: '#FFFFFF',
        '& .MuiOutlinedInput-root': {  
          '& fieldset': {
            borderColor: '#173B75',
          },
          '&:hover fieldset': {
            borderColor: '#173B75',
          },
        },
        '& .MuiOutlinedInput-input': {
          padding: '13px 14px',
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#14299D', /* TurnSku Blue */
      dark: '#14299D', /* Dark Blue */
      light: '#E7EFFE' /* Light Blue */
    },
    secondary: {
      main: '#173B75', /* Mid Blue */
      light: '#96B9F2' /* Mid Light Blue */
    },
    common: {
      black: '#000', /* Black */
      white: '#FFFFFF' /* White */
    },
    background: {
      default: '#292C33' /* Background */,
      // paper: '#1E1E24', /* Resin Black */
    },
    success: {
      main: '#87B750' /* Green - Success */
    },
    error: {
      main: '#BF1A2F' /* Red - Error */
    },
    warning: {
      main: '#FFC254' /* Yellow */
    },
  }, 
  breakpoints: {
    values: {
      xs: 1200,
      sm: 1200,
      md: 1200,
      lg: 1200,
      xl: 1200,
    }
  },
  typography: {
    fontFamily: [
      'inter',
      'serif',
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '2rem',
      color: '#fff' 
    }
  },
})



export default theme