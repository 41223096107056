import * as React from "react";

function SvgMore(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.993 7.805c1.19 0 2.143.975 2.143 2.195 0 1.22-.953 2.195-2.143 2.195S7.85 11.22 7.85 10c0-1.22.952-2.195 2.143-2.195zM7.85 2.195c0 1.22.952 2.195 2.143 2.195 1.19 0 2.143-.975 2.143-2.195C12.136.975 11.183 0 9.993 0S7.85.976 7.85 2.195zm0 15.61c0 1.22.952 2.195 2.143 2.195 1.19 0 2.143-.976 2.143-2.195 0-1.22-.953-2.195-2.143-2.195s-2.143.975-2.143 2.195z"
        fill="#0047BB"
      />
    </svg>
  );
}

export default SvgMore;
