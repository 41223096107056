import { combineReducers } from 'redux';
import uiSlice from './uiReducer'
import authSlice from './authReducer'
import productsSlice from './productsReducer'
import brandPanelSlice from './brandPanelReducer'
import notificationsSlice from './notificationsReducer'
import brandsSlice from './brandsReducer'
import adminReducer from './adminReducer'
import adsGridSlice from './adsGridReducer'
import reviewSlice from './reviewReducer'

const appReducer = combineReducers({
  adsGrid: adsGridSlice,
  ui: uiSlice,
  auth: authSlice,
  brandPanel: brandPanelSlice,
  products: productsSlice,
  notifications: notificationsSlice,
  brands: brandsSlice,
  admin: adminReducer,
  reviews: reviewSlice
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }  
  if (action.type === 'RESET_PRODUCTS') {
    state = {
      ...state,
      adsGrid: undefined,
      brandPanel: undefined,
      products: undefined,
      notifications: undefined,
      reviews: undefined,
    };
  }

  return appReducer(state, action);
};

export default rootReducer