import React from "react";
import { withStyles, createStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import Ajax from "../../../../Ajax";
import {
  deleteReport,
  getReports,
  createReports,
} from "../../../../redux/reducers/productsReducer/thunks";
import {
  Box,
  Button,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@material-ui/core";
import { Table } from "@material-ui/core";
import moment from "moment";
import CustomTabs from "../CustomTabs";
import SwitchRow from "./SwitchRow";
import TableAccordion from "./TableAccordion";
import { format, isBefore, isAfter } from "date-fns";
import { updateReports } from "../../../../redux/reducers/productsReducer";
import { shallowEqual } from "react-redux";
import Spinner from "../../../../components/Spinner";
import MoreActionMenu, {
  StyledMenuItem,
} from "../../../../components/MoreActionMenu";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    top: -40,
    width: 1200,
  },
  table: {
    // minWidth: 580,
    minWidth: 1200,
    backgroundColor: theme.palette.common.white,
  },
  tableContainer: {
    borderRadius: 4,
    borderTopLeftRadius: 0,
  },
  header: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.black,
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    textTransform: "none",
    margin: "0 1rem",
    height: 41,
    width: 149,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonText: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.white,
  },
  topHeaders: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
  },
  center: {
    marginRight: 50,
  },
  actionColumn: {
    width: 50,
  },
  bottomPages: {
    backgroundColor: theme.palette.secondary.light,
    height: 60,
    width: "100%",
  },
  bottomTextContainer: {
    width: "100%",
    height: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  export: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.fontFamily,
    textDecoration: "none",
    padding: "0 4px",
  },
  headerContainer: {
    height: 60,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
  },
  buttonContainer: {},
  switchText: {},
  bottomText: {},
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export default function ReportsTable() {
  const classes = useStyles();
  const { currentListId, productsList, reports, loading } = useAppSelector(
    (state) => state.products,
    shallowEqual
  );

  const { ads } = useAppSelector((state) => state.adsGrid);
  const [viewCurrent, setViewCurrent] = React.useState(true);
  const [reportsToArchive, setReportsToArchive] = React.useState([]);
  const [deleteAllExportAllDialog, setDeleteAllExportAllDialog] = React.useState(false)
  const dispatch = useAppDispatch();
  const [skus, setSkus] = React.useState([]);
  const [error, setError] = React.useState();
  const [submitting, setSubmitting] = React.useState(false);
  const [activeReports, setActiveReports] = React.useState([])

  React.useEffect(() => {
    if (!reports || !reports.length) {
      if (currentListId) {
        dispatch(getReports(currentListId));
      }
    }
  }, [currentListId]);

  // generate variance report for sku reports
  const generateVarianceReports = (listReports) => {
    if (!listReports || listReports.length === 0) {
      return []
    }
  
    if (listReports.length === 1) {
      return listReports
    }
  
    let listReportsVariance = []
  
    for (var i = 0; i < listReports.length; i++){
      var thisReport = listReports[i]
      // not storing deleted reports in redux store
      // if (thisReport.isDeleted) {
      //   continue
      // }
      var lastReport = i < listReports.length - 1 ? listReports[i + 1] : null
      if (!lastReport) {
        listReportsVariance.push({
          ...thisReport
        })
        continue
      }
      let varianceValue = []
      thisReport.value.forEach(repo => {
        const oldData = lastReport ? lastReport.value.find(rep => parseInt(rep.sku) === parseInt(repo.sku)) : null;
        if (!oldData) {
          varianceValue.push({
            ...repo,
            changesFromLastReport: true,
            isNewAdded: true
          })
        } else {
          const { msrp, price, promo, available } = repo;
          const { msrp: msrpO, price: priceO, promo: promoO, available: availableO } = oldData;
          var isValueChanged = false
          if (msrp !== msrpO || price !== priceO || promo !== promoO || available !== availableO) {
            isValueChanged = true
          } else {
            isValueChanged = false
          }
          varianceValue.push({
            ...repo,
            ...{
              prevMsrp: msrpO, 
              prevPrice: priceO,
              prevPromo: promoO,
              prevAvailable: availableO,
              changesFromLastReport: isValueChanged,
              isNewAdded: false
            }
          })
        }
      })
      listReportsVariance.push({
        ...thisReport,
        value: varianceValue,
        lastReportDate: lastReport.date
      })
    }
    return listReportsVariance
  }

  React.useEffect(() => {
    if (reports && reports.length > 0) {
      var listReportsVariance = generateVarianceReports(reports)
      var actRepo = listReportsVariance.filter(listReportsVariance => !listReportsVariance.isDeleted)
      setActiveReports(actRepo)
    }
  }, [reports])

  // need to move this to redux
  const handleDelete = async (index) => {
    const confirm = window.confirm(
      `Delete ${format(new Date(activeReports[index].date), "M/dd/yyyy H:mm")}?`
    );

    if (!confirm) return;

    try {
      // getting original report data
      const repo = reports.find(r => r.id === activeReports[index].id)
      await Ajax.updateReportList(activeReports[index].id, { ...repo, isDeleted: true });
      // const _reports = reports.filter((report, i) => report.id !== activeReports[index].id);

      // dispatch(updateReports(_reports));
      dispatch(getReports(productsList.id))
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewCurrent = () => {
    setViewCurrent(!viewCurrent);
  };

  const handleArchive = (report) => {
    if (reportsToArchive.includes(report)) {
      const newArchiveList = reportsToArchive.filter(
        ({ id }) => id !== report.id
      );
      setReportsToArchive(newArchiveList);
      return;
    }
    setReportsToArchive((state) => state.concat(report));
  };

  const archiveReports = async () => {
    // maybe need to move this to redux so state updates
    try {
      setSubmitting(true);
      reportsToArchive.forEach(async (report, i) => {
        // getting original report data
        const repo = reports.find(r => r.id === report.id)
        await Ajax.updateReportList(report.id, { ...repo, archived: true });
        if (i === reportsToArchive.length - 1) {
          // I think this is firing maybe to early?
          dispatch(getReports(productsList.id));
          setReportsToArchive([]);
          setSubmitting(false);
        }
      });
    } catch (err) {
      window.alert(err.message);
      setSubmitting(false);
    }
  };

  const sortByAvailability = products => {
    let sortedProducts = [];
    
    const missingProducts = products.filter(prod => {
      return prod.available === "Missing"
    })
    missingProducts.sort((a, b) => a.model > b.model ? -1 : 1)
    sortedProducts.push(...missingProducts)
    
    const outOfStockProducts = products.filter(prod => {
      return prod.available === "Out of Stock"
    })
    outOfStockProducts.sort((a, b) => a.model > b.model ? -1 : 1)
    sortedProducts.push(...outOfStockProducts)
    
    const availableProducts = products.filter(prod => {
      return prod.available === "Available"
    })
    availableProducts.sort((a, b) => a.model > b.model ? -1 : 1)
    sortedProducts.push(...availableProducts)
    return sortedProducts;
  }

  // this is causing multiple reports to be
  const fetchProductData = async (skus) => {
    try {
      let details = await Ajax.fetchBestBuyApi(skus);
      // For SKUS missing from BBY API, add empty object with SKU
      skus.forEach((sku) => {
        const foundInDetails = details.find(
          (p) => parseInt(p.sku) === parseInt(sku)
        );

        if (!foundInDetails) details.push({ sku: parseInt(sku) });
      });

      // Get model from SKU list and Promo from Ad Grid
      details = details.map((row) => {
        const rowInList = productsList.list.find(
          (prod) => parseInt(prod.sku) === parseInt(row.sku)
        );
        if (!rowInList) return row;

        return { ...row, ...{ model: rowInList.model, map: rowInList.map } };
      });

      const today = new Date(new Date().setHours(0,0,0,0));
      // Add promo price if ad is currently running
      details = details.map((row) => {
        if (!ads) return row
        const allAdsRunningToday = ads.map((ad) => {
          if (!ad.value) return []
          if (!ad.value.current) return []
          let thisAds = ad.value.current.values
          let adsRunningToday = thisAds.filter((thisAd) => {
            return (
              !isBefore(today, new Date(thisAd.startDate)) &&
              !isAfter(today, new Date(thisAd.endDate))
            );
          });
          return adsRunningToday
        })
        var ad
        for (let i = 0; i < allAdsRunningToday.length; i++) {
          ad = allAdsRunningToday[i].find(
            (a) => parseInt(a.sku) === parseInt(row.sku)
          );
          if (ad) break;
        }
        if (!ad) return row;
        return { ...row, promo: ad.promo, stc: ad.stc };
      });
      let sortedDetails = sortByAvailability(details)
      const productsListId = productsList.id;
      dispatch(
        createReports({ data:{value: sortedDetails, date: new Date()}, productsListId })
      );
    } catch (e) {
      console.log(e.message);
      if (e.message === "Request failed with status code 400") {
        window.alert(
          "Request to Best Buy API failed. It's likely one of the SKUs is incorrect."
        );
      } else {
        setError(e.message);
      }
    }
  };

  // SHOULD BE FIRED FIRST AS IT SETS SKUS
  const getGeneratedSKus = async (id) => {
    const skuList = productsList.list.map((pair) => pair.sku);
    fetchProductData(skuList);
  };

  const handleDeleteAll = async() => {
    const confirm = window.confirm(
      `Delete all SKU Status reports?`
    );
    closeDeleteAllExportAllDialog()
    if (!confirm) return;
    try {
      let toDeleteReports = []
      if (viewCurrent) {
        toDeleteReports = reports.filter(repo => repo.archived === false)
      } else {
        toDeleteReports = reports.filter(repo => repo.archived === true)
      }
      const toDeleteReportsIds = toDeleteReports.map((repo) => repo.id)
      toDeleteReports.forEach(async (report, i) => {
        await Ajax.updateReportList(report.id, { ...report, isDeleted: true });
      });
      // const _reports = reports.filter(repo => toDeleteReportsIds.indexOf(repo.id) === -1);
      // dispatch(updateReports(_reports));
      dispatch(getReports(productsList.id))
    } catch (err) {
      console.log(err);
    }
  };

  const formatDate = myDate => {
    var d = new Date(myDate)
    var d_month = (d.getMonth()+1) < 10 ? `0${d.getMonth()+1}` : d.getMonth()+1
    var d_date =  (d.getDate()+1) < 10 ? `0${d.getDate()}` : d.getDate()
    return `${d_month}-${d_date}-${d.getFullYear()}`
  }

  const getCsv = () => {
    if(!activeReports) return [];
    let reportsCSV = []
    const parseLink = (model, url) => {
      return `=HYPERLINK(""${url}"",""${model}"")`
    }

    const shoppingLink = (price, model) => {
      return `=HYPERLINK(""${`https://www.google.com/search?q=${model}&tbm=shop`}"",""${price && price !== "" ? `$${price}` : ''}"")`
    }
    reportsCSV.push(['SKU', 'Model', 'Description', 'MAP', 'Suggested Promo Retail', 'BBY Price', 'MSRP', 'Availability', 'STC'])
    activeReports.map(r => {
      if (r.isDeleted) {
        return
      }
      reportsCSV.push([`SKU Status Report on: ${formatDate(r.createdAt)}`])
      r.value.map(v => {
        reportsCSV.push([v.sku,
          v.model,      // parseLink(v.model, v.url),
          v.description.replaceAll(",", "").replaceAll(/\"/g, ""),
          v.map && v.map !== "" ? `$${v.map}` : '',
          v.promo && v.promo !== "" ? `$${v.promo}` : '',
          v.price && v.price !== "" ? `$${v.price}` : '',      // shoppingLink(v.price, v.model),
          v.msrp &&  v.msrp !== "" ? `$${v.msrp}` : '',
          v.available,
          v.stc])
      })
    })
    return reportsCSV
  }

  const closeDeleteAllExportAllDialog = async() => {
    await setDeleteAllExportAllDialog(true)
    await setDeleteAllExportAllDialog(false)
  }


  return (
    <Box className={classes.root}>
      <CustomTabs />
      <Paper style={{ borderTopLeftRadius: 0 }}>
        <Box className={classes.headerContainer}>
          <Typography className={classes.header}>
            My SKU Status Reports
          </Typography>
          <Box className={classes.buttonContainer} style={{marginLeft: "auto"}}>
            {reportsToArchive.length > 0 && (
              <Button
                className={classes.button}
                onClick={archiveReports}
                disabled={submitting}
              >
                <Typography
                  className={classes.buttonText}
                >{`Archive ${reportsToArchive.length} Report`}</Typography>
              </Button>
            )}
            <Button
              className={classes.button}
              onClick={getGeneratedSKus}
              disabled={submitting}
            >
              <Typography className={classes.buttonText}>
                Run New Report
              </Typography>
            </Button>
          </Box>
          {reports && reports.length && activeReports.length > 0 ? (
            <MoreActionMenu close={deleteAllExportAllDialog} className={classes.more} actions={{}}>
              <Box className={classes.moreContainer}>
                <CSVLink
                  filename={`${productsList.name}-All-SKU-Status-Reports(${formatDate(new Date())})`}
                  className={classes.export}
                  data={getCsv() || []}
                  onClick={() => closeDeleteAllExportAllDialog()}
                >
                  Export all to CSV
                </CSVLink>
                <StyledMenuItem
                  style={{ color: "red" }}
                  onClick={() => {
                    handleDeleteAll();
                  }}
                >
                  Delete all{" "}
                </StyledMenuItem>
              </Box>
            </MoreActionMenu>
          ) : ''}
        </Box>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead></TableHead>
            {!submitting ? (
              <TableBody>
                <StyledTableRow>
                  <SwitchRow
                    handleViewCurrent={handleViewCurrent}
                    viewCurrent={viewCurrent}
                  />
                </StyledTableRow>
                {reports && reports.length && activeReports.length &&
                  !viewCurrent ?
                  activeReports.map(
                    (row, i) =>
                      row.archived && !row.isDeleted && (
                        <TableAccordion
                          handleArchive={() => {}}
                          index={i}
                          archived
                          date={moment(row.date).format("MM-DD-YYYY hh:mm")}
                          data={row}
                          handleDelete={handleDelete}
                        />
                      )
                  ) : ''}
                {reports && reports.length && activeReports.length &&
                  viewCurrent ?
                  activeReports.map(
                    (row, i) =>
                      !row.archived && !row.isDeleted && (
                        <TableAccordion
                          handleArchive={handleArchive}
                          index={i}
                          date={moment(row.date).format("MM-DD-YYYY hh:mm")}
                          data={row}
                          handleDelete={handleDelete}
                        />
                      )
                  ): ''}
              </TableBody>
            ) : (
              <Spinner />
            )}
          </Table>
        </TableContainer>
        {!reports || (reports.length === 0)  || (activeReports.length === 0) ? 
          <Box className={classes.bottomTextContainer}>
            <Typography className={classes.bottomText}>
              Run new report to get started
            </Typography>
          </Box>
        : ''}
      </Paper>
    </Box>
  );
}
