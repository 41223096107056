import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 6,
    padding: 11,
    height: 41,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textTransform: 'none',
    zIndex: 3,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },
    '&.Mui-disabled': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.light
    }
  },
  icon: {
    fontSize: 30
  },
  text: {
    fontSize: 16,
    fontWeight: 600
  }
}))

interface props {
  label: string,
  width?: number,
  disabled?: boolean
  onClick?: () => void
}

export default function CommonButton({label, width = 210, disabled = false, onClick}: props) {
  const classes = useStyles()
  return (
    <Button className={classes.root} style={{ width: width }} disabled={disabled} onClick={onClick}>
      <AddIcon className={classes.icon}/>
      <Typography variant="body1" className={classes.text}>
        {label}
      </Typography>
    </Button>
  )
}