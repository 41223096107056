import React from 'react';
import {useAppSelector} from '../../hooks/redux'
import { shallowEqual } from 'react-redux'
import { Redirect, Switch, Route } from 'react-router-dom';
import AuthPage from './AuthPage'
import Router from '../../Router'
import useScreenSize from '../../hooks/useSceenSize'
import ScreenSizeWarning from '../ScreenSizeWarning';


export default function Auth() {
  const { width } = useScreenSize()
  const {isAuthorized} = useAppSelector(
    ({ auth }) => ({
      isAuthorized: auth.currentUser != null,
    }),
    shallowEqual
  ) 

 

  const validScreenSize = () => {
    if(width > 1024) {
      return (
        <Router />
      )
    } else {
      return (
        <ScreenSizeWarning />
      )
    }
  }

  return (
    <Switch>
      {!isAuthorized ? (
        /* Render auth page when user at `/auth` and not authorized. */
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /* Otherwise redirect to root page (`/`) */
        <Redirect from="/auth" to="/brands" />
      )}

      {/* <Route path="/error" component={ErrorsPage} /> */}
      {/* <Route path="/logout" component={Logout} /> */}

      {!isAuthorized ? (
        /* Redirect to `/auth` when user is not authorized */
        <Redirect to="/auth/login" />
      ) : (
        <>
         {validScreenSize()}
        </>
      )}
    </Switch>
  );
}