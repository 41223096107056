import { Accordion, AccordionSummary,TableHead, createStyles, makeStyles, Table, TableBody, TableCell, TableRow, Typography, withStyles, Checkbox, Box, TextField, Collapse, IconButton, MenuItem } from '@material-ui/core';
import { ExpandMoreRounded, ExpandLessRounded } from '@material-ui/icons';
import React from 'react';
import { CSVLink } from 'react-csv';
import { AdGridContext } from '..';
import MoreActionMenu from '../../../../components/MoreActionMenu'
import moment from 'moment';

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 600,
      letterSpacing: '5%',
    },
    body: {
      fontSize: theme.typography.pxToRem(12),
    
    
    },
    root: {
      '&.MuiTableCell-root': {
        padding: 8
      }
    }
  }),
)(TableCell);

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        
      },
    },
  }),
)(TableRow);

const StyledTextField = withStyles((theme) => 
  createStyles({
    root: {
      '& .MuiFilledInput-input': {
        fontSize: theme.typography.pxToRem(12),
        padding: 0
      },
    label: {
      fontSize: theme.typography.pxToRem(12),
      padding: 0
    }
    }
}))(TextField)

const StyledMenuItem = withStyles((theme) => ({
  root: {
    padding: "0 4px",
    fontSize: 14,
    "&:focus": {
      // backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);



const useStyles = makeStyles((theme) => ({
  root: {

  },
  topHeaders: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    borderTop: `2px solid #e2e2e2`,
    // borderBottom: `2px solid #e2e2e2`,
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 100
  },
  dateText: {
    padding: '1rem 0.5rem'
  },
  checkBox: {
    padding: "1rem"
  },
  actionContainer: {
    paddingRight: 8
  },
  export: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    textDecoration: 'none',
    padding: '0 4px'
  }
}))

const EditableRow = React.memo(({align, name, editing, index, value, keyVal, handleEditing}) => {
  const [editValue, setEditValue] = React.useState()

  const handleEditingVal = (val, _keyVal) => {
    setEditValue({
      val
    })
    handleEditing(val, _keyVal)
  }

  return (
    <>
      { editing !== index ? (
      <StyledTableCell align={align}>
        {value}
      </StyledTableCell>
      ) : (
      <StyledTableCell align={align}>
        <StyledTextField variant='filled' value={editValue ? editValue.val : value} onChange={(e) => handleEditingVal(e.target.value, keyVal)} />
      </StyledTableCell>
       )}
   </>

  )
})


const AdsCollapse = React.memo(({handleArchive, data, date, name, index, open, viewType, gridIndex, id, show, archived }) => {
  const { handleDelete, handleDeleteRow, handleUpdateRow } = React.useContext(AdGridContext)
  const [finishedEditing, setFinishedEditing] = React.useState(false)
  const [adDialog, setAdDialog] = React.useState(false)
  const [toggled, setToggled] = React.useState(false)
  const [rowEditing, setRowEditing] = React.useState();
  const [editing, setEditing] = React.useState({})
  const classes = useStyles()

  const handleToggled = () => {
    setToggled(!toggled)
  }

  const handleFinishEditing = () => {
    handleUpdateRow(rowEditing, viewType, editing, gridIndex, id )
    setRowEditing()
    setEditing({})
    setFinishedEditing(true)
  }

  const handleEditRow = (i) => {
    setFinishedEditing(false)
    setRowEditing(i)
  }

  const handleEditing = (value, key) => {
    let d = {}
    if(Object.keys(editing).length === 0 && editing.constructor === Object) {
      d = {
        ...data.values[rowEditing],
        [key]: value
      }
    } else {
      d = {
        ...editing,
        [key]: value
      }
    }
    setEditing(d)
  }

  const handleDeleteAd = id => {
    handleDelete(id)
    closeAdDialog()
  }

  const closeAdDialog = async() => {
    await setAdDialog(true)
    await setAdDialog(false)
  }

   const createCSV = (report) => {
    if (!report) return [];

    return report.map(row => {
      return {
        Week: row.week,
        'Start Date': row.startDate,
        'End Date': row.endDate,
        Model: row.model,
        SKU: row.sku,
        STC: row.stc,
        Notes: row.notes,
        MSRP: row.msrp,
        'Suggested Savings': row.savings,
        'Suggested Promo Retail': row.promo,
        'Gross Cost': row.gross,
        'Adjusted Cost': row.adjustedCost,
        'Reg % GM': row.regGMPercent,
        'Adjusted % GM': row.adjustedGMPercent,
        'Reg GM': row.regGM,
        'Adjusted GM': row.adjustedGM
      }
    });
  }

  return (
      <>
        <Box className={classes.topContainer} style={{display: show ? 'flex' : 'none'}}>
          <Box className={classes.dateContainer}>
            {!archived ?
            <Checkbox className={classes.checkBox} onClick={() => handleArchive(id)}/>
            : ''}
            <Typography className={classes.dateText}>{date}</Typography>
            { !toggled ? (
              <IconButton onClick={() => handleToggled(!toggled)}>
                <ExpandMoreRounded />
              </IconButton>
            
            ): (
              <IconButton onClick={() => handleToggled(!toggled)}>
                <ExpandLessRounded />
              </IconButton>  
            )}
            <Typography className={classes.dateText}>{name}</Typography>
          </Box>
          <Box className={classes.actionContainer}>
          <MoreActionMenu
          close={adDialog}
          actions={{
            delete: () => handleDeleteAd(id)
          }}>
              <CSVLink filename={data ? `${name.replace('.csv', '')}(${moment(data.dateCreated).format('MM-DD-YYYY')}).csv` : ''} onClick={() => closeAdDialog()} className={classes.export} data={createCSV(data.values|| null)}>Export to CSV</CSVLink>
          </MoreActionMenu>
          </Box>
        </Box>
      <Collapse in={open || toggled} timeout="auto" unmountOnExit>
      
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="center" className={classes.topHeaders}>Week</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Start</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>End</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Model</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>SKU</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>STC</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Notes</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>MSRP</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Suggested Savings</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Suggested Promo Retail</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Gross Cost</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Adj Cost</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Reg GM %</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Adj GM %</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Reg GM $</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}>Adj GM $</StyledTableCell>
            <StyledTableCell align="center" className={classes.topHeaders}></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          { data.values.length > 0 &&
            data.values.map((value, i) => (
              <StyledTableRow key={`${i}{value.sku}`}>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"week"} editing={rowEditing} index={i} value={value.week}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"startDate"} editing={rowEditing} index={i} value={value.startDate}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"endDate"} editing={rowEditing} index={i} value={value.endDate}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"model"} editing={rowEditing} index={i} value={value.model}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"sku"} editing={rowEditing} index={i} value={value.sku}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"stc"} editing={rowEditing} index={i} value={value.stc}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"notes"} editing={rowEditing} index={i} value={value.notes}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"msrp"} editing={rowEditing} index={i} value={value.msrp}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"savings"} editing={rowEditing} index={i} value={value.savings}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"promo"} editing={rowEditing} index={i} value={value.promo}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"gross"} editing={rowEditing} index={i} value={value.gross}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"adjustedCost"} editing={rowEditing} index={i} value={value.adjustedCost}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"regGMPercent"} editing={rowEditing} index={i} value={value.regGMPercent}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"adjustedGMPercent"} editing={rowEditing} index={i} value={value.adjustedGMPercent}/>
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"regGM"} editing={rowEditing} index={i} value={value.regGM} />
                <EditableRow align="center" handleEditing={handleEditing} keyVal={"adjustedGM"} editing={rowEditing} index={i} value={value.adjustedGM} />
                <StyledTableCell align="right" className={classes.actionColumn}>
                  <MoreActionMenu
                    close={finishedEditing}
                    actions={{
                    [rowEditing !== i ? "edit" : "save"]: () => handleEditRow(i),
                    delete: () => handleDeleteRow(i, id)
                  }}
                  >
                    { rowEditing === i && (
                      <StyledMenuItem onClick={handleFinishEditing}>
                        save
                      </StyledMenuItem>
                    )}
                  </MoreActionMenu>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody> 
      </Table>
    </Collapse>
    </>
  )
})


export default AdsCollapse