import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getNotifications, updatedNotifications } from './thunks'

// Define a type for the slice state
interface notificationsState {
  emailDialogToggled: boolean
  notifications: undefined | any
}

// Define the initial state using that type
const initialState: notificationsState = {
  emailDialogToggled: false,
  notifications: undefined
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleEmailDialog: (state) => {
      state.emailDialogToggled = !state.emailDialogToggled
    },
    // toggleEmailDialog: (state) => {
    //   state.emailDialogToggled = !state.emailDialogToggled
    // },
  },
  extraReducers: {
    [getNotifications.fulfilled.toString()]: (state, action) => {
      state.notifications = action.payload
    },
    [updatedNotifications.fulfilled.toString()]: (state, action) => {
      state.notifications = action.payload
    },
  }
})

export const { toggleEmailDialog } = notificationsSlice.actions

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.products

export default notificationsSlice.reducer