import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import DefaultView from '../../views/DefaultView'
import ActionButton from '../../components/Buttons/ActionButton'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CenterContentContainer from '../../components/CenterContentContainer';
import BrandCard from './BrandCard';
import { useAppDispatch, useAppSelector} from '../../hooks/redux'
import Ajax from '../../Ajax';
import { setBrands, addNewBrand } from '../../redux/reducers/brandsReducer'
import { createBrand, getUserBrands } from '../../redux/reducers/brandsReducer/thunks'
import SubscriptionDialog from './SubscriptionDialog/SubscriptionDialog';
import { Redirect ,Route } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '34px 0 85px 0'
  },
  brandCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
}))


export default function Dashboard() {
  const { brands } = useAppSelector(state => state.brands)
  const dispatch = useAppDispatch()
  const [subscriptionDialog, setSubscriptionDialog] = React.useState(false);
  const [userSubscriptionPlan, setUserSubscriptionPlan] = React.useState(null);
  const [userSubscriptionDetail, setUserSubscriptionDetail] = React.useState(null);
  const [brandsMaxLimit, setBrandsMaxLimit] = React.useState(null);
  const [redirect, setRedirect] = useState(false);
  const { user } = useAppSelector(({ auth }) => ({
    user: auth.currentUser,
  }));
  const classes = useStyles()

  React.useEffect(() => {
    const fetchMans = async () => {
      const userBrands= await Ajax.getUserManufacturers();
      if(userBrands !== null && userBrands.length > 0) 
      dispatch(setBrands(userBrands));
    }

    if (!brands || !brands.length) {
      fetchMans();
    }

    // CLEARS REDUX FOR WHEN A DIFFERENT BRAND IS EXPLORED
    dispatch({type: 'RESET_PRODUCTS'})

  }, []);

  React.useEffect(async() => {
    if (user.subDetailId != null) {
      const respo = await Ajax.getUserSubscription(user.email);
      const maxlimit =respo.result.subscriptionPlanDetail.productMetadata.brands;
      setUserSubscriptionPlan(respo.result.subscriptionPlanDetail);
      setUserSubscriptionDetail(respo.result.subscriptionDetail);
      setBrandsMaxLimit(maxlimit)
    }
  }, [])

  React.useEffect(async() => {
    if (userSubscriptionDetail != null) {
      if (userSubscriptionDetail.status != "active" && userSubscriptionDetail.status != "trialing"){
        setSubscriptionDialog(true)
      }
    }
  }, [userSubscriptionDetail])


const togglesubscription = (closed) => {
  if (!closed) return setSubscriptionDialog(!subscriptionDialog);
  else {
    setSubscriptionDialog(false);
    setRedirect(true);
  }
};


    const createNewBrand = async () => {
    
      if (user.subDetailId == null) {
        if(!subscriptionDialog) setSubscriptionDialog(true);
        return;
      }
      const selectedsub = userSubscriptionDetail
      if (selectedsub && selectedsub.status != "active" && selectedsub.status != "trialing") {
        setSubscriptionDialog(true);
        return;
      }
      if ( brands && brands.length >= brandsMaxLimit)
      { 
        setSubscriptionDialog(true);
        return ;
      } else {
        if (brands){
          for (let i = 0; i < brands.length; i++) {
            if (!brands[i].name) {
              alert("A new brand without name already exists.");
              return;
            }
          }
        }
        dispatch(createBrand());
      }
    };

  return (
    <DefaultView>
      <Box className={classes.root}>
        <Typography variant="h1">
          Your Brands
        </Typography>
        <ActionButton label="Create New Brand" onClick={createNewBrand}/>
      </Box>
      <CenterContentContainer label="Create a brand to get started">
        { brands && brands.length > 0 && (
          <Grid container className={classes.brandCardContainer} spacing={4}>
          {brands.map(({name}, index) => (
            <Grid item>
              <BrandCard key={name} name={name} id={index} subscriptionStatus={userSubscriptionDetail ? userSubscriptionDetail.status : null}/>
            </Grid>))}
          </Grid>
        )}
      </CenterContentContainer>
      <SubscriptionDialog
        onClick={togglesubscription} 
        open={subscriptionDialog}
        subscription={ userSubscriptionPlan ? userSubscriptionPlan : '' } 
        status={ userSubscriptionDetail ? userSubscriptionDetail.status : null}
      />
            {redirect ? <Redirect to="/subscription"/> : ''}
    </DefaultView>
  )
} 