import { Box, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import Ajax from '../../Ajax';
import CommonButton from '../../components/Buttons/CommonButton';
import CancelDialog from "./CancelDialog";
import React from 'react'

const useStyles = makeStyles((theme)=>({
  subscriptionbox:{  
    backgroundColor: '#343840',
    boxShadow: "0 0 5px 10px #343840",
    // display:'flex',
    // flexDirection : "column",
    width: '70vw',
    color:'white',
    margin: 'auto',
    padding : '0.7rem',
    fontFamily: theme.typography.fontFamily,
    fontSize:'18px',
      },

  plan:{
    // display:'flex',
    // flexWrap: 'wrap',
    // flexDirection:"row",
    // gap:'1.5rem',
    margin:'10px',
    color: "white",
    fontSize: 16
  },
  plandiv: {
    display:'flex',
    flexWrap: 'wrap',
    flexDirection:"row",
    gap:'1.5rem',
    padding: '3px 0',
    verticalAlign: "middle"
  }
}))


function UserSubscription({plan, subscription}) {
  const [cancelSubscriptionDialog, setCancelSubscriptionDialog] = React.useState(false)

  const classes = useStyles();

  const getPaymentLink = async(invoice) =>{
    const url = await Ajax.getInvoiceLink(invoice);
    window.location.href = url;
  }

  const handleCancellation = async() => {
    setCancelSubscriptionDialog(false)
    if (subscription.status != "past_due" && subscription.status != "incomplete"){
      const cancellation = await Ajax.cancelSubscription(subscription.subscriptionId);
      if (cancellation.status == 'canceled'){
        window.location.reload()
      } else {
        alert('Unable to cancel your subscription')
      }
    } else {
      const cancellation = await Ajax.cancelPaymentIntent(subscription.customerId);
      window.location.reload()
    }
    
  };

  return (
    <>
    {subscription ? 
    <Box className={classes.subscriptionbox}>
      <div className={classes.plan}>
        <div className={classes.plandiv}>
          <Typography variant='h5'>
            {subscription.status == "active" || subscription.status == "trialing"
              ? "Active Subscription" : "Last Subscription"}
          </Typography>
          {subscription.status == 'past_due' || subscription.status == 'incomplete' ?
            <CommonButton label='Pay Here' onClick={() => getPaymentLink(subscription.latestInvoice)}/>
          : ''}
          {subscription.status != "canceled" && subscription.status != "incomplete_expired" ?
            <CommonButton padding='5px' width='100px' label='Cancel' onClick={() => setCancelSubscriptionDialog(true)}/>
          : ''}
        </div>
      
        <div className={classes.plandiv}>
          <span>Plan: {plan.productName} </span>
          <span>Status: {subscription.status != "incomplete_expired" ? subscription.status.toUpperCase() : 'EXPIRED'}</span>
        </div>
        <div className={classes.plandiv}>
          {subscription.status == "active" || subscription.status == "trialing"
          ? <>
              <span>Brands allowed: {plan.productMetadata.brands}</span>
              <span>Start Date: {moment(subscription.startDate).format('MM-DD-YYYY')}</span>
              <span>End Date: {moment(subscription.endDate).format('MM-DD-YYYY')}</span>
            </>
          : ''}
        </div>
        <div className={classes.plandiv}>
          
          
          </div>
        </div>
        <CancelDialog onClick={setCancelSubscriptionDialog}  open={cancelSubscriptionDialog} handleCancellation={handleCancellation}/>
    </Box>
    : ''}
  </>
  )
}

export default UserSubscription
