import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import BaseDialog from '../../../../components/BaseDialog'
import CommonButton from '../../../../components/Buttons/CommonButton';
import { useAppDispatch, useAppSelector} from '../../../../hooks/redux'
import { toggleAddProductDialog } from '../../../../redux/reducers/productsReducer'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  textContainer: {
    // boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingLeft: 33,
    paddingRight: 75,
    paddingTop: 35,
    paddingBottom: 32,
    width: '100%',
    // height: 92
  },
  text: {
    textAlign: 'left',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    paddingTop: 10,
  },
  buttonContainer: {
    width: '100%',
 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boldText: {
 
    textAlign: 'left',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14)
  }
}))

export default function ProductListDialogWarning() {
  const { productDialogWarningToggled } = useAppSelector(state => state.products)
  const dispatch = useAppDispatch()
  const classes = useStyles()


  const handleContinueUpload = () => {
    dispatch(toggleAddProductDialog())
  }

  return (
    <BaseDialog title="Upload Product List" open={productDialogWarningToggled} close={toggleAddProductDialog}>
      <Box className={classes.textContainer}>
        <Typography className={classes.text}>
        Before you continue, please note you may only have one product list for each brand at a time.


        </Typography>
        <Typography className={classes.text}>
         By uploading a new product list, you will be <span className={classes.boldText}>deleting your current product list</span> and replacing it with the new one.
        </Typography>

      </Box>
      <Box className={classes.buttonContainer}>
        <CommonButton label="Continue anyway" onClick={handleContinueUpload} width={167}/>
      </Box>
    </BaseDialog>
  )
}