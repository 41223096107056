import React from "react";
import DefaultView from "../../views/DefaultView";
import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Ajax from "../../Ajax";
import CardContainer from "../../components/CardContainer";
import BrandButton from "../Dashboard/BrandCard/BrandButton";
import { Link } from "react-router-dom";
import { Arrow } from "../../assets";
import { useAppSelector } from "../../hooks/redux";
import { useHistory } from "react-router-dom";
import SnackBar from '../../components/SnackBar'

import ConfirmDialog from "./ConfirmDialog";
import UserSubscription from "./UserSubscription";

const useStyles = makeStyles((theme) => ({
  bigbox: {
    display: "flex",
    flextWrap: "wrap",
    justifyContent: "space-around",
    marginTop: "20px",
    position: "center",
  },
  name: {
    padding: 6,
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    paddingBottom: 10,
    textTransform: "uppercase",
  },
  link: {
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
    marginTop: 10,
  },
  linkText: {
    display: "inline",
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: 17,
    paddingLeft: 8,
  },
  plan: {
    fontWeight: 500,
    fontSize: 22,
    paddingBottom: 10,
    color: "#4a546d",
  },
  charge: {
    fontWeight: 600,
    fontSize: 38,
    paddingBottom: 5,
  },
  productName: {
    backgroundColor: "#b1bcd6",
    width: 300,
    paddingTop: 5,
  },
  cardcontent: {
    alignItems: "center",
    textAlign: "center",
  },
  freeTrial: {
    width: "500px",
    margin: "auto",
    fontFamily: theme.typography.fontFamily,
    color: "white",
    textAlign: "center"
  }

}));

export default function SubscriptionPanel() {
  const { user } = useAppSelector(({ auth }) => ({
    user: auth.currentUser,
  }));

  const { brands } = useAppSelector((state) => state.brands);
  const classes = useStyles();
  let history = useHistory();
  const [selectedSub, setSelectedSub] = React.useState(null);
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [subscriptions, setSubscriptions] = React.useState(null);
  
  const [snackbarOpen,setSnackbarOpen] = React.useState(false);
  const [snackbarMessage,setSnackbarMessage] = React.useState();
  const [snackbarSeverity,setSnackbarSeverity] = React.useState();

  const [subscriptionDialog,setSubscriptionDialog] = React.useState(false);
  const [confirmSub,setConfirmSub] = React.useState(false);
  const [selectedbody , setSelectedBody] = React.useState();

  React.useEffect(async () => {
    if (subscriptions == null) {
      const subResp = await Ajax.getSubscriptions();
      setSubscriptions(subResp.result);
    }
    if (selectedSub == null && user.subDetailId != null) {
      const respo = await Ajax.getUserSubscription(user.email);
      if (respo != null) {
        setSelectedSub(respo.result.subscriptionDetail);
        setSelectedPlan(respo.result.subscriptionPlanDetail);
      }
    }
  }, [user.subDetailId]);

//handleSnackbar
  const handleSnackBarOpen = (message, type) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarOpen(true)
    setTimeout(() => setSnackbarOpen(false), 4000)
  }


  const togglesubscription = async(closed) =>{
    if(!closed) return setSubscriptionDialog(!subscriptionDialog);
    else{
      
      setSubscriptionDialog(false);  

      if(selectedbody)
      {
      const checkout = await Ajax.checkout(selectedbody);
      if(checkout){
      
        if (checkout.url) {
        window.location.href = checkout.url;
       } else {
        handleSnackBarOpen(checkout.message, 'success');
        setTimeout(() =>history.push("/brands") , 2000);
         }

        }
      }
    }
  }


  const handleClick = async (priceId, maxbrands) => {
    if (selectedSub && selectedSub.status != "active" && selectedSub.status != "trialing" && selectedSub.status != "canceled" && selectedSub.status != "incomplete_expired") {
      if (selectedSub.status == "past_due")
        handleSnackBarOpen("Please complete or cancel your previous subscription invoice payment", 'info');

      if (selectedSub.status == "incomplete")
        handleSnackBarOpen("Incomplete payment for previous subscription", 'info');
      return;
    }


    //console.log(user.stripeCustId);
    const cancelPayment = await Ajax.cancelPaymentIntent(user.stripeCustId);

    if (maxbrands < brands.length) {
        handleSnackBarOpen(`Your current number of brands (${brands.length}) is more than allowed in selected plan. Please reduce brands to downgrade.`,'info');
      return;
    } else {
      setSelectedBody({ priceId: priceId, email: user.email });
      setSubscriptionDialog(true);
    }
  };

  return (
    <DefaultView>
      <Link to="/brands" className={classes.link}>
        <Arrow color="#fff" />
        <Typography variant="h1" className={classes.linkText}>
          Home
        </Typography>
      </Link>
     {selectedPlan
      ? <UserSubscription plan={selectedPlan} subscription={selectedSub}/>
      : <Box className={classes.freeTrial}>Get 14-day free trial with your first subscription!!</Box>}
      <Typography
        style={{
          color: "white",
          fontWeight: 700,
          fontSize: 25,
          textAlign: "center",
          marginTop: "10px"
        }}
      >
         SUBSCRIPTION PLANS
      </Typography>
      <Box className={classes.bigbox}>
        {subscriptions !== null
          ? subscriptions.map((sub, i) => {
              //  console.log(e.productId);

              return (
                <CardContainer width={270} minHeight={320}>
                  <div className={classes.productName}>
                    <Typography
                      color="text.secondary"
                      variant="h5"
                      gutterBottom
                      className={classes.name}
                    >
                      {sub.productName}
                    </Typography>
                  </div>
                  <CardContent className={classes.cardcontent}>
                    <Typography className={classes.charge}>
                      {sub.currency == "usd" ? "$" : "#"}
                      {(sub.amount / 100).toLocaleString()}
                    </Typography>
                    <Typography variant="h5" className={classes.plan}>
                      <Box>
                        {sub.intervalCount} {sub.interval}
                      </Box>
                      <Box>
                        {sub.productMetadata
                          ? sub.productMetadata.brands
                            ? `up to ${sub.productMetadata.brands} brand(s)`
                            : "no limit"
                          : ""}
                      </Box>
                    </Typography>
                    <div style={{ minHeight: "130px" }}>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {sub.productDesc}
                      </Typography>
                    </div>
                    <BrandButton
                      label={selectedSub && (selectedSub.status == "active" || selectedSub.status == "trialing") ? (sub.priceId == selectedSub.priceId ? "ACTIVE" : "SUBSCRIBE") : "SUBSCRIBE"}
                      onClick={() =>
                        handleClick(sub.priceId, sub.productMetadata.brands)
                      }
                      isDisabled={
                        selectedSub && (selectedSub.status == "active" || selectedSub.status == "trialing") ? sub.priceId == selectedSub.priceId : false
                      }
                    />
                  </CardContent>
                </CardContainer>
              );
            })
          : ""}
      </Box>

      <ConfirmDialog onClick={togglesubscription}  open={subscriptionDialog} subscription={selectedPlan ? true : false}/>

      {snackbarOpen ? <SnackBar message={snackbarMessage} severity={snackbarSeverity} open={true} /> : ''}
    </DefaultView>
  );
}
