import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Edit, More } from '../../../assets';
import CardContainer from '../../../components/CardContainer'
import CommonTextField from '../../../components/CommonTextField';
import BrandButton from './BrandButton';
import MoreActionMenu from '../../../components/MoreActionMenu'
import DeleteDialog from './DeleteDialog'
import { finishRename, toggleEdit } from '../../../redux/reducers/brandsReducer'
import { changeTab } from '../../../redux/reducers/brandPanelReducer'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { Redirect, useHistory } from 'react-router';
import { createBrand, updatedBrand, deleteBrand } from '../../../redux/reducers/brandsReducer/thunks'
import Ajax from '../../../Ajax'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 36
  },
  name: {
    paddingTop: 10,
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    paddingBottom: 30
  },
  instruction: {
    paddingTop: 0,
    fontSize: 14,
    textAlign: 'left',
    fontFamily: theme.typography.fontFamily
  },
  icon: {
    position: 'absolute',
    top: 14,
    right: 19.84,
    padding: 0,
    margin: 0
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '28px 10px 16px 10px',
    width: '100%',
    padding: '0 14px'
  },
  notEditing: {
    padding: '',
    height: 'inherit',
    margin: '0 10px 10px 10px',
  },
  editContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end'

  },
  textFieldContainer: {

  },
  label: {

  }
}))


interface brandCard {
  error: boolean;
  deleteDialog: boolean;
  name: string
  toggleDeleteDialog: (arg0: boolean) => void;
  handleEditMode?: () => void;
  editing: boolean
}

const localState: brandCard = {
  error: false,
  name: '',
  deleteDialog: false,
  toggleDeleteDialog(){},
  editing: false
}

interface props {
  name?: string
  newCard?: boolean
  id: number
  subscriptionStatus: string
}

export default function BrandCard({name, newCard, id, subscriptionStatus}: props) {
  const { brands } = useAppSelector((state) => state.brands)
  const [state, setState] = React.useState(localState)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const brand = brands[id]
  const [brandHasProducts, setBrandHasProducts] = React.useState(false)
  const [checkedProductList, setCheckedProductList] = React.useState(false)
  const [disableBrand, setDisableBrand] = React.useState(true)
  const classes = useStyles()

  React.useEffect(() => {
    if(!brand.name) {
      handleEditMode()
    }
    checkProductList()
  }, [brand])

  React.useEffect(() => {
    if (subscriptionStatus){
      if (subscriptionStatus == "active" || subscriptionStatus == "trialing"){
        setDisableBrand(false)
      }
    }
  }, [subscriptionStatus])

  const handleEditMode = () => {
    setState({
      ...state,
      editing: !state.editing
    })
  }

  const toggleDelete = (deleted?: boolean) => {
    if(!deleted) return setState({...state, deleteDialog: !state.deleteDialog})
    const brandId = brands[id].id
    if(brandId) dispatch(deleteBrand(brandId))
    setState({
      ...state,
      deleteDialog: false
    })
  }


  const handleBrandNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      name: e.target.value
    })
  }

  const handleCompletedCard = () => {
    if (!state.name) {
      alert("Error: Brand name can't be empty.")
      return
    }
      dispatch(updatedBrand({id: brand.id, data: {name: state.name}}))

      setTimeout(() => {
        handleEditMode()
      },250)
      
  }

  const handleBrandPath = (path: string) => {
    switch(path) {
      case "products":
        dispatch(changeTab(0))
        break;
      case "ADS":
        dispatch(changeTab(1))
        break;
      case "Reviews":
        dispatch(changeTab(2))
        break;
      case "Notifications":
        dispatch(changeTab(3))
        break;  
    }
    history.push(`/brand-panel/${brand.id}`)
  }

  const checkProductList = async() => {
    const products = await Ajax.getManufacturersProducts(brand.id)
    if (products) {
      console.log(products)
      setBrandHasProducts(true)
    } else {
      setBrandHasProducts(false)
    }
    setCheckedProductList(true)
  }
  
  if (!checkedProductList) {
    return (
      <CardContainer width={376} height={194}>
        { !state.editing && (
          <>
            <Typography variant="h3" className={classes.name}>
              {brand.name}
            </Typography>
            <Box className={`${classes.buttonContainer} ${!brand.editing && classes.notEditing}`}>
              <BrandButton label="..." onClick={() => {}} isDisabled={true} />
              <BrandButton label="..." onClick={() => {}} isDisabled={true} />
              <BrandButton label="..." onClick={() => {}} isDisabled={true} />
              <BrandButton label="..." onClick={() => {}} isDisabled={true} />
            </Box>
          </>
        )}
      </CardContainer>
    )
  }
  return (
    <CardContainer width={376} height={194}>
      { !state.editing && (
        <>
          <MoreActionMenu className={classes.icon} actions={{
            delete: () => toggleDelete(false),
            Rename: handleEditMode
          }}/>
          <Typography variant="h3" className={classes.name}>
            {brand.name}
          </Typography>
          <Typography variant="h3" className={classes.instruction}>
            {!brandHasProducts ? "Click PRODUCTS to get started" : ""}
          </Typography>
          <Box className={`${classes.buttonContainer} ${!brand.editing && classes.notEditing}`}>
            <BrandButton label="products" onClick={handleBrandPath} isDisabled={disableBrand} />
            <BrandButton label="ADS" onClick={handleBrandPath} isDisabled={!brandHasProducts || disableBrand} />
            <BrandButton label="Reviews" onClick={handleBrandPath} isDisabled={!brandHasProducts || disableBrand} />
            <BrandButton label="Notifications" onClick={handleBrandPath} isDisabled={!brandHasProducts || disableBrand} />
          </Box>
        </>
      )}
      { state.editing && (
        <>
        <MoreActionMenu className={classes.icon} actions={{
          delete: () => toggleDelete(false)
        }}/>
        <Box className={classes.editContainer}>
          <Box className={classes.textFieldContainer}>
            <Typography variant="body1" className={classes.label}>
              {brand.name ? `Renaming '${brand.name}'` : 'Enter brand name'}
            </Typography>
            <CommonTextField onChange={handleBrandNameChange} value={state.name}/>
          </Box>
          <Box className={classes.buttonContainer}>
            {brand.name ? <BrandButton label="Cancel" cancel onClick={handleEditMode}/> : ''}
            <BrandButton label={brand.name ? `Rename` : `Create`} onClick={handleCompletedCard}/>
          </Box>  
        </Box>
        </>
      )}
      <DeleteDialog onClick={toggleDelete}  open={state.deleteDialog} name={brand.name!}/>
    </CardContainer>
  )
} 