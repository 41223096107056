import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

interface props {
  severity: 'success' | 'info' | 'warning' | 'error'
  message: string
  opened: boolean
}

export default function SnackBar({severity, message }: props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar open={true} autoHideDuration={1000} anchorOrigin={{vertical:'top',horizontal:'center'}}>
        <Alert severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}