import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';
import DefaultView from '../../views/DefaultView'

const useStyles = makeStyles((theme) => ({
  root: {

  }
}))

export default function NotFound() {
  const classes = useStyles()
  return (
    <DefaultView>
      <Redirect from="*" to='/auth' />
    </DefaultView>
  )
}