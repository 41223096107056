import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import Ajax from '../../../Ajax'
// inviteCode
// 56wnpV

export const getAds = createAsyncThunk(
  'ads/getAds',
  async (id) => {

    const response = await Ajax.getManufacturersAds(id)
    
    let sortedResponse = response.sort((a, b) => {
      return new Date(b.dateCreated) - new Date(a.dateCreated);
    });

    return sortedResponse.reverse()
  }
)

export const updateAdsGrid = createAsyncThunk(
  'ads/updateAdsGrid',
  async (data) => {
    const { id } = data
    
    const manufacturer = await Ajax.updateManufacturersAds(id, data)

    const response = await Ajax.getManufacturersAds(manufacturer.ManufacturerId)
    return response
  }
)

export const createAdsGrid = createAsyncThunk(
  'ads/createAdsGrid',
  async (data) => {
    const {id, updates} = data

    const response = await Ajax.createManufacturersAds(id, updates);

    return response
  }
)

export const deleteAdsGrids = createAsyncThunk(
  'ads/deleteAdsGrids',
  async (id) => {
    const response = await Ajax.deleteAds(id);
    return {response,id};
  }
)

export const deleteAllAdsGrids = createAsyncThunk(
  'ads/deleteAllAdsGrids',
  async (data) => {
    const { manufacturer_id } = data
     await Ajax.deleteAllAds(manufacturer_id);

    return [];
  }
)


