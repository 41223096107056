import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import Ajax from '../../../Ajax'
// inviteCode
// 56wnpV

export const getAllUsers = createAsyncThunk(
  'admin/getAllUsers',
  async () => {
    const allUsers = await Ajax.getAllUsers();

    return allUsers
  }
)

export const deleteUser = createAsyncThunk(
  'admin/deleteUser',
  async (id: number | string) => {
    const deleteUser = await Ajax.deleteUser(id);
    const allUsers = await Ajax.getAllUsers();
  
    return allUsers
  }
)

export const generateInviteCode = createAsyncThunk(
  'admin/generateInviteCode',
  async () => {
    // await Ajax.createInviteCode();
    await Ajax.createInviteCode()
    const inviteCodes = await Ajax.getAllInviteCodes()
    return inviteCodes
  }
)

export const getAllInviteCodes = createAsyncThunk(
  'admin/getAllInviteCodes',
  async () => {

    const inviteCodes = await Ajax.getAllInviteCodes()
    return inviteCodes
  }
)

export const deleteInviteCode = createAsyncThunk(
  'admin/deleteInviteCode',
  async (code:string) => {
    await Ajax.deleteInviteCode(code)
    const inviteCodes = await Ajax.getAllInviteCodes()
    return inviteCodes
  }
)