import * as React from "react";

function SvgDownArrow(props) {
  return (
    <svg
      width={12}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.002 20a1.02 1.02 0 00.618-.239l5.1-4.706c.293-.319.413-.93.076-1.287-.333-.35-.979-.34-1.313.01l-3.581 3.308V.882a.891.891 0 00-.9-.882c-.497 0-.9.395-.9.882v16.204L1.52 13.778c-.308-.302-.976-.357-1.312-.01-.337.348-.23.981.075 1.287l5.1 4.706c.174.161.38.24.619.239z"
        fill="#BF1A2F"
      />
    </svg>
  );
}

export default SvgDownArrow;
